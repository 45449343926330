import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { Table, DatePicker as AntDatePicker, Skeleton } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
// import LogoLoader from 'common/LogoLoader';
import AntTableActions from 'common/TableActions';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import notifications from 'lib/notifications';
import TableListHeader from 'common/TableListHeader';
import useQuery from 'lib/hooks/useQuery';
import utils, { getDecryptedUrl } from 'lib/utils';
import FilterUtils from 'lib/filterUtils';
import Modal from 'pages/CTDataSetup/commodityDetails/components/Modal';
import CreatePlan from './CreatePlan';
import AntPagination from 'common/AntTablePagination';
import { actions as storageActions } from 'redux/storage';
import TextSearchFilter from 'common/Form/AntFilters/TextSearchFilter';
import NumericSearchFilter from 'common/Form/AntFilters/NumericSearchFilter';
import RangeDateSearchFilter from 'common/Form/AntFilters/RangeDateSearchFilter';
import './planList.scss';

const PlanList = () => {
  let { page, back, storage_plan_master_id, storage_plan_name, modified_on, isControlTower, person_company_affiliate_enum, storagePersonId } = useQuery();
  const isCTUser = Boolean(isControlTower);
  const history = useHistory();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const userStoragePlans = useSelector((state) => state?.userStorage?.storagePlans);
  const userInfo = useSelector((state) => state.user);
  const [rerender, setRerender] = useState(true)
  const isSupportAccess = userInfo?.profile?.person_role.some(role => role.role_id === 59)

  const { RangePicker } = AntDatePicker;

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isCreatePlan, setIsCreatePlan] = useState(false);
  const [isEditPlan, setIsEditPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [date, setDate] = useState('');
  const [value, setValue] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [scrollToFirstRow, setScrollToFirstRow] = useState(false);

  const backLink = utils.decodeBackUrl(back);
  const loc = getDecryptedUrl(window.location.href);

  let links = [
    { to: backLink, title: `Storage` },
    { to: `/storage/plan?page=1&${isCTUser ? `isControlTower=true&storagePersonId=${storagePersonId}&person_company_affiliate_enum=${person_company_affiliate_enum}&` : ''}back=${back}`, title: 'Plan' },
  ];

  const onFilterChange = (e, dataIndex, confirm) => {
    confirm();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        params = {
          ...params,
          [dataIndex]: e,
        };
        break;
    }
    setFilters(params);
    // history.push(FilterUtils.createUrl(params));
    history.push(FilterUtils.createUrl(params) + '?' + `page=1&isControlTower=true&storagePersonId=${storagePersonId}&person_company_affiliate_enum=${person_company_affiliate_enum}`);
    setScrollToFirstRow(true);
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    let params = {
      ...filters,
    };
    switch (dataIndex) {
      default:
        delete params[dataIndex];
        setFilters(params);
        history.push(FilterUtils.removeUrl(dataIndex));
        break;
    }
  };

  const getDateIcon = (filtered, dataIndex) => {
    return <CalendarOutlined style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getSearchIcon = (filtered, dataIndex) => {
    return <SearchOutlined style={{ color: filtered ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  const getFilteredIcon = (filtered, dataIndex) => {
    return <FilterOutlined style={{ color: filters.hasOwnProperty(dataIndex) ? 'var(--unnamed-color-1890ff)' : undefined }} />;
  };

  useEffect(() => {
    (async () => {
      try {
        let params;
        if (isCTUser) {
          params = {
            filters: date ? { ...filters, movement_date: date } : {
              ...filters,
              entity_id: storagePersonId,
              // user_type_enum: person_company_affiliate_enum === 1 ? 2 : ('2' || '3') && '1',
            },
            person_company_affiliate_enum: parseInt(person_company_affiliate_enum),
            is_market: false,
            offset: 0,
            page_Size: 10,
          };
        } else {
          params = {
            filters: date ? { ...filters, movement_date: date } : filters,
            offset: 0,
            page_Size: 10,
          };
        }
        setIsLoading(true);
        const success = await dispatch(storageActions.getStoragePlans(params));
        if (success) {
          setIsLoading(false);
        } else {
          setTimeout(() => setIsLoading(false), 3000);
        }
      } catch (err) {
        setIsLoading(false);
      }
    })();
  }, [isCreatePlan, storage_plan_master_id, storage_plan_name, modified_on, date, isConfirmModalOpen]);

  const PLAN_TABLE_HEADER = [
    {
      title: 'Plan Id',
      key: 'storage_plan_master_id',
      dataIndex: 'storage_plan_master_id',
      sorter: (a, b) => a.storage_plan_master_id - b.storage_plan_master_id,
      width: '120px',
      align: 'center',
      fixed: true,
      render: (text, row) => <div className="bfc-table-list-components">{row.storage_plan_master_id}</div>,
      filterDropdown: (props) => (
        <NumericSearchFilter
          dataIndex={'storage_plan_master_id'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={storage_plan_master_id}
          name={'Plan Id'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'storage_plan_master_id'),
      filteredValue: storage_plan_master_id !== undefined ? storage_plan_master_id : null,
    },
    {
      title: 'Plan Name',
      key: 'storage_plan_name',
      dataIndex: 'storage_plan_name',
      width: '120px',
      align: 'center',
      fixed: true,
      sorter: (a, b) =>
        a.storage_plan_name && a.storage_plan_name.localeCompare(b.storage_plan_name && b.storage_plan_name),
      render: (text, row) => <div className="bfc-table-list-components">{row.storage_plan_name}</div>,
      filterDropdown: (props) => (
        <TextSearchFilter
          dataIndex={'storage_plan_name'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={storage_plan_name}
          name={'Plan Name'}
        />
      ),
      filterIcon: (filtered) => getSearchIcon(filtered, 'storage_plan_name'),
      filteredValue: storage_plan_name !== undefined ? storage_plan_name : null,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '120px',
      align: 'center',
      sorter: (a, b) => a.location_code && a.location_code.localeCompare(b.location_code && b.location_code),
      render: (text, row) => <div className="bfc-table-list-components">{row.location_code}</div>,
    },
    {
      title: 'From',
      dataIndex: 'from',
      width: '120px',
      align: 'center',
      sorter: (a, b) =>
        a.movement_from_date && a.movement_from_date.localeCompare(b.movement_from_date && b.movement_from_date),
      render: (text, row) => (
        <div className="bfc-table-list-components">
          {row.movement_from_date ? dayjs(row.movement_from_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
    },
    {
      title: 'To',
      dataIndex: 'to',
      width: '120px',
      align: 'center',
      sorter: (a, b) =>
        a.movement_to_date && a.movement_to_date.localeCompare(b.movement_to_date && b.movement_to_date),
      render: (text, row) => (
        <div className="bfc-table-list-components">
          {row.movement_to_date ? dayjs(row.movement_to_date).format('DD-MMM-YYYY') : ''}
        </div>
      ),
    },
    {
      title: 'Modified On',
      dataIndex: 'modified_on',
      width: '140px',
      align: 'center',
      sorter: (a, b) => a.modified_on && a.modified_on.localeCompare(b.modified_on && b.modified_on),
      render: (text, row) => (
        <div className="bfc-table-list-components">
          {row.modified_on ? dayjs(row.modified_on).format('DD-MMM-YYYY') : ''}
        </div>
      ),
      filterDropdown: (props) => (
        <RangeDateSearchFilter
          dataIndex={'modified_on'}
          data={props}
          onFilterChange={onFilterChange}
          handleReset={handleReset}
          value={modified_on}
          name={'Modified On'}
        />
      ),
      filterIcon: (filtered) => getDateIcon(filtered, 'modified_on'),
      filteredValue: modified_on !== undefined ? modified_on : null,
    },
    {
      title: '',
      width: '80px',
      align: 'center',
      render: (text, row) => (
        !isSupportAccess && (
          <AntTableActions
            showFlag={isCTUser ? false : true}
            isConfirmModal={true}
            isOpen={isConfirmModalOpen}
            confirmModalMsg="Are you sure you want to delete this plan?"
            controls={[
              {
                name: 'Edit',
                onClick: () => handelPlanEdit(row),
                controlColor: 'var(--unnamed-color-1890ff)',
              },
              {
                name: 'Delete',
                onClick: () => handelPlanDelete(row),
                controlColor: '#ff4d4f',
              },
            ]}
          />
        )
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const CHILD_TABLE_HEADERS = [
      {
        title: '',
        width: '80px',
        align: 'center',
      },
      {
        title: 'Consume/Produce',
        width: '80px',
        align: 'center',
        render: (text, row) => <div className="bfc-table-list-components">{row.stock_in_out_type_enum_code}</div>,
      },
      {
        title: 'Form',
        width: '80px',
        align: 'center',
        render: (text, row) => (
          <div className="bfc-table-list-components font-weight-bold">{row.commodity_form_master_code}</div>
        ),
      },
      {
        title: 'Commodity',
        width: '80px',
        align: 'center',
        render: (text, row) => <div className="bfc-table-list-components">{row.commodity_code}</div>,
      },
      {
        title: 'Residue',
        width: '80px',
        align: 'center',
        render: (text, row) => <div className="bfc-table-list-components">{row.commodity_residue_master_code}</div>,
      },
      {
        title: 'Qty Per Day',
        width: '80px',
        align: 'center',
        render: (text, row) => (
          <div
            className="bfc-table-list-components font-weight-bold"
            style={{ color: row.stock_in_out_type_enum_code === 'Consume' ? '#e60e0e' : '#599906' }}>
            {row.qty && `${row.stock_in_out_type_enum_code === 'Consume' ? '-' : ''}${row.qty} MT`}
          </div>
        ),
      },
      {
        title: '',
        width: '80px',
        align: 'center',
      },
    ];
    const child_rows = _.get(record, 'child_rows') || [];
    let childTableData = child_rows || [];
    childTableData = childTableData.map((item) => ({ ...item, key: item.storage_plan_master_id }));
    return (
      <Table
        columns={CHILD_TABLE_HEADERS}
        dataSource={childTableData}
        pagination={false}
        // showHeader={false}
        rowClassName="ant-child-table-row"
        className="ant-child-table-header expand-table"
      />
    );
  };

  const handlePageChange = (updatePage) => {
    if (!_.isEmpty(updatePage)) {
      let params = {
        ...filters,
        page: updatePage ? updatePage.current : 1,
      };
      history.push(FilterUtils.createUrl(params));
      if (updatePage.pageSize !== rowsPerPage) {
        setRowsPerPage(updatePage.pageSize);
      }
    }
  };

  if (page === undefined) {
    page = 1;
    handlePageChange({ current: page, pageSize: rowsPerPage });
  }

  const handelPlanEdit = (row) => {
    setSelectedPlan(row);
    setIsEditPlan(true);
    setIsCreatePlan(true);
  };

  const handelPlanDelete = async (row) => {
    setIsConfirmModalOpen(true);
    // const planId = row.storage_plan_master_id;
    const params = {
      storage_plan_master_id: row.storage_plan_master_id,
    }
    notifications.show({
      type: 'info',
      message: 'Deleting Plan ...',
      key: 'req-form',
    });
    const success = await dispatch(storageActions.deleteStoragePlan(params));
    if (success) {
      notifications.show({
        type: 'success',
        message: 'Plan Deleted Successfully.',
        key: 'req-form',
      });
      setRerender(!rerender)
    }
  };

  const clearFilters = () => {
    setFilters({});
    let pathname = window.location.pathname;
    if (isCTUser) {
      history.push(pathname + '?' + `page=1&isControlTower=true&storagePersonId=${storagePersonId}&person_company_affiliate_enum=${person_company_affiliate_enum}`);
    } else {
      history.push(pathname + '?' + `page=1`);
    }
  };

  const handelOnCloseModal = () => {
    setIsCreatePlan(false)
    setIsEditPlan(false)
  }

  let tableData = Object.values(userStoragePlans) || [];
  tableData = tableData?.map((item) => ({ ...item, key: item.storage_plan_master_id }));
  const totalRecords = _.get(tableData, `[0].total_count`) || 0;

  useEffect(() => {
    if (scrollToFirstRow && tableRef.current) {
      const firstRow = tableRef.current.querySelector('.ant-table-row');
      if (firstRow) {
        firstRow.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToFirstRow(false);
    }
  }, [scrollToFirstRow]);

  return (
    <div className="plan-list">
      {/* {isLoading && (
        <div className="commodity-wrapper">
          <div className="panel__refresh">
            <LogoLoader />
          </div>
        </div>
      )} */}
      <div className="bfc-header m-0">
        <TableListHeader
          backLink={back}
          links={links}
          setRowsPerPage={setRowsPerPage}
          // heading={`${location} Storage`}
          rightPanContent={
            !isCTUser && (
              !isSupportAccess && (
                <>
                  <Button color="primary" size="sm" onClick={() => setIsCreatePlan(true)}>
                    Create Plan
                  </Button>
                </>
              )
            )
          }
        />
      </div>
      <Modal
        size={'lg'}
        open={isCreatePlan}
        setOpen={setIsCreatePlan}
        handelOnCloseModal={handelOnCloseModal}
        title={'Create Plan'}
        busy={isLoading}
        isIconRequired={false}
        loaderStyle={{ top: '50%', left: '50%', width: 'auto', height: 'auto' }}>
        <CreatePlan
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsCreatePlan={setIsCreatePlan}
          isEditPlan={isEditPlan}
          setIsEditPlan={setIsEditPlan}
          selectedPlan={selectedPlan}
        />
      </Modal>
      <div className="d-flex mt-0" style={{ width: 'calc(100% - 8px)' }}>
        <div id="datepicker-container" className='storage-datepicker'>
        <RangePicker
          open={true}
          // allowEmpty={[true, true]}
          value={value}
          onChange={(moment, date) => {
            setValue(moment);
            setDate(date[0] + ':' + date[1]);
          }}
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          renderExtraFooter={() => (
            <span
              className="ant-tag w-100 text-center"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setValue(['', '']);
                setDate('');
              }}>
              Reset
            </span>
          )}
          getPopupContainer={() => document.getElementById('datepicker-container')}
          style={{visibility:'hidden',height:0,width:0,margin:0,padding:0,border:0}}
          popupStyle={{ position: 'static', opacity: 1, pointerEvents: 'all', padding: 0 }}
        />

        </div>

        <div className="ant-table-body ant-table ml-1" style={{ width: 'calc(100% - 286px)' }}>
          <Table
            ref={tableRef}
            dataSource={!isLoading ? _.sortBy(tableData, 'transfer_date') : [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
            columns={isLoading ? PLAN_TABLE_HEADER.map((column) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      active="true"
                      key={column.dataIndex}
                      title={true}
                      paragraph={false}
                    />
                  );
                },
              };
            }) : PLAN_TABLE_HEADER}
            pagination={false}
            onChange={handlePageChange}
            scroll={{ y: 550, x: 550 }}
            className="ant-table"
            size="small"
            expandedRowRender={(record) => expandedRowRender(record)}
          />
          <AntPagination
            total={parseInt(totalRecords)}
            handlePageChange={handlePageChange}
            page={page}
            clearFilters={clearFilters}
            rowsPerPage={rowsPerPage}
            setScrollToFirstRow={setScrollToFirstRow}
          />
        </div>
      </div>
    </div>
  );
};
export default PlanList;
