import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav, NavItem, TabContent, TabPane, Container, Row } from 'reactstrap';
import _ from 'lodash';
import LoginForm from './components/LoginForm';
import OtpForm from './components/OtpForm';
import WelcomeCard from 'shared/components/WelcomeCard';
import { useSelector, useDispatch } from 'react-redux';
import storage from 'lib/storage';
import notifications from 'lib/notifications';
// import YoutubeIcon from 'assets/images/youtube-png.png';
// import ReactPlayer from 'react-player';
import TopBannerUnderMaintain from '../TopBannerUnderMaintain';
import TopBannerContryAlertMessage from '../TopBannerContryAlertMessage';
import { toast } from 'react-toastify';

import config from 'config';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import otplessSdk from "otpless-js-sdk";
import { actions as appConfigActions } from 'redux/appConfig';
import { getDecryptedUrl } from 'lib/utils';
import CookieConsent from 'components/CookieConsent';


const Login = (props) => {
  const dispatch = useDispatch();
  const loc = getDecryptedUrl(window.location.href);
  const { indentDealsUrl } = config;
  const user = useSelector((state) => state.user);
  const misc = useSelector((state) => state.misc);
  const [showDisabled, setShowDisabled] = useState(false);

  const getLogoutMessage = async () => {
    const logoutMessage = (await storage.getItem('LogoutMessage')) || '';
    if (logoutMessage !== '') {
      // notifications.showLogoutMessage({
      //   type: 'error',
      //   message: `${logoutMessage}`,
      //   key: 'req-form',
      //   duration: 10,
      // });
      toast.error(logoutMessage)
      await storage.removeItem('LogoutMessage');
    }
  };

  const is_kyc_done = _.get(user, 'profile.person.is_kyc_done');

  const type_of_individual_enum = _.get(user, 'profile.person.type_of_individual_enum');

  const user_type_enum = _.get(user, 'profile.person.user_type_enum');

  const id = _.get(user, 'profile.person.person_id');

  const underMaintenance = false;

  useEffect(() => {
    const tmr = setInterval(() => {
      window.location.reload(false);
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(tmr);
    };
  }, []);

  let currLocation = window.location.pathname;
  let profilePage = currLocation.includes('account');
  let kycPage = currLocation.includes('kyc');

  // https://my.biofuelcircle.com/account/login
  const handleConfirm = () => {
    if (window.location.href === 'https://testassist.biofuelcircle.com/account/login') {
      window.open("https://test.biofuelcircle.com/account/signup", "_blank")
    } else if (window.location.href === 'https://prepassist.biofuelcircle.com/account/login') {
      window.open("https://prep.biofuelcircle.com/account/signup", "_blank")
    } else if (window.location.href === 'https://prepsupply.biofuelcircle.com/account/login') {
      window.open("https://prep.biofuelcircle.com/account/signup", "_blank")
    } else if ("https://assist.biofuelcircle.com/account/login") {
      window.open("https://my.biofuelcircle.com/account/signup")  //Don't remove it
    }
  }

  const whatsAppButtonStyle = {
    backgroundColor: '#25D366',
    padding: '5.5px 25px',
    border: 'none', borderRadius: '25px',
    fontSize: ' var(--fs-base__two)',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const separatorStyle = {
    position: 'absolute',
    top: '-12px',
    background: '#fff',
    left: '44%',
    height: '25px',
    width: '36px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#707070',
  }

  let sdkInStance;
  let getIntent;

  useEffect(() => {
    (async () => {
      const clientId = await dispatch(appConfigActions.getAppConfig({ config_parameter: 'OTPless_Client_Id' }))
      // const clientSecret = await dispatch(appConfigActions.getAppConfig({ config_parameter: 'OTPless_Client_Secret' }))
      sdkInStance = otplessSdk({
        appId: clientId,
        enableErrorLogging: true,
      });
      let UrlToken = sdkInStance.getToken();
      UrlToken && setToken(UrlToken);
    })()
  });

  useEffect(()=>{
    window.FreshworksWidget('show', 'launcher');
  },[])

  const initiateLogin = () => {
    let redirectionUrl = 'https://my.biofuelcircle.com/sso/WA/callback';

    if (loc.includes('/smartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://smartbuyer.biofuelcircle.com/sso/WA/callback';
    }

    if (loc.includes('assist.biofuelcircle.com')) {
      redirectionUrl = 'https://assist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prep.biofuelcircle.com')) {
      redirectionUrl = 'https://prep.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prepsmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://prepsmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('prepassist.biofuelcircle.com')) {
      redirectionUrl = 'https://prepassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demo.biofuelcircle.com')) {
      redirectionUrl = 'https://demo.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demosmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://demosmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('demoassist.biofuelcircle.com')) {
      redirectionUrl = 'https://demoassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('test.biofuelcircle.com')) {
      redirectionUrl = 'https://test.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('testsmartbuyer.biofuelcircle.com')) {
      redirectionUrl = 'https://testsmartbuyer.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('testassist.biofuelcircle.com')) {
      redirectionUrl = 'https://testassist.biofuelcircle.com/sso/WA/callback';
    } else if (loc.includes('localhost:3000')) {
      redirectionUrl = 'http://localhost:3000/sso/WA/callback';
    }

    getIntent = sdkInStance.createGetIntentOnClick({
      redirectionURL: redirectionUrl,
    });
    return getIntent();
  };

  const renderForm = () => {
    let html = null;

    if (misc.authStep === 'otp') {
      html = (
        <React.Fragment>
          <OtpForm
            user={{
              ...user,
              ...misc,
            }}
          />
        </React.Fragment>
      );
    } else {
      html = (
        <div className="tabs tabs--justify tabs--bordered-bottom bfc-account-tabs">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink className="nav-link active" color="#1B62AB" to="/account/login">
                  LOG IN
                </NavLink>
              </NavItem>
              {!loc.includes(indentDealsUrl) && (
                <NavItem>
                  <NavLink className="nav-link" to="/account/signup">
                    SIGN UP
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={'1'}>
              <TabPane tabId="1">
                <LoginForm initiateLogin={initiateLogin} setShowDisabled={setShowDisabled} showDisabled={showDisabled} />
                {!loc.includes(indentDealsUrl) ? (
                  <div className="account__have-account bfc-login-page">
                    <p>
                      Don't have an account?
                      <Link to="/account/signup"> Signup</Link>
                    </p>
                  </div>
                ) : (
                  <div className="account__have-account bfc-login-page">
                    <p>
                      Don't have an account?
                      <span style={{ color: '#1b62ab', cursor: 'pointer' }} onClick={handleConfirm}> Signup</span>
                    </p>
                  </div>
                )}
                {/* <div className='position-relative'>
                  <hr />
                  <span style={separatorStyle}>OR</span>
                </div>
                <div className="bfc-center">
                  <button
                    size="sm"
                    className='mr-3 mb-3'
                    style={whatsAppButtonStyle}
                    onClick={() => initiateLogin()}
                  >
                    <WhatsAppIcon size={24} className="mr-2" />
                    Continue With Whatsapp
                  </button>
                </div> */}
                <div
                  className="account-embed-link">
                  <div className="embed-link__content">
                    {/* <iframe
                      className="embed-link__video"
                      width="120"
                      height="80"
                      src="https://www.youtube.com/embed/R_z6pxMcqH4"
                      frameborder="0"
                      allowfullscreen>
                    </iframe> */}
                    <a target="_blank" href="https://www.youtube.com/watch?v=R_z6pxMcqH4">
                      <h5 className="embed-link__title text-center ml-0">
                        How to Register on the Platform
                      </h5>
                    </a>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      );
    }
    getLogoutMessage();
    return html;
  };

  return (
    <>
      {underMaintenance ? (
        <>
          <div className="bfc-alert-wrapper">
            <TopBannerUnderMaintain id={id} user_type_enum={user_type_enum} />
          </div>
          <Container className="bfc-auth login-mobile-view mt-10" style={{ pointerEvents: 'none', opacity: .1, }}>
            <Row className="no-gutters set-border">
              <div className="col col-lg-3 offset-lg-3 bfc-container">
                <WelcomeCard />
              </div>

              <div className="col col-lg-3 bfc-container bfc-login-side">
                <div className="account bfc-account">
                  <div className="account__wrapper">
                    <div className="account__card">{renderForm()}</div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </>
      ) : (
        <>
          {showDisabled && (
            <div className="bfc-alert-wrapper phone-alert">
              <TopBannerContryAlertMessage customMessage="Please use an email address to login, if you have a non-India number. Alternatively, you can login with WhatsApp." />
            </div>
          )}
          <Container className="bfc-auth login-mobile-view mt-10">
            <Row className="no-gutters set-border">
              <div className="col col-lg-3 offset-lg-3 bfc-container">
                <WelcomeCard />
              </div>

              <div className="col col-lg-3 bfc-container bfc-login-side">
                <div className="account bfc-account">
                  <div className="account__wrapper">
                    <div className="account__card">{renderForm()}</div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </>
      )}
      <CookieConsent />
    </>
  );
};

export default Login;
