import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { util } from 'prettier';

export const reqTransform = {
  fetchList(params) {
    let bodyParams = {
      ...params,
    };
    return bodyParams;
  },
  create(params) {
    return params;
  },
  setSingleInvoiceDeals(params) {
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
  createSingleInvoice(params) {
    delete params['is_seller'];
    delete params['is_user_defined_invoice_number'];
    let bodyParams = {
      ...params,
    };
    return utils.getQs(bodyParams);
  },
};

export const resTransform = {
  fetchList(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.fetchDetails(details);
    });
    return _.keyBy(configurationData, 'cashflow_id');
  },
  fetchDetails(resData) {
    let res = {
      // id: _.get(resData, 'cashflow_id'),
      cashflow_id: _.get(resData, 'cashflow_id') || '',
      description: _.get(resData, 'description') || '',
      payment_due_date: _.get(resData, 'payment_due_date') || '',
      commodity_code: _.get(resData, 'commodity_code') || '',
      commodity_form_code: _.get(resData, 'commodity_form_code') || '',
      commodity_residue_code: _.get(resData, 'commodity_residue_code') || '',
      delivery_id: _.get(resData, 'delivery_id') || '',
      commodity_deal_master_id: _.get(resData, 'commodity_deal_master_id') || '',
      transport_deal_master_id: _.get(resData, 'transport_deal_master_id') || '',
      buyer_company_code: _.get(resData, 'buyer_company_code') || '',
      seller_company_code: _.get(resData, 'seller_company_code') || '',
      delivery_location_code: _.get(resData, 'delivery_location_code') || '',
      base_location_code: _.get(resData, 'base_location_code') || '',
      // qty_measured_at_enum_code: _.get(resData, 'qty_measured_at_enum_code') || '',
      qty: _.get(resData, 'qty') || '',
      qty_uom_code: _.get(resData, 'qty_uom_code') || '',
      base_amount: _.get(resData, 'base_amount') || '',
      tax_amount: _.get(resData, 'tax_amount') || '',
      gst_code: _.get(resData, 'gst_code') || '',
      total_amount: _.get(resData, 'total_amount') || '',
      // cashflow_status_enum_code: _.get(resData, 'cashflow_status_enum_code') || '',
      invoice_number: _.get(resData, 'invoice_number') || '',
      total_count: _.get(resData, 'total_count') || '',
      // is_aggregate_quantity_variations: _.get(resData, 'is_aggregate_quantity_variations'),
      is_seller: _.get(resData, 'is_seller'),
    };
    return res;
  },
  setSingleInvoiceDeals(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'commodity_deal_master_id') || 0,
    };
    return res;
  },
  create(resData) {
    let configurationData = (_.isArray(resData) ? resData : []).map((details) => {
      return resTransform.createDetails(details);
    });
    return _.keyBy(configurationData, 'seller_company_id');
  },
  createDetails(resData) {
    let res = {
      ...resData,
      id: _.get(resData, 'seller_company_id'),
    };
    return res;
  },
  createSingleInvoice(resData) {
    return resData;
  },
};
