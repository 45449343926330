/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Formik, withFormik, Field, Form } from 'formik';
import _ from 'lodash';

import FormikLocationSelect from 'common/Form/FormikSignUpCountrySelect';
import searchLocation from 'common/Form/searchLocation';
import Organisation from './Organisation';

import FormikMaterialTextField from 'shared/components/form/FormikMaterialTextField';
import FormikCheckBox from 'shared/components/form/FormikCheckBox';

import validate from 'lib/validations/details';
import ReferenceDataSelect from './ReferenceDataSelect';
import notifications from 'lib/notifications';
import api from 'lib/api';
import { useHistory } from 'react-router-dom';
import utils from 'lib/utils';
import * as handlers from './../handlers';
import * as uploadHandlers from '../../Profile/UploadBusiness/handlers';
import { actions as locationElocActions } from 'redux/locationEloc';
import { useSelector, useDispatch } from 'react-redux';
import { locationElocService } from 'services';
import { IconButton, TextField } from '@material-ui/core';
import DropZone from 'shared/components/form/DropZone';
import Dropzone from 'react-dropzone';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Modal from 'pages/CTDataSetup/commodityDetails/components/Modal';
import SearchIFSCForm from './SearchIFSCForm';
import { actions as farmerActions } from 'redux/farmerOnboarding';
import LogoLoader from 'common/LogoLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIToggleControl from 'common/MUIComponents/ToggleControl';
import MUIModal from 'common/MUIComponents/Modal';
import getColor from 'common/MUIComponents/colors';
import { toast } from 'react-toastify';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const getInitialValues = (user) => {
  return {
    user_name: user.user_name,
    first_name: '',
    last_name: '',
    email_id: !utils.isNumber(user.user_name) ? user.user_name : '',
    mobile_number: utils.isNumber(user.user_name) ? user.user_name : '',
    mobile_number_country_code: user.mobile_number_country_code || 91,
    company_id: null,
    company_tagname: null,
    is_registered_as_company: false,
    is_newsletter_subscribed: false,
    address_lattitude: '',
    address_longitude: '',
    // location_id: 0,
    location_code: '',
    // village_id: 0,
    village_code: '',
    // taluka_id: 0,
    taluka_code: '',
    // city_id: 0,
    city_code: '',
    // district_id: 0,
    district_code: '',
    sub_district_code: '',
    // state_id: 0,
    state_code: '',
    country_id: 0,
    country_code: 'India',

    place_id: '',
    address_line1: '',
    address_line2: '',
    address_line3: '',
    pin_code: '',
    locality: '',
    // address_line1: '',
    // address_line2: '',
    // address_line3: '',

    // pin_code: '',

    // ...(user || {}),
  };
};
const getFarmerInitialValues = (user) => {
  return {
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: utils.isNumber(user.user_name) ? user.user_name : '',
    mobile_number_country_code: user.mobile_number_country_code || 91,
    pan_number: '',
    uid_card_number: '',
    bank_account_number: '',
    ifsc_code: '',
    farm_acreage: '',
    fcm_token: '',
    agent_code: '',
    farm_location_detail: {
      location_code: '',
      address_lattitude: '',
      address_longitude: '',
      place_id: '',
      village_code: '',
      district_code: '',
      sub_district_code: '',
      city_code: '',
      state_code: '',
      country_code: '',
      address_text: '',
      pincode: '',
      address_line1: '',
      address_line2: '',
      address_line3: '',
    },
  };
};

const DetailsForm = (props) => {
  const user = useSelector((state) => state.user);
  const misc = useSelector((state) => state.misc);
  const authStep = misc.authStep || '';

  const history = useHistory();
  const dispatch = useDispatch();

  const { isSubmitting, errors, values, initialValues, touched, isFarmerUser, isNewFarmer } = props;
  const isAdditionalDetails = authStep === 'additionalDetails';
  const heading = isAdditionalDetails ? 'Additional Details' : `Personal Details`;

  useEffect(() => {
    dispatch(locationElocActions.getOAuthToken());
  }, []);

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFacilitator, setSelectedFacilitator] = useState('');
  const [isGpa, setIsGpa] = useState(true);
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);
  const [vpaData, setVpaData] = useState(false);
  const [countryPhone, setCountryPhone] = useState('');


  const [latLongData, setLatLongData] = useState({});
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');
  const farmerOnboarding = useSelector((state) => state.farmerOnboarding);
  const busy = farmerOnboarding?.busy;

  const whatsappNumber = _.get(user, 'profile.person.whatsapp_number', '')
  const mobileNumberCountryCode = _.get(user, 'profile.person.mobile_number_country_code', '')
  const whatsappNumberCountryCode = _.get(user, 'profile.person.whatsapp_number_country_code', '')
  const mobileNumber = _.get(user, 'profile.person.mobile_number', '')


  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  useEffect(() => {
    if (searchValue !== '') {
      let filters;
      if (parseInt(searchValue)) {
        filters = {
          mobile_number: `${searchValue}`,
        };
      } else {
        filters = {
          person_name: `${searchValue}`,
        };
      }
      let params = {
        filters: filters,
        offset: 0,
        page_size: 10,
      };
      dispatch(farmerActions.getFacilitators(params));
    }
  }, [searchValue]);

  const validateValues = (values) => {
    if(isFarmerUser) {
      const errors = {};
      if (!values.first_name) {
        errors['first_name'] = 'First Name is required';
      }
      if (!values.last_name) {
        errors['last_name'] = 'Last Name is required';
      }
      return errors;
    }
    if(isNewFarmer) {
      const errors = {};
      const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
      // if (!values?.pan_number) {
      //   errors['pan_number'] = 'Pan number is required';
      // }
      if (values?.pan_number && panRegex.test(values?.pan_number) === false) {
        errors['pan_number'] = 'Please enter valid pan number';
      }
      if (!values.first_name) {
        errors['first_name'] = 'First Name is required';
      }
      if (!values.middle_name) {
        errors['middle_name'] = 'Middle Name is required';
      }
      if (!values.last_name) {
        errors['last_name'] = 'Last Name is required';
      }
      // if (!values.uid_card_number) {
      //   errors['uid_card_number'] = 'UID number is required';
      // }
      // const UIDRegex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
      // if (UIDRegex.test(values.uid_card_number) === false && values.uid_card_number.length <= 19) {
      //   errors['uid_card_number'] = 'Please enter valid UID number';
      // }
      if (!values.bank_account_number) {
        errors['bank_account_number'] = 'Bank account number is required';
      }
      if (!values.ifsc_code) {
        errors['ifsc_code'] = 'IFSC code is required';
      }
      const IFSCRgex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (IFSCRgex.test(values.ifsc_code) === false && values.ifsc_code.length <= 11) {
        errors['ifsc_code'] = 'Please enter valid IFSC code';
      }
      if (!values.farm_acreage) {
        errors['farm_acreage'] = 'Farm acreage is required';
      }
      // if (!values.agent_code) {
      //   errors['agent_code'] = 'Facilitator number is required';
      // }
      // if (!values.location_code) {
      //   errors['location_code'] = 'Location is required';
      // }
      return errors;
    }
  };

  let files;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {!isFarmerUser || !isNewFarmer && (
            <div className="card__title mb-10">
              <h5 className="bold-text">{heading}</h5>
              <hr className="bfc-lineBreak" />
            </div>
          )}

          <Formik
            initialValues={
              isFarmerUser || isNewFarmer
                ? getFarmerInitialValues({ ...user, ...misc })
                : getInitialValues({
                  ...user,
                  ...misc,
                })
            }
            validate={(isFarmerUser || isNewFarmer) ? validateValues : validate}
            onSubmit={(isFarmerUser || isNewFarmer) ? (values, formikBag) => handlers.handleFarmerDetailsSubmit(values, formikBag, latLongData, isFarmerUser, isNewFarmer, isRegisterPopupOpen, setIsRegisterPopupOpen, setVpaData, vpaData) : handlers.handleDetailsSubmit}>
            {(formikBag) => {
              // console.log('\n------------profileDetails-------------');

              const { values, initialValues, isSubmitting, setFieldValue, touched, errors, setFieldTouched } =
                formikBag;
              // console.log('\n============DetailsForm==========');
              // console.log('values-->', values);
              // console.log('errors-->', errors);
              // console.log('touched-->', touched);
              // console.log('formik-values--->', formikBag.values);
              values.address_lattitude = (_.get(latLongData, 'latitude') || '').toString();
              values.address_longitude = (_.get(latLongData, 'longitude') || '').toString();
              if (isFarmerUser || isNewFarmer) {
                values.farm_location_detail.address_lattitude = (_.get(latLongData, 'latitude') || '').toString();
                values.farm_location_detail.address_longitude = (_.get(latLongData, 'longitude') || '').toString();
              }

              // console.log('\n============DetailsForm==========');
              // console.log('isSubmitting-->', isSubmitting);
              // console.log('errors-->', formikBag.errors);
              // console.log('touched-->', touched);
              // console.log('sign up -DetailsForm formik-values--->', formikBag.values);

              const buttonStyles = {
                border: 'none',
                backgroundColor: 'var(--unnamed-color-1b62ab)',
                color: '#fff',
                borderRadius: '4px',
                padding: '3px 8px',
              };

              const linkBtnStyles = {
                border: 'none',
                backgroundColor: 'transparent',
                color: 'var(--unnamed-color-1b62ab)',
                padding: '0',
              };

              const inputStyle = {
                width: 'calc(100% - 67px)',
              };

              return (
                <Form className="material-form signup-form">
                  {busy && (
                    <div className="panel__refresh">
                      <LogoLoader />
                    </div>
                  )}
                  {isFarmerUser ? (
                    <div className="farmer-details">
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="First Name"
                            value={values['first_name']}
                            error={touched['first_name'] && errors['first_name']}
                            helperText={touched['first_name'] && errors['first_name']}
                            onChange={(e) => {
                              setFieldTouched('first_name', true, true);
                              if (/^[a-zA-Z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                setFieldValue('first_name', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {farmerOnboarding?.bankVerificationDetails?.is_any_error === false && (
                            <span style={{ color: 'green' }}>
                              {farmerOnboarding?.bankVerificationDetails?.log?.beneficiary_name?.split(' ')?.[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Last Name"
                            value={values['last_name']}
                            error={touched['last_name'] && errors['last_name']}
                            helperText={touched['last_name'] && errors['last_name']}
                            onChange={(e) => {
                              setFieldTouched('last_name', true, true);
                              if (/^[a-zA-Z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                setFieldValue('last_name', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {farmerOnboarding?.bankVerificationDetails?.is_any_error === false && (
                            <span style={{ color: 'green' }}>
                              {farmerOnboarding?.bankVerificationDetails?.log?.beneficiary_name?.split(' ')?.[2]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <Autocomplete
                            size="small"
                            fullWidth
                            value={selectedFacilitator}
                            options={farmerOnboarding?.facilitators || []}
                            getOptionLabel={(option) =>
                              option?.person_name && option?.mobile_number
                                ? `${option?.person_name} (${option?.mobile_number})`
                                : ''
                            }
                            onChange={(e, newValue) => {
                              setFieldTouched('agent_code', true, true);
                              setFieldValue('agent_code', newValue?.mobile_number);
                              setSelectedFacilitator(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                className="material-form__field"
                                placeholder={'Enter 3 characters to search facilitator'}
                                label={`Did someone help you?`}
                                onChange={(e) => {
                                  const searchText = e.target.value;
                                  if (searchText.length >= 3) {
                                    setSearchValue(searchText);
                                  }
                                }}
                                error={touched['agent_code'] && errors['agent_code']}
                                helperText={touched['agent_code'] && errors['agent_code']}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field">
                          <Field
                            className="material-form__field"
                            name="location_code"
                            // component={Location}
                            variant="standard"
                            component={searchLocation}
                            placeholder="Select Location"
                            label="Location"
                            cb={(locationValues) => {
                              // console.log('locationValues============', locationValues);
                              let locationDetails = locationValues[0] || {};
                              let addressLine1 =
                                (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                  ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.houseName', '')
                                  ? _.get(locationDetails, 'addressTokens.houseName', '')
                                  : '');

                              let addressLine2 =
                                (_.get(locationDetails, 'addressTokens.street', '')
                                  ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  : '');

                              let addressLine3 =
                                (_.get(locationDetails, 'addressTokens.subLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.locality', '')
                                  ? _.get(locationDetails, 'addressTokens.locality', '')
                                  : '');
                              formikBag.setFieldValue(
                                'farm_location_detail.pincode',
                                _.get(locationDetails.addressTokens, 'pincode') || '',
                              );
                              formikBag.setFieldValue('farm_location_detail.address_line1', addressLine1);
                              formikBag.setFieldValue('farm_location_detail.address_line2', addressLine2);
                              formikBag.setFieldValue('farm_location_detail.address_line3', addressLine3);
                              formikBag.setFieldValue(
                                'farm_location_detail.city_code',
                                _.get(locationDetails.addressTokens, 'city') || '',
                              );

                              formikBag.setFieldValue(
                                'farm_location_detail.district_code',
                                _.get(locationDetails.addressTokens, 'district') || '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.sub_district_code',
                                _.get(locationDetails.addressTokens, 'subDistrict') || '',
                              )
                              formikBag.setFieldValue(
                                'farm_location_detail.village_code',
                                _.get(locationDetails.addressTokens, 'village') ||
                                _.get(locationDetails.addressTokens, 'city') ||
                                _.get(locationDetails.addressTokens, 'subDistrict') ||
                                '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.state_code',
                                _.get(locationDetails.addressTokens, 'state') || '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.place_id',
                                _.get(locationDetails, 'eLoc') || '',
                              );
                              // formikBag.setFieldValue(
                              //   'farm_location_detail.address_lattitude',
                              //   _.get(locationDetails, 'latitude', '1'),
                              // );
                              formikBag.setFieldValue(
                                'farm_location_detail.address_lattitude',
                                latLongData?.latitude,
                              );
                              // formikBag.setFieldValue(
                              //   'farm_location_detail.address_longitude',
                              //   _.get(locationDetails, 'longitude', '1'),
                              // );
                              formikBag.setFieldValue(
                                'farm_location_detail.address_longitude',
                                latLongData?.longitude,
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.location_code',
                                (_.get(locationDetails, 'placeName', '')
                                  ? _.get(locationDetails, 'placeName', '') + ', '
                                  : '') + _.get(locationDetails, 'placeAddress', ''),
                              );
                              formikBag.setFieldValue(
                                'location_code',
                                (_.get(locationDetails, 'placeName', '')
                                  ? _.get(locationDetails, 'placeName', '') + ', '
                                  : '') + _.get(locationDetails, 'placeAddress', ''),
                              );
                              if (locationValues) {
                                // locationDetails = locationValues[0] || {};
                                // setLocDetailsValue(locationValues[0]?.addressTokens);
                                setLocDetailsEloc(locationValues[0]?.eLoc);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-start">
                          <MUIToggleControl label={<>Agree To <a target="_blank" href="https://my.biofuelcircle.com/Participation_Agreement.htm">Global Participation Agreement</a></>} value={isGpa} onChange={(e, checked, name) => {
                            setIsGpa(checked)
                            let toastId;
                            if (!checked) {
                              toastId = toast.error('You need to agree to the Global Participation Agreement to Proceed')
                            } else {
                              toast.dismiss(toastId)
                            }
                          }} />
                        </div>
                      </div>
                      <ButtonToolbar className="form__button-toolbar bfc-account__btns">
                        <Button
                          className="bfc-buttonWidth"
                          color="primary"
                          type="button"
                          size="sm"
                          disabled={!isGpa}
                          onClick={formikBag.handleSubmit}>
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          )}{' '}
                          Proceed
                        </Button>
                      </ButtonToolbar>
                      <MUIModal
                        open={isRegisterPopupOpen}
                        setOpen={setIsRegisterPopupOpen}
                        title='Confirm VPA'
                        confirmationMessage='Is this your current & active UPI id? We will pay you on this UPI id'
                        actionButtons={[{name: 'Yes', onClick:() => {
                          notifications.show({
                            type: 'success',
                            message:
                              'Thank you for confirming your UPI id!.',
                          });
                          handlers.handleFarmerDetailsSubmit(values, formikBag, latLongData, isFarmerUser, isNewFarmer, isRegisterPopupOpen, setIsRegisterPopupOpen, setVpaData, vpaData)
                        }}, {name: 'No', onClick:() => {
                          setIsRegisterPopupOpen(false)
                          handlers.handleFarmerDetailsSubmit(values, formikBag, latLongData, isFarmerUser, isNewFarmer, isRegisterPopupOpen, setIsRegisterPopupOpen, setVpaData, false)
                        }}]}
                      >
                        <div className='font-weight-bold' style={{color: getColor('muiPrimary')}} >{vpaData?.name_at_bank}</div>
                        <div className='font-weight-bold' style={{color: getColor('muiPrimary')}} >{vpaData?.vpa}</div>
                      </MUIModal>
                    </div>
                  ) : isNewFarmer ? (
                    <div className="farmer-details">
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Enter Pan Number (Optional)"
                            placeholder="ABCDE1234Z"
                            value={values['pan_number']}
                            error={touched['pan_number'] && errors['pan_number']}
                            helperText={touched['pan_number'] && errors['pan_number']}
                            onChange={(e) => {
                              setFieldTouched('pan_number', true, true);
                              const regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
                              if (/^[A-Z0-9]*$/.test(e.target.value.toUpperCase()) && e.target.value.length <= 10) {
                                setFieldValue('pan_number', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {values['pan_number'] && !errors['pan_number'] && (
                            <button
                              className="ml-3 font-weight-bold"
                              style={
                                farmerOnboarding?.panVerificationDetails?.kyc_message?.includes('verified')
                                  ? { ...linkBtnStyles, color: 'var(--unnamed-color-4ce1b6)' }
                                  : linkBtnStyles
                              }
                              onClick={async (e) => {
                                e.preventDefault();
                                const params = {
                                  verification_type_enum: 1,
                                  document_number: values?.pan_number,
                                  document_name: 'Pan Card',
                                  entity_id: 0,
                                  company_affiliate_enum: 0,
                                  expiry_date: null,
                                  just_return_name: true,
                                };
                                const res = await dispatch(farmerActions.kycVerification(params));
                                if (res?.name_as_per_pa_n) {
                                  setFieldValue('name_as_per_pan', res?.name_as_per_pa_n);
                                  setFieldValue('first_name', res?.name_as_per_pa_n?.split(' ')?.[0]);
                                  setFieldValue('middle_name', res?.name_as_per_pa_n?.split(' ')?.[1]);
                                  setFieldValue('last_name', res?.name_as_per_pa_n?.split(' ')?.[2]);
                                }
                              }}>
                              {farmerOnboarding?.panVerificationDetails?.kyc_message?.includes('verified')
                                ? 'Verified'
                                : 'Verify'}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="First Name"
                            value={values['first_name']}
                            error={touched['first_name'] && errors['first_name']}
                            helperText={touched['first_name'] && errors['first_name']}
                            onChange={(e) => {
                              setFieldTouched('first_name', true, true);
                              if (/^[a-zA-Z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                setFieldValue('first_name', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {farmerOnboarding?.bankVerificationDetails?.is_any_error === false && (
                            <span style={{ color: 'green' }}>
                              {farmerOnboarding?.bankVerificationDetails?.log?.beneficiary_name?.split(' ')?.[0]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Middle Name"
                            value={values['middle_name']}
                            error={touched['middle_name'] && errors['middle_name']}
                            helperText={touched['middle_name'] && errors['middle_name']}
                            onChange={(e) => {
                              setFieldTouched('middle_name', true, true);
                              if (/^[a-zA-Z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                setFieldValue('middle_name', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {farmerOnboarding?.bankVerificationDetails?.is_any_error === false && (
                            <span style={{ color: 'green' }}>
                              {farmerOnboarding?.bankVerificationDetails?.log?.beneficiary_name?.split(' ')?.[1]}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Last Name"
                            value={values['last_name']}
                            error={touched['last_name'] && errors['last_name']}
                            helperText={touched['last_name'] && errors['last_name']}
                            onChange={(e) => {
                              setFieldTouched('last_name', true, true);
                              if (/^[a-zA-Z]*$/.test(e.target.value) && e.target.value.length <= 30) {
                                setFieldValue('last_name', e.target.value.toUpperCase());
                              }
                            }}
                          />
                          {farmerOnboarding?.bankVerificationDetails?.is_any_error === false && (
                            <span style={{ color: 'green' }}>
                              {farmerOnboarding?.bankVerificationDetails?.log?.beneficiary_name?.split(' ')?.[2]}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Enter UID Number"
                            placeholder="2123 4567 8910 1112"
                            value={values['uid_card_number']}
                            error={touched['uid_card_number'] && errors['uid_card_number']}
                            helperText={touched['uid_card_number'] && errors['uid_card_number']}
                            onChange={(e) => {
                              setFieldTouched('uid_card_number', true, true);
                              const regex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
                              if ((/^[0-9\s]*$/.test(e.target.value.toUpperCase()) && e.target.value.length <= 19) || !e.target.value) {
                                setFieldValue('uid_card_number', e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div> */}
                      <div className="col">
                        <div className="form-group-field mb-10">
                          <div className="d-flex align-items-center">
                            <TextField
                              className="material-form__field mb-0"
                              type="text"
                              label="Enter IFSC Code"
                              placeholder="SBIN0125620"
                              value={values['ifsc_code']}
                              error={touched['ifsc_code'] && errors['ifsc_code']}
                              helperText={touched['ifsc_code'] && errors['ifsc_code']}
                              onChange={(e) => {
                                setFieldTouched('ifsc_code', true, true);
                                const regex = /^[A-Z]{4}0[A-Z0-9]{6}?$/;
                                if (
                                  (/^[a-zA-z0-9]*$/.test(e.target.value.toUpperCase()) &&
                                    e.target.value.length <= 11) ||
                                  !e.target.value
                                ) {
                                  setFieldValue('ifsc_code', e.target.value.toUpperCase());
                                }
                              }}
                            />
                          </div>
                          <button style={linkBtnStyles} onClick={(e) => setIsOpen(true)}>
                            Search IFSC
                          </button>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Enter Account Number"
                            value={values['bank_account_number']}
                            error={touched['bank_account_number'] && errors['bank_account_number']}
                            helperText={touched['bank_account_number'] && errors['bank_account_number']}
                            onChange={(e) => {
                              setFieldTouched('bank_account_number', true, true);
                              if (/^\d*$/.test(e.target.value) && e.target.value.length <= 20)
                                setFieldValue('bank_account_number', e.target.value);
                            }}
                          />
                          {values?.ifsc_code && values?.bank_account_number && (
                            <button
                              className="ml-3 font-weight-bold cursor-pointer"
                              style={
                                farmerOnboarding?.bankVerificationDetails?.is_any_error === false
                                  ? { ...linkBtnStyles, color: 'var(--unnamed-color-4ce1b6)' }
                                  : farmerOnboarding?.bankVerificationDetails?.is_any_error
                                  ? { ...linkBtnStyles, color: 'red' }
                                  : linkBtnStyles
                              }
                              disabled={farmerOnboarding?.bankVerificationDetails?.is_any_error === false}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const params = {
                                  account_number: values?.bank_account_number,
                                  ifsc_code: values?.ifsc_code,
                                  beneficiary_name: `${values?.first_name}  ${values?.middle_name} ${values?.last_name}`,
                                };
                                dispatch(farmerActions.verifyBankAccount(params));
                              }}>
                              {farmerOnboarding?.bankVerificationDetails?.is_any_error === false
                                ? 'Verified'
                                : farmerOnboarding?.bankVerificationDetails?.is_any_error
                                ? 'Failed'
                                : 'Verify'}
                            </button>
                          )}
                        </div>
                      </div>
                      {farmerOnboarding?.bankVerificationDetails?.is_any_error === true && (
                        <div className="col">
                          <label className="mb-0">Upload Bank Passbook/Cross Cancelled Cheque</label>
                          <div className="form-group-field d-flex align-items-center">
                            <Dropzone
                              accept="image/jpeg, image/png"
                              onDrop={(fileToUpload) => {
                                const val = fileToUpload.map((fl) =>
                                  Object.assign(fl, {
                                    preview: URL.createObjectURL(fl),
                                  }),
                                );

                                if (val[0].size > 5242880) {
                                  try {
                                    notifications.show({
                                      type: 'error',
                                      message:
                                        'Uploaded Attachment is exceeding the size. Please Upload Attachment of max 5MB size.',
                                      key: 'req-form',
                                    });
                                    return false;
                                  } catch (err) {
                                    console.log('error', err);
                                  }
                                } else {
                                  val[0] = val[val.length - 1];
                                }

                                return setFieldValue('files', val);
                              }}>
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  {(!values?.files || values?.files.length === 0) && (
                                    <div
                                      className="dropzone__drop-here"
                                      style={{
                                        color: 'var(--unnamed-color-1b62ab)',
                                        marginLeft: '8px',
                                      }}>
                                      <span className="lnr lnr-upload" /> Select file to upload
                                    </div>
                                  )}
                                  <input {...getInputProps()} />
                                </div>
                              )}
                            </Dropzone>
                            {values?.files && Array.isArray(values?.files) && values?.files.length > 0 && (
                              <div className="d-flex align-items-center">
                                <p style={{ color: 'var(--unnamed-color-1b62ab)' }}>{values?.files[0].name}</p>
                                <IconButton
                                  size="small"
                                  onClick={(e, index) => {
                                    const updatedFiles = values?.files.filter((val, i) => i !== index);
                                    setFieldValue('files', []);
                                  }}>
                                  <TrashIcon
                                    className="cursor-pointer"
                                    style={{
                                      color: 'var(--unnamed-color-f44336)',
                                      marginLeft: '8px',
                                    }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <TextField
                            className="material-form__field"
                            type="text"
                            label="Farm Acreage"
                            value={values['farm_acreage']}
                            error={touched['farm_acreage'] && errors['farm_acreage']}
                            helperText={touched['farm_acreage'] && errors['farm_acreage']}
                            onChange={(e) => {
                              setFieldTouched('farm_acreage', true, true);
                              if (/^\d+(\.\d{0,2})?$/.test(e.target.value) || !e.target.value) {
                                setFieldValue('farm_acreage', e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field d-flex align-items-center">
                          <Autocomplete
                            size="small"
                            fullWidth
                            value={selectedFacilitator}
                            options={farmerOnboarding?.facilitators || []}
                            getOptionLabel={(option) =>
                              option?.person_name && option?.mobile_number
                                ? `${option?.person_name} (${option?.mobile_number})`
                                : ''
                            }
                            onChange={(e, newValue) => {
                              setFieldTouched('agent_code', true, true);
                              setFieldValue('agent_code', newValue?.mobile_number);
                              setSelectedFacilitator(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                className="material-form__field"
                                placeholder={'Enter 3 characters to search facilitator'}
                                label={`Did someone help you?`}
                                onChange={(e) => {
                                  const searchText = e.target.value;
                                  if (searchText.length >= 3) {
                                    setSearchValue(searchText);
                                  }
                                }}
                                error={touched['agent_code'] && errors['agent_code']}
                                helperText={touched['agent_code'] && errors['agent_code']}
                              />
                            )}
                          />
                          {/* <TextField
                            className="material-form__field"
                            type="text"
                            label="Facilitator's Number"
                            value={values['agent_code']}
                            error={touched['agent_code'] && errors['agent_code']}
                            helperText={touched['agent_code'] && errors['agent_code']}
                            onChange={(e) => {
                              setFieldTouched('agent_code', true, true);
                              if ((/^\d*$/.test(e.target.value) && e.target.value.length <= 10) || !e.target.value) {
                                setFieldValue('agent_code', e.target.value);
                              }
                            }}
                          /> */}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group-field">
                          <Field
                            className="material-form__field"
                            name="location_code"
                            // component={Location}
                            variant="standard"
                            component={searchLocation}
                            placeholder="Select Location"
                            label="Location"
                            cb={(locationValues) => {
                              // console.log('locationValues============', locationValues);
                              let locationDetails = locationValues[0] || {};
                              //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                              // if (locationValues) {
                              //   locationDetails = locationValues[0] || {};
                              //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                              //   setLocDetailsEloc(locationValues[0]?.eLoc);
                              // }

                              let addressLine1 =
                                (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                  ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.houseName', '')
                                  ? _.get(locationDetails, 'addressTokens.houseName', '')
                                  : '');

                              let addressLine2 =
                                (_.get(locationDetails, 'addressTokens.street', '')
                                  ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                  : '');

                              let addressLine3 =
                                (_.get(locationDetails, 'addressTokens.subLocality', '')
                                  ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                  : '') +
                                (_.get(locationDetails, 'addressTokens.locality', '')
                                  ? _.get(locationDetails, 'addressTokens.locality', '')
                                  : '');
                              formikBag.setFieldValue(
                                'farm_location_detail.pincode',
                                _.get(locationDetails.addressTokens, 'pincode') || '',
                              );
                              formikBag.setFieldValue('farm_location_detail.address_line1', addressLine1);
                              formikBag.setFieldValue('farm_location_detail.address_line2', addressLine2);
                              formikBag.setFieldValue('farm_location_detail.address_line3', addressLine3);
                              formikBag.setFieldValue(
                                'farm_location_detail.city_code',
                                _.get(locationDetails.addressTokens, 'city') || '',
                              );

                              formikBag.setFieldValue(
                                'farm_location_detail.district_code',
                                _.get(locationDetails.addressTokens, 'district') || '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.sub_district_code',
                                _.get(locationDetails.addressTokens, 'subDistrict') || '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.village_code',
                                _.get(locationDetails.addressTokens, 'village') ||
                                _.get(locationDetails.addressTokens, 'city') ||
                                _.get(locationDetails.addressTokens, 'subDistrict') ||
                                '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.state_code',
                                _.get(locationDetails.addressTokens, 'state') || '',
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.place_id',
                                _.get(locationDetails, 'eLoc') || '',
                              );
                              // formikBag.setFieldValue(
                              //   'farm_location_detail.address_lattitude',
                              //   _.get(locationDetails, 'latitude', '1'),
                              // );
                              formikBag.setFieldValue(
                                'farm_location_detail.address_lattitude',
                                latLongData?.latitude,
                              );
                              // formikBag.setFieldValue(
                              //   'farm_location_detail.address_longitude',
                              //   _.get(locationDetails, 'longitude', '1'),
                              // );
                              formikBag.setFieldValue(
                                'farm_location_detail.address_longitude',
                                latLongData?.longitude,
                              );
                              formikBag.setFieldValue(
                                'farm_location_detail.location_code',
                                (_.get(locationDetails, 'placeName', '')
                                  ? _.get(locationDetails, 'placeName', '') + ', '
                                  : '') + _.get(locationDetails, 'placeAddress', ''),
                              );
                              formikBag.setFieldValue(
                                'location_code',
                                (_.get(locationDetails, 'placeName', '')
                                  ? _.get(locationDetails, 'placeName', '') + ', '
                                  : '') + _.get(locationDetails, 'placeAddress', ''),
                              );
                              if (locationValues) {
                                // locationDetails = locationValues[0] || {};
                                // setLocDetailsValue(locationValues[0]?.addressTokens);
                                setLocDetailsEloc(locationValues[0]?.eLoc);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <ButtonToolbar className="form__button-toolbar bfc-account__btns">
                        <Button
                          className="bfc-buttonWidth"
                          color="primary"
                          type="button"
                          size="sm"
                          disabled={
                            isSubmitting || farmerOnboarding?.bankVerificationDetails?.is_any_error !== false || farmerOnboarding?.bankVerificationDetails?.is_any_error === true
                              ? !values?.files?.length
                              : ''
                          }
                          onClick={formikBag.handleSubmit}>
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          )}{' '}
                          Proceed
                        </Button>
                      </ButtonToolbar>
                      <div className="plan-list" style={{ width: '65%' }}>
                        <Modal
                          size={'md'}
                          open={isOpen}
                          setOpen={setIsOpen}
                          title={'IFSC Code Finder'}
                          busy={false}
                          isIconRequired={false}
                          loaderStyle={{ top: '50%', left: '50%', width: 'auto', height: 'auto' }}>
                          <SearchIFSCForm formikBag={formikBag} setIsOpen={setIsOpen} />
                        </Modal>
                      </div>
                    </div>
                  ) : (
                    <div className={isAdditionalDetails ? 'd-none' : ''}>
                      <div>
                        <Field
                          name="first_name"
                          component={FormikMaterialTextField}
                          // placeholder="First Name"
                          label="First Name"
                        />
                      </div>
                      <div>
                        <Field
                          name="last_name"
                          component={FormikMaterialTextField}
                          // placeholder="Last Name"
                          label="Last Name"
                        />
                      </div>

                      <div className="mt-20 mb-20">
                        <Field
                          name="email_id"
                          component={FormikMaterialTextField}
                          // placeholder="example@mail.com"
                          type="emailid"
                          label="Email"
                          disabled={!!initialValues.email_id}
                        />
                      </div>
                      <div className="mb-15">
                        <PhoneInput
                          country={'in'}
                          value={mobileNumber ? mobileNumberCountryCode + mobileNumber : countryPhone}
                          preferredCountries={['in']}
                          onChange={(event, name) => {
                            setCountryPhone(event);
                            const originalString = event;
                            const dialCodeLength = name.dialCode;
                            const stringWithoutFirstThree = originalString.substring(dialCodeLength.length);
                            formikBag.setFieldValue('mobile_number_country_code', parseInt(name.dialCode) || 0);
                            formikBag.setFieldValue('mobile_number', stringWithoutFirstThree || '');
                            }
                          }
                          inputProps={{
                            name: 'mobile_number',
                            required: true,
                            autoFocus: true
                          }}
                        />
                      </div>
                      {/* <div>
                        <Field
                          name="mobile_number"
                          component={FormikMaterialTextField}
                          // placeholder="Phone Number"
                          label="Phone Number"
                          disabled={!!initialValues.mobile_number}
                        />
                      </div> */}

                      {/* <div>
                      <Field

                        component={FormikLocationSelect}


                        // placeholder="Phone Number"
                        label="Select Country"
                        // disabled={!!initialValues.mobile_number}
                    /> */}
                      <div className="location__field">
                        {/* {values.country_id === 1 && ( */}
                        <Field
                          name="location_code"
                          // component={Location}
                          // variant="standard"
                          component={searchLocation}
                          placeholder="Select Location"
                          label="Location"
                          cb={(locationValues) => {
                            let locationDetails = locationValues[0] || {};
                            //call this if statement at the end because sometimes setLocDetailsValue will unmount the component and hence will show old data
                            // if (locationValues) {
                            //   locationDetails = locationValues[0] || {};
                            //   // setLocDetailsValue(locationValues[0]?.addressTokens);
                            //   setLocDetailsEloc(locationValues[0]?.eLoc);
                            // }

                            let addressLine1 =
                              (_.get(locationDetails, 'addressTokens.houseNumber', '')
                                ? _.get(locationDetails, 'addressTokens.houseNumber', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.houseName', '')
                                ? _.get(locationDetails, 'addressTokens.houseName', '')
                                : '');

                            let addressLine2 =
                              (_.get(locationDetails, 'addressTokens.street', '')
                                ? _.get(locationDetails, 'addressTokens.street', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.subSubLocality', '')
                                ? _.get(locationDetails, 'addressTokens.subSubLocality', '')
                                : '');

                            let addressLine3 =
                              (_.get(locationDetails, 'addressTokens.subLocality', '')
                                ? _.get(locationDetails, 'addressTokens.subLocality', '') + ', '
                                : '') +
                              (_.get(locationDetails, 'addressTokens.locality', '')
                                ? _.get(locationDetails, 'addressTokens.locality', '')
                                : '');

                            // let locationCode = (_.get(locationDetails.addressTokens, 'locality')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'street') || '') + ' ' + (_.get(locationDetails.addressTokens, 'city')|| '') + ' ' + (_.get(locationDetails.addressTokens, 'state') || '') + ' ' + (_.get(locationDetails.addressTokens, 'pincode')|| '' ) ;
                            formikBag.setFieldValue('pin_code', _.get(locationDetails.addressTokens, 'pincode') || '');
                            formikBag.setFieldValue('address_line1', addressLine1);
                            formikBag.setFieldValue('address_line2', addressLine2);
                            formikBag.setFieldValue('address_line3', addressLine3);
                            formikBag.setFieldValue('city_code', _.get(locationDetails.addressTokens, 'city') || '');
                            formikBag.setFieldValue('locality', _.get(locationDetails.addressTokens, 'locality') || '');
                            formikBag.setFieldValue(
                              'district_code',
                              _.get(locationDetails.addressTokens, 'district') || '',
                            );
                            formikBag.setFieldValue(
                              'village_code',
                              _.get(locationDetails.addressTokens, 'village') ||
                              _.get(locationDetails.addressTokens, 'city') ||
                              _.get(locationDetails.addressTokens, 'subDistrict') ||
                              '',
                            );
                            formikBag.setFieldValue('sub_district_code', _.get(locationDetails.addressTokens, 'subDistrict') || '');
                            formikBag.setFieldValue('state_code', _.get(locationDetails.addressTokens, 'state') || '');
                            formikBag.setFieldValue('place_id', _.get(locationDetails, 'eLoc') || '');
                            // formikBag.setFieldValue('address_lattitude', _.get(locationDetails, 'latitude', '1'));
                            // formikBag.setFieldValue('address_longitude', _.get(locationDetails, 'longitude', '1'));
                            formikBag.setFieldValue(
                              'location_code',
                              (_.get(locationDetails, 'placeName', '')
                                ? _.get(locationDetails, 'placeName', '') + ', '
                                : '') + _.get(locationDetails, 'placeAddress', ''),
                            );
                            if (locationValues) {
                              // locationDetails = locationValues[0] || {};
                              // setLocDetailsValue(locationValues[0]?.addressTokens);
                              setLocDetailsEloc(locationValues[0]?.eLoc);
                            }
                          }}
                        />
                        {/* )} */}
                      </div>
                      <div className="form__form-group">
                        <div className="bfc-form__form-group-field">
                          <p>
                            <br />
                          </p>
                          <Field
                            name="is_newsletter_subscribed"
                            component={FormikCheckBox}
                            label="I would like to subscribe to information, tips and offers about BiofuelCircle products and services"
                          />
                        </div>
                      </div>
                      <ButtonToolbar className="form__button-toolbar bfc-account__btns">
                        <Button
                          className="bfc-buttonWidth"
                          color="primary"
                          type="button"
                          size="sm"
                          disabled={isSubmitting}
                          onClick={formikBag.handleSubmit}>
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          )}{' '}
                          Proceed
                        </Button>
                      </ButtonToolbar>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DetailsForm;

// export default withFormik({
//   displayName: 'DetailsForm',
//   mapPropsToValues: (props) => ({
//     user_name: userInfo.user_name,
//     first_name: '',
//     last_name: '',
//     email_id: userInfo.email_id,
//     mobile_number: userInfo.mobile_number,
//     location_id: '',
//     company_id: '',
//     is_newsletter_subscribed: false,
//   }),
//   validate,
//   // handleSubmit: handleProceed,
// })(DetailsForm);

// export default withFormik({
//   displayName: 'OtpForm',
//   mapPropsToValues: (props) => {
//     return {
//       user_name: user.user_name,
//       first_name: '',
//       last_name: '',
//       email_id: !utils.isNumber(user.user_name) ? user.user_name : '',
//       mobile_number: utils.isNumber(user.user_name) ? user.user_name : '',

//       company_id: '',
//       is_newsletter_subscribed: false,
//       address_lattitude: '',
//       address_longitude: '',
//       location_id: 0,
//       location_code: '',
//       village_id: 0,
//       village_code: '',
//       taluka_id: 0,
//       taluka_code: '',
//       city_id: 0,
//       city_code: '',
//       district_id: 0,
//       district_code: '',
//       state_id: 0,
//       state_code: '',
//       country_id: 0,
//       country_code: '',

//       // address_line1: '',
//       // address_line2: '',
//       // address_line3: '',

//       // pin_code: '',

//       ...(user || {}),
//     };
//   },
//   validate,
//   // handleSubmit: handlers.details.handleSignup,
// })(DetailsForm);
