import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Slide } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DialogPopup({ isOpen,
  setIsOpen,
  heading,
  message,
  onClick,
  children,
  type,
  reset,
  okButtonText = 'OK',
  setShowEULAModal,
  cancelButtonText = 'Cancel',
  cancelFunction,
  isEULAPOPup,
  handleLogout,
  showCancelModal,
  handleGoBack,
  loading,
}) {


  const handleClose = async () => {
    await setIsOpen && setIsOpen(false);
    reset && reset()
    if (isEULAPOPup) {
      cancelFunction(true)
      setShowEULAModal(false);
    }
  };

  const fontStyle = {
    fontFamily: 'var(--unnamed-font-segoeUI)',
  };

  const backgroundStyle = {
    backgroundColor:
      type === 'warning'
        ? 'var(--unnamed-color-f44336)'
        : type === 'success'
          ? 'var(--unnamed-color-4ce1b6'
          : 'var(--unnamed-color-1b62ab)',
    color: '#fff',
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        // TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle style={{ ...fontStyle, ...backgroundStyle, }}>
          <span style={{ color: '#fff', fontWeight: 'bold', ...fontStyle, }}>{heading}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="mb-2">
            <span style={{ ...fontStyle, fontSize: 16 }}>{message}</span>
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          {cancelButtonText ? <Button
            style={{ color: 'var(--unnamed-color-f44336)', ...fontStyle, fontWeight: 'bold' }}
            onClick={() => {
              if (showCancelModal) {
                handleLogout()
              }
              handleClose()
            }}>
            <span style={{ color: 'var(--unnamed-color-f44336)' }}>{cancelButtonText} </span>
          </Button> : ''}
          {okButtonText ? <Button
            style={{ color: 'var(--unnamed-color-1b62ab)', ...fontStyle, fontWeight: 'bold' }}
            onClick={() => {
              onClick();
            }}
            autoFocus
            >
            {loading ? <CircularProgress size="30px"/> : okButtonText}
          </Button> : ''}
        </DialogActions>
      </Dialog>
    </div>
  );
}
