import React, { useState, useEffect } from 'react';
import _, { set } from 'lodash';
import { Button, ButtonToolbar, Row, Card, CardBody } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik, Field, Form, FieldArray } from 'formik';
import FormikMaterialTextField from '../KYCBusiness/components/FormikMaterialTextField';
import DOBFormikMaterialTextField from 'shared/components/form/FormikMaterialTextField';
import FormikDatePickerField from './components/FormikDatePickerField';
import calendarIcon from 'containers/Html/images/calendar-black-icon.svg';
import FormikMaterialTextFieldAcct from './components/FormikMaterialTextFieldAcct';
import { useSelector, useDispatch } from 'react-redux';
import RemoveCircleIcon from 'mdi-react/RemoveCircleIcon';
import LogoLoader from 'common/LogoLoader';
import EditIcon from 'mdi-react/SquareEditOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReferenceDataSelect from '../components/ReferenceDataSelect';
import FormikLocationSelect from 'common/Form/FormikLocationSelectEloc';
import searchLocation from 'common/Form/searchLocation';
import MUITextField from '@material-ui/core/TextField';
import utils, {  getUrlWithEncryptedQueryParams } from 'lib/utils';
import EyeIcon from 'mdi-react/EyeIcon';
import { actions as kycIndividualActions } from 'redux/kycIndividual';
import { actions as kycUserTaxIdentityActions } from 'redux/kycUserTaxIdentity';
import { actions as kycUserBankIdentityActions } from 'redux/kycUserBankIdentity';
import { actions as eKycStatusActions } from 'redux/eKycStatus';
import { actions as transportSearchDriverActions } from 'redux/transportSearchDriver';
import PropTypes from 'prop-types';
import * as handlers from './handlers';
import * as driverHandlers from 'pages/network/driver/handlers';
import Collapse from '../components/Collapse';
import useQuery from 'lib/hooks/useQuery';
import FormikCheckBox from './components/FormikCheckBox';
import BankSelect from '../KYCBusiness/components/bankSelect';
import followIcon from 'assets/images/icons/follow.svg';
import KycCommentspopUp from './components/kycCommentspopUp';
import PopupForm from 'common/Form/PopupForm';
import BankPopup from './components/BankPopup';
import BankForm from './components/BankForm';
import BankVerificationForm from './components/BankVerificationForm';
import ToggleSwitch from './components/toggleSwitchDisplay';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { locationElocService } from 'services';

import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const getInitialValues = (kycDetails, taxDetails, user, driverDetails) => {

  let params = {
    person_kyc_id: _.get(kycDetails, 'person_kyc_id') || 0,
    identity_type_enum: _.get(kycDetails, 'identity_type_enum') || 0,
    identity_doc_number: _.get(kycDetails, 'identity_doc_number') || '',
    name_as_per_identity: _.get(kycDetails, 'name_as_per_identity') || '',

    location_id: _.get(kycDetails, 'location_id') || 0,
    location_code: _.get(kycDetails, 'location_code') || '',
    // village_id: _.get(kycDetails, 'village_id') || 0,
    village_code: _.get(kycDetails, 'village_code') || '',
    // taluka_id: _.get(kycDetails, 'taluka_id') || 0,
    taluka_code: _.get(kycDetails, 'taluka_code') || '',
    // city_id: _.get(kycDetails, 'city_id') || 0,
    city_code: _.get(kycDetails, 'city_code') || '',
    // district_id: _.get(kycDetails, 'district_id') || 0,
    district_code: _.get(kycDetails, 'district_code') || '',
    // state_id: _.get(kycDetails, 'state_id') || 0,
    state_code: _.get(kycDetails, 'state_code') || '',
    // country_id: _.get(kycDetails, 'country_id') || 1,
    country_code: _.get(kycDetails, 'country_code') || 'India',
    address_lattitude: _.get(kycDetails, 'lattitude') || '',
    address_longitude: _.get(kycDetails, 'longitude') || '',
    address_line_1: _.get(kycDetails, 'address_line_1') || '',
    address_line_2: _.get(kycDetails, 'address_line_2') || '',
    address_line_3: _.get(kycDetails, 'address_line_3') || '',
    pin_code: _.get(kycDetails, 'pin_code') || '',
    location_tuple: _.get(kycDetails, 'location_tuple') || '',
    locality: '',
    place_id: _.get(kycDetails, 'place_id') || '',
    //tax identity
    person_tax_detail_id: _.get(taxDetails, 'person_tax_detail_id') || 0,
    pan_id: _.get(taxDetails, 'pan_id') || '',
    name_as_per_pan: _.get(taxDetails, 'name_as_per_pan') || '',
    dont_have_pan_flag: _.get(taxDetails, 'dont_have_pan_flag') || false,
    form_60_declaration_flag: _.get(taxDetails, 'dont_have_pan_flag') || false,

    //driver
    first_name: _.get(user, 'profile.person.first_name') || '',
    last_name: _.get(user, 'profile.person.last_name') || '',
    middle_name: _.get(driverDetails, 'middle_name') || '',
    dob: _.get(driverDetails, 'dob') || _.get(kycDetails, 'dob') || '1999-01-01',
    license_number: _.get(driverDetails, 'license_number') || _.get(kycDetails, 'license_number') || '',
    mobile_number: _.get(user, 'profile.person.mobile_number') || '',
    same_as_registered_address:
      (user?.profile?.person?.user_type_enum === 2 ||
        (user?.profile?.person?.is_proprietor === false && user?.profile?.person?.type_of_individual_enum === 2)) &&
      true,
  };
  return params;
};

const KYCUserIdentity = (props) => {
  const id = parseInt(props.id);
  const dispatch = useDispatch();
  const { back } = useQuery();
  const history = useHistory();
  const user = useSelector((state) => state.user);
    const sid = _.get(user, 'social_profile_id', 0);
    const socialProfiles = useSelector((state) => state.socialProfiles);
    const profileData = _.get(socialProfiles, `data[${sid}]`) || {};
    const partnerId = _.get(profileData, 'partner_id') || null;
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let driverUserDetails = user;
  let userRole = _.get(user, 'profile.person.type_of_individual_enum') || 0;
  let phoneNumber = _.get(user, 'profile.person.mobile_number') || '';
  let isControlTowerUser = _.get(user, 'profile.person.is_control_tower_user') || false;
  let isKycDoneWithoutBankDetails = _.get(user, 'profile.person.is_kyc_done_without_bank_details') || false;

  const kycUser = useSelector((state) => state.kycUser);
  const kycIndividual = useSelector((state) => state.kycIndividual);
  const kycTaxIndividual = useSelector((state) => state.kycUserTaxIdentity);
  const eKycStatus = useSelector((state) => state.eKycStatus);

  const busyKyc = _.get(kycIndividual, 'busy') || false;
  const busyTax = _.get(kycTaxIndividual, 'busy') || false;
  const busyEKyc = _.get(eKycStatus, 'busy') || false;

  let profileAddress = _.get(user, 'profile.person_adress');
  const isKycDone = _.get(user, 'profile.person.is_kyc_done') || false;
  let kycStatusEnumCode;
  let addressText = _.get(profileAddress, 'address_text') || '';
  let person_address_id = _.get(profileAddress, 'person_address_id');

  const kycDetails = _.get(kycIndividual, 'entities') || {};
  const [timestamp, setTimestamp] = useState(Date.now());

  const taxDetails = _.get(kycTaxIndividual, 'entities') || {};

  const eKycStatusList = _.get(eKycStatus, 'entities') || {};

  const person_kyc_id = _.get(kycDetails, 'person_kyc_id') || 0;
  const person_tax_detail_id = _.get(taxDetails, 'person_tax_detail_id') || 0;
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);

  //tax
  const checkBox = _.get(taxDetails, 'dont_have_pan_flag') || false;
  const [checkBoxValue, setCheckBoxValue] = useState(checkBox);

  let idCompare = parseInt(id);

  let isAdmin;
  if (idCompare !== window.user.userid && CT_parent) {
    isAdmin = true;
    driverUserDetails = kycUser;
    userRole = _.get(kycUser, 'profile.person.type_of_individual_enum') || 0;
    phoneNumber = _.get(kycUser, 'profile.person.mobile_number') || '';
  }
  if (idCompare === window.user.userid) {
    isAdmin = false;
  }

  {
    isAdmin
      ? (kycStatusEnumCode = _.get(kycUser, 'profile.person.kyc_status_enum_code'))
      : (kycStatusEnumCode = _.get(user, 'profile.person.kyc_status_enum_code'));
  }
  if (isAdmin) {
    profileAddress = _.get(kycUser, 'profile.person_adress');
    addressText = _.get(profileAddress, 'address_text') || '';
    person_address_id = _.get(profileAddress, 'person_address_id');
  }

  let driverDetails = {};
  let busyDriver = false;
  if (userRole == 2) {
    const transportSearchDriver = useSelector((state) => state.transportSearchDriver);
    busyDriver = _.get(transportSearchDriver, 'busy') || false;
    const driverIds = _.get(transportSearchDriver, 'ids') || [];
    const driverId = driverIds[0];
    driverDetails = _.get(transportSearchDriver, `entities.${driverId}`);
  }

  let kycStatusKey = false;
  if (kycStatusEnumCode == 'Approved' || kycStatusEnumCode == 'Pending') {
    kycStatusKey = true;
  }
  if (kycStatusEnumCode == 'Rejected' || kycStatusEnumCode == 'Not Submitted') {
    kycStatusKey = false;
  }
  //EKyc
  const [documentText, setDocumentText] = useState('Verify');
  const [panText, setPanText] = useState('Verify');
  const [licenseText, setLicenseText] = useState('Verify');

  const [locDetailsValue, setLocDetailsValue] = useState({});
  const [locDetailsEloc, setLocDetailsEloc] = useState();

  const [latLongData, setLatLongData] = useState({});
  const tokenState = useSelector((state) => state.locationEloc);
  const token = _.get(tokenState, 'entities.undefined');

  const getLocationDetails = async (params) => {
    const resData = await locationElocService.getLocationDetails(params);
    setLatLongData(resData);
    return resData;
  };

  useEffect(() => {
    let params = {
      place_id: locDetailsEloc,
      bearer_token: token,
    };
    if (locDetailsEloc) {
      const res = getLocationDetails(params);
    }
  }, [locDetailsEloc, token]);

  //tax
  useEffect(() => {
    setCheckBoxValue(checkBox);
  }, [checkBoxValue, checkBox]);

  const acl = utils.getACL(user);
  let nextLink = `/account/${id}/upload`;
  let prevLink = `/account/${id}/transport`;
  if (acl.isCompany) {
    nextLink = `/account/${id}/upload`;
    prevLink = `/account/${id}/transport`;
  }
  if (isAdmin) {
    nextLink = `/account/${id}/upload?back=${back}`;
    prevLink = `/account/${id}/transport?back=${back}`;
    if (acl.isCompany) {
      nextLink = `/account/${id}/upload?back=${back}`;
      prevLink = `/account/${id}/transport?back=${back}`;
    }
  }

  const [showPopUp, setPopUp] = useState(false);

  const createMatch = useRouteMatch(getUrlWithEncryptedQueryParams(`/account/${id}/kyc/create`));

  const paramsFetch = {
    person_id: id,
    ...(partnerId ? { company_affiliate_enum: "1" } : {}),
  };

  const handleEKyc = async (values, formikBag) => {
    // console.log('values-->>', values)
    if (values.eKyc == 'DriverLicense') {
      const success = await driverHandlers.handleEKycDocument(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (success == 'Identity verified' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.') {
        setLicenseText('Verified');
      }
    }
  };

  useEffect(() => {
    dispatch(kycIndividualActions.fetchKYCIdentityDetails(paramsFetch));
    dispatch(kycUserTaxIdentityActions.fetchTaxDetails(paramsFetch));
    if (isControlTowerUser) {
      const paramsCT = {
        ...paramsFetch,
        user_type_enum: _.get(kycUser, 'profile.person.user_type_enum') || 0,
      };
      dispatch(eKycStatusActions.fetchEKycStatusCt(paramsCT));
    } else {
      dispatch(eKycStatusActions.fetchList(paramsFetch));
    }

    if (userRole == 2) {
      let driverParams = {
        mobile_number: phoneNumber,
      };
      dispatch(transportSearchDriverActions.fetchDriverByMobile(driverParams));
    }
  }, [kycIndividual.id, timestamp]);

  if (busyKyc || busyTax || busyEKyc || busyDriver) {
    return (
      <div className="bfc-body bfc-form">
        <div className="panel__refresh">
          <LogoLoader />
        </div>
      </div>
    );
  }
  let params = {
    entity_id: _.get(user, 'userid'),
  };

  const handleCheck = async (values, formikBag) => {
    if (values['dont_have_pan_flag'] == true) {
      values['pan_id'] = '';
      values['name_as_per_pan'] = '';
    }
    if (values['dob'] !== null && values['dob'] !== '') {
      values['dob'] = dayjs(values['dob']).format('YYYY-MM-DD')
    }
    if (values.eKyc == 'Document') {
      const success = await handlers.handleEKycDocument(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (success == 'Identity verified' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.') {
        if (values.eKyc == 'Document') {
          setDocumentText('Verified');
        }
      }
    } else if (values.eKyc == 'Pan') {
      const success = await handlers.handleEKycDocument(values, formikBag);
      const kycMessage = _.get(success, 'kyc_message') || '';
      if (success == 'Identity verified' ||
        kycMessage == 'Identity already verified.' ||
        kycMessage == 'Identity verified.') {
        if (values.eKyc == 'Pan') {
          setPanText('Verified');
        }
      }
    } else {
      let success;
      if (userRole === 2) {
        // console.log('driverParams before');
        let driverParams = {
          first_name: values['first_name'],
          middle_name: values['middle_name'],
          last_name: values['last_name'],
          mobile_number: values['mobile_number'],
          license_number: values['license_number'],
          person_id: _.get(user, 'person_id') || 0,
          dob: values['dob'] ? dayjs(values['dob']).format('YYYY-MM-DD') : null,
        };
        success = await driverHandlers.handleKycUpdate(driverParams, formikBag);
        // console.log('driverParams after');
      } else {
        success = true;
      }

      if (success) {
        delete values['first_name'];
        delete values['last_name'];
        delete values['middle_name'];
        delete values['mobile_number'];

        let resKycData = {};
        let resTaxData = {};
        values['userRole'] = userRole;
        //kyc
        person_kyc_id
          ? (resKycData = await handlers.handleUpdate(values, formikBag))
          : (resKycData = await handlers.handleCreate(values, formikBag));
        //tax
        person_tax_detail_id
          ? (resTaxData = await handlers.handleTaxUpdate(values, formikBag))
          : (resTaxData = await handlers.handleTaxCreate(values, formikBag));
        if (!_.isEmpty(resKycData) || !_.isEmpty(resTaxData)) {
          setTimestamp(Date.now());
        }
      }
    }
  };
  return (
    <div className="">
      <div className="bfc-body bfc-form profile-height-body">
        <Formik
          initialValues={getInitialValues(kycDetails, taxDetails, driverUserDetails, driverDetails)}
          onSubmit={handleCheck}>
          {(formikBag) => {
            const { values, initialValues } = formikBag;
            values.address_lattitude = (_.get(latLongData, 'latitude') || '').toString();
            values.address_longitude = (_.get(latLongData, 'longitude') || '').toString();
            // values.place_id = (_.get(latLongData, 'eloc') || '').toString();
            // console.log('values.dob', documentText)
            const bankRowCount = _.get(values, 'bank_account_details_request_model.length') || 0;
            const removeQualitySpec = async (index) => {
              let params = {
                entity_id: id,
              };
              const success = await handlers.handleDeleteBank(params);
              if (success) {
                let bank_account_details_request_model = [...values.bank_account_details_request_model];

                bank_account_details_request_model.splice(index, 1);

                formikBag.setFieldValue('bank_account_details_request_model', bank_account_details_request_model);
              }
            };
            if (values['identity_type_enum'] == 4) {
              values['dont_have_pan_flag'] = false;
              values['pan_id'] = values['identity_doc_number'];
              values['name_as_per_pan'] = values['name_as_per_identity'];
            }
            _.map(eKycStatusList, (details, id) => {
              try {
                if (details.enum_name == 'VoterIdVerification' && values.identity_type_enum == 3) {
                  if (details.document_status) {
                    setDocumentText('Verified');
                  }
                } else if (details.enum_name == 'DLVerification' && values.identity_type_enum == 2) {
                  if (details.document_status) {
                    setDocumentText('Verified');
                    setLicenseText('Verified');
                  }
                }
                if (details.enum_name == 'PanVerification') {
                  if (details.document_status) {
                    setPanText('Verified');
                  }
                }

                if (details.enum_name == 'DLVerification') {
                  if (details.document_status) {
                    setLicenseText('Verified');
                  }
                }
              } catch (err) {
                console.log('eKyc status=========>error', err);
              }
            });
            return (
              <React.Fragment>
                <Form className="form-container kyc-form-container">
                  <Card>
                    <div className="btn-wrapper justify-content-space-between mb-10">
                      <div className="d-flex align-items-center">
                        <span>
                          {kycStatusEnumCode == 'Approved' ? <img src={followIcon} alt="" /> : <InformationOutlineIcon />}
                        </span>
                        <span className="ml-5">KYC {kycStatusEnumCode}</span>
                      </div>

                      {isAdmin == false && (
                        <div>
                          <Button
                            color="secondary"
                            type="button"
                            size="sm"
                            onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/kyc/create`))}
                            className="next bfc-button-next">
                            KYC Comments
                          </Button>
                        </div>
                      )}
                      {isAdmin == true && (
                        <div>
                          <Button
                            color="secondary"
                            type="button"
                            size="sm"
                            onClick={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/kyc/create`))}
                            disabled={kycStatusEnumCode == 'Not Submitted' ? true : false}
                            className="next bfc-button-next">
                            Update Status
                          </Button>
                        </div>
                      )}
                    </div>

                    {createMatch ? (
                      <div>
                        <PopupForm
                          title={isAdmin ? 'Update KYC Status' : 'KYC Comments'}
                          onClose={() => history.push(getUrlWithEncryptedQueryParams(`/account/${id}/kyc`))}>
                          <KycCommentspopUp params={params} isAdmin={isAdmin} id={id} />
                        </PopupForm>
                      </div>
                    ) : null}

                    <CardBody className="main-cardbody">
                      <Collapse title="Individual Identity" className="with-shadow">
                        <Card>
                          <CardBody>
                            <div className="row form__form-group">
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Select Identity Document</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <Field
                                    name="identity_type_enum"
                                    component={ReferenceDataSelect}
                                    entity="IdentityTypeEnum"
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Name on Document</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <Field
                                    name="name_as_per_identity"
                                    component={FormikMaterialTextField}
                                    disabled={isAdmin || kycStatusKey}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row form__form-group">
                              {
                                values.identity_type_enum == 2 && (
                                  <div className="col-md-4">
                                    <span className="form-group-label">Date Of Birth</span>
                                    <span class="required">*</span>
                                    <div className="form-group-field driver-dob custom-input">
                                      <Field
                                        name="dob"
                                        component={FormikDatePickerField}
                                        defaultValue={values.dob}
                                        disabled={isAdmin || kycStatusKey}
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                      />
                                      <img src={calendarIcon} className="datepicker-calendar-icon" />
                                    </div>
                                    <div className="form-group-field">
                                      <div className="notes-block">
                                        <p className="redbg mt-10">
                                          We only need this to verify your identity using the Driving Licence number
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Document Number</span>
                                {/* <span className="form-group-label">Driving Licence</span> */}
                                <span class="required">*</span>
                                <div className="form-group-field with-verified__btn">
                                  <div className="row no-gutters">
                                    <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                      <Field
                                        name="identity_doc_number"
                                        component={FormikMaterialTextField}
                                        disabled={isAdmin || kycStatusKey}
                                      />
                                    </div>
                                    <div className="col-3 col-sm-2 col-md-2">
                                      {values.identity_doc_number && values.identity_type_enum !== 4 && (
                                        <div className="">
                                          <div className="form-group-field">
                                            <button
                                              type="button"
                                              className={`form__form-group-button ${documentText == 'Verified' ? '' : 'active'
                                                }`}
                                              disabled={
                                                documentText == 'Verified' || isAdmin || kycStatusKey ? true : false
                                              }
                                              onClick={() => {
                                                formikBag.values.eKyc = 'Document';
                                                formikBag.handleSubmit(formikBag.values, formikBag);
                                              }}>
                                              {documentText}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row form__form-group">
                              <div className="col-md-6 col-lg-5 col-xl-4">
                                <span className="form-group-label">Address</span>
                                <span class="required">*</span>
                                <div className="form-group-field">
                                  <div className="row no-gutters">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                      <MUITextField
                                        fullWidth
                                        size="small"
                                        disabled
                                        variant={'outlined'}
                                        value={`${values.location_tuple}` || `${values.location_code}` || addressText}
                                      />
                                    </div>
                                    {!isAdmin && !kycStatusKey && (
                                      <div className="col-sm-4 col-md-4">
                                        <div className="">
                                          <div className="form-group-field">
                                            <Link
                                              to={getUrlWithEncryptedQueryParams(`/account/${id}/myaccount`)}
                                              style={{
                                                minWidth: 'fit-content',
                                                width: '100%',
                                                height: '38px',
                                              }}
                                              target="_blank">
                                              Change Location
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </CardBody>
                        </Card>
                      </Collapse>

                      <Collapse title="Tax Identity" className="with-shadow">
                        <Card>
                          <CardBody>
                            <div className="pan-wrapper">
                              <div className="dont-pan" style={{ width: '150px' }}>
                                <Field
                                  name="dont_have_pan_flag"
                                  component={FormikCheckBox}
                                  label={<span>Don't have a PAN</span>}
                                  disabled={isAdmin || kycStatusKey || values.identity_type_enum == 4}
                                  value={values.identity_type_enum == 4 ? false : taxDetails?.dont_have_pan_flag}
                                />
                              </div>
                            </div>
                            {values.dont_have_pan_flag == false && (
                              <div className="row form__form-group">
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">PAN</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field with-verified__btn">
                                    <div className="row no-gutters">
                                      <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                        <Field
                                          name="pan_id"
                                          component={FormikMaterialTextField}
                                          disabled={isAdmin || kycStatusKey}
                                        />
                                      </div>
                                      <div className="col-3 col-sm-2 col-md-2">
                                        {values.name_as_per_pan && (
                                          <div className="">
                                            <div className="form-group-field">
                                              <button
                                                type="button"
                                                className={`form__form-group-button ${panText == 'Verified' ? '' : 'active'
                                                  }`}
                                                disabled={
                                                  panText == 'Verified' || isAdmin || kycStatusKey ? true : false
                                                }
                                                onClick={() => {
                                                  formikBag.values.eKyc = 'Pan';
                                                  formikBag.handleSubmit(formikBag.values, formikBag);
                                                }}>
                                                {panText}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {values.dont_have_pan_flag == false && (
                              <div className="row form__form-group">
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <div className="form-group-field">
                                    <span className="form-group-label">Name as per PAN</span>
                                    <span class="required">*</span>
                                    <Field
                                      name="name_as_per_pan"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Collapse>

                      <Collapse title="Bank Details" className="with-shadow">
                        <Card>
                          <CardBody>
                            <BankForm id={id} isAdmin={isAdmin} kycStatusKey={kycStatusKey} user_type_enum={2} />
                            {(kycDetails?.bank_passbook_name && kycDetails?.bank_passbook_url) ? <div className="row form__form-group">
                              <Row className="col-md-12">
                                <span className="form-group-label col-md-3">
                                  Bank Passbook
                                </span>
                                <div className="form-group-field col-md-9">
                                  <a href='kycDetails?.bank_passbook_url' target='_blank'>{kycDetails?.bank_passbook_name}</a>
                                </div>
                              </Row>
                            </div> : ''}
                          </CardBody>
                        </Card>
                      </Collapse>
                      {userRole == 2 && (
                        <Collapse title="Driver Identity" className="with-shadow">
                          <Card>
                            <CardBody>
                              <div className="row form__form-group">
                                <div className="col-md-4">
                                  <span className="form-group-label">First Name</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field">
                                    <Field
                                      name="first_name"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <span className="form-group-label">Middle Name</span>
                                  <div className="form-group-field">
                                    <Field
                                      name="middle_name"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row form__form-group">
                                <div className="col-md-4">
                                  <span className="form-group-label">Last Name</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field">
                                    <Field
                                      name="last_name"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group-field">
                                    <span className="form-group-label">Mobile Number</span>
                                    <span class="required">*</span>
                                    <Field
                                      name="mobile_number"
                                      component={FormikMaterialTextField}
                                      disabled={isAdmin || kycStatusKey}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row form__form-group">
                                <div className="col-md-4">
                                  <span className="form-group-label">Date Of Birth</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field driver-dob cutom-input">
                                    <Field
                                      name="dob"
                                      component={FormikDatePickerField}
                                      defaultValue={values.dob}
                                      disabled={isAdmin || kycStatusKey}
                                      showYearDropdown={true}
                                      showMonthDropdown={true}
                                    />
                                    <img src={calendarIcon} className="datepicker-calendar-icon" />
                                  </div>
                                  <div className="form-group-field">
                                    <div className="notes-block">
                                      <p className="redbg mt-10">
                                        We only need this to verify your identity using the Driving Licence number
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-5 col-xl-4">
                                  <span className="form-group-label">Driving Licence</span>
                                  <span class="required">*</span>
                                  <div className="form-group-field with-verified__btn">
                                    <div className="row no-gutters">
                                      <div className="col-9 col-sm-10 col-md-10 col-lg-10">
                                        <Field
                                          name="license_number"
                                          component={FormikMaterialTextField}
                                          disabled={isAdmin || kycStatusKey}
                                        />
                                      </div>
                                      <div className="col-3 col-sm-2 col-md-2">
                                        {values.license_number && (
                                          <div className="">
                                            <div className="form-group-field">
                                              <button
                                                type="button"
                                                className={`form__form-group-button ${licenseText === 'Verified' ? '' : 'active'
                                                  }`}
                                                disabled={licenseText === 'Verified' || isAdmin || kycStatusKey}
                                                onClick={() => {
                                                  formikBag.values.eKyc = 'DriverLicense';
                                                  handleEKyc(formikBag.values, formikBag);
                                                }}>
                                                {licenseText}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                      )}
                    </CardBody>
                  </Card>

                  <div className="btn-wrapper justify-content-space-between">
                    <div className="">
                      <NavLink to={getUrlWithEncryptedQueryParams(prevLink)} color="link">
                        Prev
                      </NavLink>
                    </div>
                    <div>
                      {!isAdmin && !kycStatusKey && (
                        <Button
                          color="primary"
                          type="button"
                          size="sm"
                          onClick={() => {
                            formikBag.values.eKyc = '';
                            formikBag.handleSubmit(formikBag.values, formikBag);
                          }}
                          disabled={formikBag.isSubmitting}
                          className="next bfc-button-next">
                          {formikBag.isSubmitting && (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          )}{' '}
                          Save
                        </Button>
                      )}
                    </div>

                    <div className="">
                      <NavLink to={getUrlWithEncryptedQueryParams(nextLink)} color="link">
                        Next
                      </NavLink>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            );
          }}
        </Formik>
      </div>
    </div >
  );
};

export default KYCUserIdentity;
