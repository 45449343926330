import _ from 'lodash';
import { decryptionInHex, getDecryptedUrl, getUrlWithEncryptedQueryParams } from './utils';

const FilterUtils = {
  getFilters(filters) {
    let filterParams = {};
    _.forOwn(filters, function (val, key) {
      if (val !== undefined) {
        filterParams = {
          ...filterParams,
          [key]: val,
        };
      }
    });
    return filterParams;
  },
  createUrl(params) {
    let searchParams = new URLSearchParams('');
    let pathname = window.location.pathname;
    if (!searchParams.has('page')) {
      searchParams.append('page', 1);
    }
    _.forOwn(params, function (val, key) {
      if (key !== 'page') {
        searchParams.append(key, val);
      } else {
        let url = new URL(getDecryptedUrl(window.location.href));
        searchParams = new URLSearchParams(url.search);
        searchParams.set(key, val);
      }
    });
    return getUrlWithEncryptedQueryParams(pathname + '?' + searchParams.toString());
  },
  createUrlForDispense(params, fiscalYear) {
    let searchParams = new URLSearchParams('');
    let pathname = window.location.pathname;
    if (!searchParams.has('page')) {
      searchParams.append('page', 1);
    }
    _.forOwn(params, function (val, key) {
      if (key !== 'page') {
        searchParams.append(key, val);
      } else {
        let url = new URL(getDecryptedUrl(window.location.href));
        searchParams = new URLSearchParams(url.search);
        searchParams.set(key, val);
      }
    });
    return getUrlWithEncryptedQueryParams(pathname + '?' + searchParams.toString() + `&fiscal_year=${fiscalYear}`);
  },
  createUrlForAggragationContracts(params, fiscalYear) {
    let searchParams = new URLSearchParams('');
    let pathname = window.location.pathname;
    const loc = getDecryptedUrl(window.location.href);
    if (!searchParams.has('page')) {
      searchParams.append('page', 1);
    }
    _.forOwn(params, function (val, key) {
      if (key !== 'page') {
        searchParams.append(key, val);
      } else {
        let url = new URL(getDecryptedUrl(window.location.href));
        searchParams = new URLSearchParams(url.search);
        searchParams.set(key, val);
      }
    });
    return getUrlWithEncryptedQueryParams(
      pathname + '?' + searchParams.toString() + (loc.includes('isNetwork=true') ? '&isNetwork=true' : ''),
    );
  },
  removeUrl(dataIndex) {
    let pathname = window.location.pathname;
    let url = new URL(getDecryptedUrl(window.location.href));
    let searchParams = new URLSearchParams(url.search);
    const isAlreadyEncrypted = decryptionInHex(searchParams.toString());
    if (isAlreadyEncrypted) {
      searchParams = new URLSearchParams(`?${isAlreadyEncrypted}`);
    }
    if (searchParams.has(dataIndex)) {
      searchParams.delete(dataIndex);
    }
    return getUrlWithEncryptedQueryParams(pathname + '?' + searchParams.toString());
  },
};

export default FilterUtils;
