import _ from 'lodash';
import utils from 'lib/utils';
import config from 'config';
import { reqTransform, resTransform } from './transforms';
const { baseUrl } = config;

export const fetchDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchKYCIdentityDetails_ELOC?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchDetails(resData);
  } catch (err) {
    console.log('FetchKYCIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchKYCIdentityDetails_ELOC');
    throw errors;
  }
};

export const create = async (params) => {
  try {
    let bodyParams = reqTransform.create(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateKYC_IndividualUser_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateKYC_IndividualUser_ELOC');
    throw errors;
  }
};

export const createStatus = async (params) => {
  try {
    let bodyParams = reqTransform.createStatus(params);
    const url = `${baseUrl}/BioFuelRegistration/api/ControlTower/UpdateKycStatusAgainstEntityId`;

    const res = await utils.fetch(url, {
      method: 'PUT',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'UpdateKycStatusAgainstEntityId');
    throw errors;
  }
};

export const update = async (id, params = {}) => {
  try {
    let bodyParams = reqTransform.update(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateKYC_IndividualUser_ELOC`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateKYC_IndividualUser_ELOC');
    throw errors;
  }
};

//Tax
export const fetchTaxDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchTaxDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchTaxIdentityDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchTaxDetails(resData);
  } catch (err) {
    console.log('FetchTaxIdentityDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchTaxIdentityDetails');
    throw errors;
  }
};

export const createTax = async (params) => {
  try {
    //Tax
    let bodyParams = reqTransform.createTax(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateTaxIdentity_IndividualUser`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateTaxIdentity_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTaxIdentity_IndividualUser');
    throw errors;
  }
};

export const updateTax = async (id, params = {}) => {
  try {
    //Tax
    let bodyParams = reqTransform.updateTax(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateTaxIdentity_IndividualUser`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateTaxIdentity_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateTaxIdentity_IndividualUser');
    throw errors;
  }
};

//Bank
export const fetchBankDetails = async (params = {}) => {
  try {
    const qs = reqTransform.fetchBankDetails(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/FetchBankAccountDetails?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    if (params['isSerialized'] === false) {
      return resData;
    }
    return resTransform.fetchBankList(resData);
  } catch (err) {
    console.log('FetchBankAccountDetails->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'FetchBankAccountDetails');
    throw errors;
  }
};

export const createBank = async (params) => {
  try {
    //Bank
    let bodyParams = reqTransform.createBank(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateBankAccountDetails`;
    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.create(resData);
  } catch (err) {
    console.log('SaveAndUpdateBankAccountDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBankAccountDetails');
    throw errors;
  }
};

export const updateBank = async (id, params = {}) => {
  try {
    //Bank
    let bodyParams = reqTransform.updateBank(id, params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/SaveAndUpdateBankAccountDetails`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);

    return resTransform.update(resData);
  } catch (err) {
    console.log('SaveAndUpdateBankAccountDetails-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'SaveAndUpdateBankAccountDetails');
    throw errors;
  }
};

export const eKycDocumentVerification = async (params = {}) => {
  try {
    let bodyParams = reqTransform.eKycDocumentVerification(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/KYCVerification`;

    const res = await utils.fetch(url, {
      method: 'POST',
      body: JSON.stringify(bodyParams),
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.eKycVerification(resData);
  } catch (err) {
    console.log('KYCVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'KYCVerification');
    throw errors;
  }
};

export const fetchEKycStatus = async (params = {}) => {
  try {
    const qs = reqTransform.fetchEKycStatusList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/GetKYCVerificationStatus?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchEKycStatusList(resData);
  } catch (err) {
    console.log('GetKYCVerificationStatus->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetKYCVerificationStatus');
    throw errors;
  }
};

export const fetchEKycStatusCt = async (params = {}) => {
  try {
    const qs = reqTransform.fetchEKycStatusList(params);
    const url = `${baseUrl}/BioFuelCommonUtilities/api/KYC/GetKYCVerificationStatus_CT?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.fetchEKycStatusList(resData);
  } catch (err) {
    console.log('GetKYCVerificationStatus->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'GetKYCVerificationStatus_CT');
    throw errors;
  }
};

export const verifyIFSC_old = async (params) => {
  try {
    const url = `https://api.sandbox.co.in/bank/${params.ifsc}`;
    const res = await utils.fetch(url, {
      method: 'GET',
    }).ready;
    let resJSON = res;
    if (typeof res.json === 'function') {
      resJSON = await utils.getResJSON(res);
    }
    return resTransform.verifyIFSC(resJSON);
  } catch (err) {
    console.log('verifyIFSC-code->err---->', err.statusText);
    throw err.statusText;
  }
};

export const verifyIFSC = async (params) => {
  try {
    const url = `https://ifsc.razorpay.com/${params.ifsc}`;
    const res = await fetch(url);
    let resJSON = res;
    if (typeof res.json === 'function') {
      resJSON = await utils.getResJSON(res);
    }
    return resTransform.verifyIFSC(resJSON);
  } catch (err) {
    console.log('verifyIFSC-code->err---->', err.statusText);
    throw err.statusText;
  }
};

export const verifyIFSCzoop = async (params) => {
  try {
    let qsParams = reqTransform.verifyIfscZoopApi(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/VerifyBankIFSC?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.verifyIfscZoopApi(resData);
  } catch (err) {
    console.log('RequestBankVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RequestBankVerification');
    throw errors;
  }
};

export const verifyBank = async (params = {}) => {
  try {
    let qsParams = reqTransform.verifyBank(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/RequestBankVerification?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.verifyBank(resData);
  } catch (err) {
    console.log('RequestBankVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'RequestBankVerification');
    throw errors;
  }
};

export const completeBank = async (params = {}) => {
  try {
    let qsParams = reqTransform.completeBank(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/CompleteBankVerification?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'POST',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.completeBank(resData);
  } catch (err) {
    console.log('CompleteBankVerification-verify->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'CompleteBankVerification');
    throw errors;
  }
};

export const deleteBank = async (params) => {
  try {
    let qsParams = reqTransform.deleteBank(params);
    const url = `${baseUrl}/BioFuelRegistration/api/KYC/DeleteBankAccountDetails?${qsParams}`;
    const res = await utils.fetch(url, {
      method: 'PUT',
    }).ready;
    const resData = await utils.processApiRes(res);
    return resTransform.deleteBank(resData);
  } catch (err) {
    console.log('SaveAndUpdateKYC_IndividualUser-create->err---->', err);
    const errors = await utils.processApiErrors(err, '', 'DeleteBankAccountDetails');
    throw errors;
  }
};

export const verifyVPAId = async (params) => {
  try {
    let qs = utils.getQs(params);
    const url = `${baseUrl}/BiofuelThirdPartyIntegration/api/WhatsappBot/VerifyVPAId?${qs}`;
    const res = await utils.fetch(url).ready;
    const resData = await utils.processApiRes(res);
    return resData;
  } catch (err) {
    const errors = await utils.processApiErrors(err, '', 'VerifyVPAId');
    throw errors;
  }
};
