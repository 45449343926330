import _ from 'lodash';
import * as api from './api';
import * as yup from 'yup';
import * as helpers from 'services/helpers';
import ConfirmDeleteModal from 'pages/commodityDealDetails/schedule/components/ConfirmModal';

export const UserIdentitySchema = yup.object().shape({
  identity_type_enum: yup.number().positive().required(),
  identity_doc_number: yup.string().required(),
  name_as_per_identity: yup.string().required(),
  // address_line_1: yup.string().required('Address Line 1 is a required field'),
  address_line_1: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Address Line 1 is a required field'),
  }),

  location_code: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Location is required'),
  }),

  pin_code: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Pin Code is a required field'),
  }),

  //Tax
  name_as_per_pan: yup.string().when('dont_have_pan_flag', {
    is: false,
    then: yup.string().required(),
  }),
  pan_id: yup.string().when('dont_have_pan_flag', {
    is: false,
    then: yup.string().required(),
  }),
  license_number: yup.string().when('userRole', {
    is: 2,
    then: yup.string().required('License Number is a required field'),
    otherwise: yup.string().optional(),
  }),
  // form_60_declaration_flag: yup.string().when('dont_have_pan_flag', {
  //   is: true,
  //   then: yup.string().required(),
  // }),
});

export const UserIdentitySchemaDOB = yup.object().shape({
  identity_type_enum: yup.number().positive().required(),
  identity_doc_number: yup.string().required(),
  name_as_per_identity: yup.string().required(),
  dob: yup.string().required('Date Of Birth is required'),
  // address_line_1: yup.string().required('Address Line 1 is a required field'),
  address_line_1: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Address Line 1 is a required field'),
  }),

  location_code: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Location is required'),
  }),

  pin_code: yup.string().when('same_as_registered_address', {
    is: false,
    then: yup.string().required('Pin Code is a required field'),
  }),

  //Tax
  name_as_per_pan: yup.string().when('dont_have_pan_flag', {
    is: false,
    then: yup.string().required(),
  }),
  pan_id: yup.string().when('dont_have_pan_flag', {
    is: false,
    then: yup.string().required(),
  }),
  license_number: yup.string().when('userRole', {
    is: 2,
    then: yup.string().required('License Number is a required field'),
    otherwise: yup.string().optional(),
  }),
});

export const eKycDocumentValiadtion = yup.object().shape({
  dob: yup.string().required('Date Of Birth is required'),
  identity_type_enum: yup
    .number()
    .positive('Please Select Identity Document')
    .required('Please Select Identity Document'),
  identity_doc_number: yup.string().required('Document Number is required'),
  name_as_per_identity: yup.string().required('Name on Document is required'),
});

export const eKycPanValiadtion = yup.object().shape({
  pan_id: yup.string().required('PAN Number is required'),
  name_as_per_pan: yup.string().required('Name on PAN is required'),
});

export const eKycStatusValiadtion = yup.object().shape({
  kyc_status_enum: yup.number().positive('Kyc status is required').required('Kyc status is required'),
});

export const fetchDetails = async (params) => {
  try {
    let res = await api.fetchDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const create = async (params) => {
  try {
    if (params.identity_type_enum == 2) {
      await helpers.validateSchema(UserIdentitySchemaDOB, params);
    } else {
      await helpers.validateSchema(UserIdentitySchema, params);
    }
    const res = await api.create(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createStatus = async (params) => {
  try {
    await helpers.validateSchema(eKycStatusValiadtion, params);
    const res = await api.createStatus(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const update = async (id, params = {}) => {
  try {
    if (params.identity_type_enum == 2) {
      await helpers.validateSchema(UserIdentitySchemaDOB, params);
    } else {
      await helpers.validateSchema(UserIdentitySchema, params);
    }
    const res = await api.update(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

//Tax
export const fetchTaxDetails = async (params) => {
  try {
    let res = await api.fetchTaxDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTax = async (params) => {
  try {
    if (params.identity_type_enum == 2) {
      await helpers.validateSchema(UserIdentitySchemaDOB, params);
    } else {
      await helpers.validateSchema(UserIdentitySchema, params);
    }
    const res = await api.createTax(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTax = async (id, params = {}) => {
  try {
    if (params.identity_type_enum == 2) {
      await helpers.validateSchema(UserIdentitySchemaDOB, params);
    } else {
      await helpers.validateSchema(UserIdentitySchema, params);
    }
    const res = await api.updateTax(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};

//Bank
export const fetchBankDetails = async (params) => {
  try {
    let res = await api.fetchBankDetails(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createBank = async (params, saveBankSchema) => {
  try {
    await helpers.validateSchema(saveBankSchema, params['bank_account_details_request_model']?.[0]);
    const res = await api.createBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateBank = async (id, params = {}, saveBankSchema) => {
  try {
    await helpers.validateSchema(saveBankSchema, params['bank_account_details_request_model']?.[0]);
    const res = await api.updateBank(id, params);
    return res;
  } catch (err) {
    throw err;
  }
};
//EKyc
export const eKycDocumentVerification = async (params) => {
  try {
    if (params['eKyc'] == 'Document') {
      await helpers.validateSchema(eKycDocumentValiadtion, params);
    } else if (params['eKyc'] == 'Pan') {
      await helpers.validateSchema(eKycPanValiadtion, params);
    }
    const res = await api.eKycDocumentVerification(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchEKycStatus = async (params) => {
  try {
    const res = await api.fetchEKycStatus(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const fetchEKycStatusCt = async (params) => {
  try {
    const res = await api.fetchEKycStatusCt(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const verifyIFSC = async (code) => {
  try {
    const res = await api.verifyIFSC(code);
    return res;
  } catch (err) {
    throw err;
  }
};

export const verifyIFSCzoop = async (code) => {
  try {
    const res = await api.verifyIFSCzoop(code);
    return res;
  } catch (err) {
    throw err;
  }
};

export const verifyBank = async (params) => {
  try {
    const res = await api.verifyBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const completeBank = async (params) => {
  try {
    const res = await api.completeBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteBank = async (params) => {
  try {
    const res = await api.deleteBank(params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const verifyVPAId = async (params) => {
  try {
    const res = await api.verifyVPAId(params);
    return res;
  } catch (err) {
    throw err;
  }
};
