import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import './CookieConsent.css';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const location = useLocation();
  const isAuthPage = location.pathname === '/account/login' || location.pathname === '/account/signup';

  useEffect(() => {
    // Check if user has already made a choice using cookies
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent && isAuthPage) {
      setShowBanner(true);
    }
  }, [isAuthPage]);

  const handleAccept = () => {
    // Set cookie with 1 year expiration
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setShowBanner(false);
  };

  const handleCookiePolicy = () => {
    // Redirect to cookie policy page
    window.location.href = '/cookie-policy.html';
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-consent-banner">
      <div className="cookie-consent-content">
        <p>By continuing to use this website, you consent to our use of cookies.</p>
        <div className="cookie-consent-buttons">
          <button onClick={handleCookiePolicy} className="cookie-consent-info">
            What for?
          </button>
          <button onClick={handleAccept} className="cookie-consent-accept">
            Yes, I understand
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
