import _ from 'lodash';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { REQ_OFFER_STATUS_ENUM } from 'constants/enums';
import api from 'lib/api';
import * as commodityPlanApi from 'services/commodityPlan/api';
import OfferService from 'services/offer';
import utils, { getUrlWithEncryptedQueryParams } from 'lib/utils';
import history from 'lib/history';
import notifications from 'lib/notifications';
import { commodityAuctionService, financePartnerService, qualityService } from 'services';
import { commodityPlanService, storageService } from 'services';
import { calculatorServices } from 'services';

const CommodityReqOfferQualitySpecSchema = yup.object().shape({
  commodity_spec_id: yup
    .number('Commodity spec name is required.')
    .required('Commodity spec name is required.')
    .positive('Commodity spec name is required.')
    .integer('Commodity spec name is required.'),
  qs_uom_id: yup
    .number('Commodity spec unit of measure is required.')
    .required('Commodity spec unit of measure is required.')
    .positive('Commodity spec unit of measure is required.')
    .integer('Commodity spec unit of measure is required.'),

  min_max_enum: yup
    .number('Commodity spec min/max value is required.')
    .required('Commodity spec min/max value is required.')
    .positive('Commodity spec min/max value is required.')
    .integer('Commodity spec min/max value is required.'),

  difference_by_enum: yup
    .number('Commodity spec difference unit is required.')
    .required('Commodity spec difference unit is required.')
    .positive('Commodity spec difference unit is required.')
    .integer('Commodity spec difference unit is required.'),
});

const RequirementSchemaObject = {
  commodity_id: yup
    .number('Commodity is required.')
    .required('Commodity is required.')
    .positive('Commodity is required.')
    .integer('Commodity is required.'),
  qty: yup.number().typeError('Quantity is required.').required('Quantity is required.'),
  qty_uom_id: yup
    .number('Quantity unit of measure is required.')
    .required('Quantity unit of measure is required.')
    .positive('Quantity unit of measure is required.')
    .integer('Quantity unit of measure is required.'),
  periodicity_enum: yup
    .number('Periodicity is required.')
    .required('Periodicity is required.')
    .positive('Periodicity is required.')
    .integer('Periodicity is required.'),

  // min_qty: yup
  //   .number('Minimum quantity is required.')
  //   .required('Minimum quantity is required.')
  //   .max(yup.ref('qty'), 'Minimum Quantity should be less than Quantity'),
  expected_price: yup
    .number()
    .typeError('Expected price is required.')
    .nullable(true)
    .when('is_rfq', {
      is: true,
      then: yup.number().typeError('Expected price is required.').optional(),
      otherwise: yup.number().typeError('Expected price is required.').required('Expected price is required.'),
    }),
  price_currency_id: yup.number('Price currency is required.'),
  price_uom_id: yup
    .number('Price unit of measure is required.')
    .required('Price unit of measure is required.')
    .positive('Price unit of measure is required.')
    .integer('Price unit of measure is required.'),
  address_id: yup
    .number('Location is required.')
    .required('Location is required.')
    .positive('Location is required.')
    .integer('Location is required.'),
  bill_to_address_id: yup
    .number('Location is required.')
    .required('Location is required.')
    .positive('Location is required.')
    .integer('Location is required.'),
  payment_term_id: yup
    .number('Payment term is required.')
    .required('Payment term is required.')
    .positive('Payment term is required.')
    .integer('Payment term is required.'),

  vehicle_type_id: yup.number('Max vehicle capacity is required.').when('is_walk_in', {
    is: true,
    then: yup.number().optional(),
    otherwise: yup
      .number()
      .required('Max vehicle capacity is required.')
      .positive('Max vehicle capacity is required.')
      .integer('Max vehicle capacity is required.'),
  }),

  qty_measured_at_enum: yup
    .number('Quantity measured at is required.')
    .required('Quantity measured at is required.')
    .positive('Quantity measured at is required.')
    .integer('Quantity measured at is required.'),
  quality_measured_at_enum: yup
    .number('Quality measured at is required.')
    .required('Quality measured at is required.')
    .positive('Quality measured at is required.')
    .integer('Quality measured at is required.'),
  commodity_type_code: yup.string('Commodity form is required.').required('Commodity form is required.'),
  commodity_residue_id: yup
    .number('Commodity residue is required.')
    .required('Commodity residue is required.')
    .positive('Commodity residue is required.')
    .integer('Commodity residue is required.'),
  entity_id: yup
    .number('Please select Company')
    .required('Please select Company')
    .positive('Please select Company')
    .integer('Please select Company'),

  // from_date: yup.date('From Date is required.').required('From Date is required.'),
  // to_date: yup.date('To Date is required.').required('To Date is required.'),
  packaging_id: yup
    .number()
    .typeError('Please select Packaging')
    .required('Please select Packaging')
    .positive('Please select Packaging'),
};

const RequirementSchema = yup.object().shape(RequirementSchemaObject);

// const RequirementIndentSchema = yup.object().shape({
//   ...RequirementSchemaObject,
//   valid_until: yup.string('Valid until date is required.').required('Valid until date is required.'),
// });

const RequirementSchemaWithDateValidation = yup.object().shape({
  ...RequirementSchemaObject,
  from_date: yup.date('From Date is required.').typeError('From Date is required.').required('From Date is required.'),
  to_date: yup.date('To Date is required.').typeError('To Date is required.').required('To Date is required.'),
  min_qty: yup.number('Minimum quantity is required.').min(0, 'Invalid Minimum quantity'),

  // closing_on: yup.string('Closing on date is required.').when('is_walk_in', {
  //   is: true,
  //   then: yup.string().optional(),
  //   otherwise: yup.string().required('Closing on date is required.'),
  // }),
  valid_until: yup.string('Valid until date is required.').when('is_walk_in', {
    is: true,
    then: yup.string().optional(),
    otherwise: yup.string().required('Valid until date is required.'),
  }),
});

const RequirementSchemaWithAvgBaleWeightValidation = yup.object().shape({
  ...RequirementSchemaObject,
  avg_bale_weight: yup.number().required('Avg weight of bale is required.'),
});

const RequirementSchemaWithYieldValidation = yup.object().shape({
  ...RequirementSchemaObject,
  yield: yup.number('Yield is required.').moreThan(0, 'Yield Should be at least 1.').required('Yield is required.'),
});

const PlanToRequirementSchema = yup.object().shape({
  commodity_id: yup
    .number('Commodity is required.')
    .required('Commodity is required.')
    .positive('Commodity is required.')
    .integer('Commodity is required.'),
  qty: yup.number('Quantity is required.').required('Quantity is required.'),
  qty_uom_id: yup
    .number('Quantity unit of measure is required.')
    .required('Quantity unit of measure is required.')
    .positive('Quantity unit of measure is required.')
    .integer('Quantity unit of measure is required.'),
  periodicity_enum: yup
    .number('Periodicity is required.')
    .required('Periodicity is required.')
    .positive('Periodicity is required.')
    .integer('Periodicity is required.'),

  min_qty: yup
    .number('Minimum quantity is required.')
    .required('Minimum quantity is required.')
    .max(yup.ref('qty'), 'Minimum Quantity should be less than Quantity'),
  expected_price: yup
    .number('Expected price is required.')
    .typeError('Expected price is required.')
    .required('Expected price is required.'),
  price_currency_id: yup.number('Price currency is required.'),
  price_uom_id: yup
    .number('Price unit of measure is required.')
    .required('Price unit of measure is required.')
    .positive('Price unit of measure is required.')
    .integer('Price unit of measure is required.'),
  address_id: yup
    .number('Location is required.')
    .required('Location is required.')
    .positive('Location is required.')
    .integer('Location is required.'),
  payment_term_id: yup
    .number('Payment term is required.')
    .required('Payment term is required.')
    .positive('Payment term is required.')
    .integer('Payment term is required.'),
  vehicle_type_id: yup
    .number('Max vehicle capacity is required.')
    .required('Max vehicle capacity is required.')
    .positive('Max vehicle capacity is required.')
    .integer('Max vehicle capacity is required.'),
  closing_on: yup.date('Closing on date is required.').required('Closing on date is required.'),
  valid_until: yup.date('Valid until date is required.').required('Valid until date is required.'),
  qty_measured_at_enum: yup
    .number('Quantity measured at is required.')
    .required('Quantity measured at is required.')
    .positive('Quantity measured at is required.')
    .integer('Quantity measured at is required.'),
  quality_measured_at_enum: yup
    .number('Quality measured at is required.')
    .required('Quality measured at is required.')
    .positive('Quality measured at is required.')
    .integer('Quality measured at is required.'),
  commodity_type_code: yup.string('Commodity form is required.').required('Commodity form is required.'),
  commodity_residue_id: yup
    .number('Commodity residue is required.')
    .required('Commodity residue is required.')
    .positive('Commodity residue is required.')
    .integer('Commodity residue is required.'),
  entity_id: yup
    .number('Please select Company')
    .required('Please select Company')
    .positive('Please select Company')
    .integer('Please select Company'),

  from_date: yup
    .date()
    .when('actual_plan_from_date', (from, schema) => from && schema.min(from))
    .when('actual_plan_to_date', (to, schema) => to && schema.max(to))
    .required('From Date is required'),

  to_date: yup
    .date()
    .when('actual_plan_from_date', (from, schema) => from && schema.min(from))
    .when('actual_plan_to_date', (to, schema) => to && schema.max(to))
    .required('To Date is required'),

  packaging_id: yup.number().required('Please select Packaging').positive('Please select Packaging'),
});

const saveRequirement = async (type, values, formikBag, isOpenBuySell, convertToBuy) => {
  let typeText = 'Buy';
  if (type === 'offers') {
    typeText = 'Sell';
  }

  try {
    await utils.validateSchema(
      isOpenBuySell || values['is_indent_buy_sell']
        ? values?.price_uom_code === 'Bale'
          ? values?.is_pick_up
            ? RequirementSchemaWithAvgBaleWeightValidation.concat(RequirementSchemaWithYieldValidation)
            : RequirementSchemaWithAvgBaleWeightValidation
          : values?.is_pick_up
          ? RequirementSchema.concat(RequirementSchemaWithYieldValidation)
          : RequirementSchema
        : RequirementSchemaWithDateValidation,
      values,
    );

    notifications.show({
      type: 'info',
      message: `Saving ${typeText}...`,
      key: 'req-form',
    });

    //To date and from date timezone fix

    values['from_date'] = values['from_date'] ? dayjs(values['from_date']).format('YYYY-MM-DD') : '';
    values['to_date'] = values['to_date'] ? dayjs(values['to_date']).format('YYYY-MM-DD') : '';
    values['closing_on'] = values['closing_on'] ? dayjs(values['closing_on']).format('YYYY-MM-DD HH:mm:ss') : '';
    if (values['indentString'] == 'IndentForm') {
      values['valid_until'] = values['valid_until'] ? dayjs(values['valid_until']).format('YYYY-MM-DD HH:mm:ss') : '';
    } else {
      values['valid_until'] = values['valid_until'] ? dayjs(values['valid_until']).format('YYYY-MM-DD HH:mm:ss') : '';
    }

    if (values?.is_pick_up) {
      values['closing_on'] = '';
      values['valid_until'] = '';
    }

    let res = '';
    let isPublished = values.req_offer_status_enum === REQ_OFFER_STATUS_ENUM.PUBLISHED ? true : false;

    if (type === 'requirements') {
      if (values['commodity_req_offer_id']) {
        // delete values['typeString'];
        // if (values['indentString'] == 'IndentForm' && values['typeString'] != 'ActualForm') {
        //   res = await commodityPlanApi.create(values);
        // } else {
        res = await commodityPlanApi.updateRequirement(values, isOpenBuySell);
        // }
      } else {
        if (isOpenBuySell) {
          res = await commodityPlanApi.createOpenBuy(values);
        } else {
          res = await commodityPlanApi.create(values);
        }
      }
    } else if (type === 'offers') {
      if (values['commodity_req_offer_id']) {
        // delete values['typeString'];
        // if (values['indentString'] == 'IndentForm') {
        //   res = await commodityPlanApi.createOffer(values);
        // } else {
        res = await commodityPlanApi.updateOffer(values, isOpenBuySell);
        // }
      } else {
        if (isOpenBuySell) {
          res = await commodityPlanApi.createOpenSell(values);
        } else {
          res = await commodityPlanApi.createOffer(values);
        }
      }
    }

    notifications.update({
      type: 'success',
      message: isPublished ? `${typeText} saved.` : `${typeText} draft saved.`,
      key: 'req-form',
    });

    let indentDealsDocuments = values['indentDocuments'];
    let indentDealsDocumentsArray = values['indentDocumentsArray'];
    let reqOfferStatusEnum = values['req_offer_status_enum'];
    let commodityReqOfferId = _.get(res, 'commodity_req_offer_id');

    let reqId = values['commodity_req_offer_id'] || 0;
    reqId = parseInt(reqId);

    if (indentDealsDocumentsArray?.length > 5) {
      try {
        notifications.show({
          type: 'error',
          message: 'Cannot upload more than 5',
          key: 'req-form',
        });
        return undefined;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      if (indentDealsDocuments?.length) {
        indentDealsDocuments.map(async (item, index) => {
          let params = {
            file: item,
            entity_id: reqOfferStatusEnum === 0 ? commodityReqOfferId : reqId,
            entity_type_enum: 28,
            document_module_enum: 22,
            company_affiliate_enum: 1,
            user_type_enum: 1,
            attachment_id: 0,
          };
          try {
            notifications.show({
              type: 'info',
              message: `Uploading Document...`,
              key: 'req-form',
            });

            const res = await financePartnerService.upload(params);
            notifications.show({
              type: 'success',
              message: `Document Uploaded.`,
              key: 'req-form',
            });
            // setTimeStamp(Date.now());
            return res;
          } catch (err) {
            console.log('handleCreate--->error---->', err);
            let errors = _.get(err, 'errors') || {};
            let errMessage = _.get(err, 'message') || 'Unable to process request.';
            notifications.show({
              type: 'error',
              message: errMessage,
              key: 'req-form',
              duration: 20,
            });
          }
        });
      }
    }

    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }

  return false;
};

export const handleSave = async (type, values, formikBag, isOpenBuySell, indent, convertToBuy, isSmartBuy) => {
  let typeText = 'Buy';
  if (type === 'offers') {
    typeText = 'Sell';
  }
  formikBag.setSubmitting(true);

  const res = await saveRequirement(type, values, formikBag, isOpenBuySell, convertToBuy);

  if (res && type === 'offers') {
    await savePriceCalculatorData(values, res?.commodity_req_offer_id || 0);
  }

  formikBag.setSubmitting(false);
  if (!res) {
    return false;
  }

  let reqId = _.get(formikBag, 'values.commodity_req_offer_id') || 0;
  let isPublished = values?.req_offer_status_enum === REQ_OFFER_STATUS_ENUM.PUBLISHED ? true : false;
  let back = `/commodity/${type}/user?page=1`;
  if (indent && isSmartBuy === 'true') {
    back = `/control-tower/indent-deals?page=1`;
  } else if (isSmartBuy === 'false') {
    back = `/control-tower/seller-indent-deals?page=1`;
  }
  const encodeBack = utils.encodeCustomUrl(back);
  //checking for create
  if (!reqId) {
    reqId = _.get(res, 'commodity_req_offer_id') || 0;
    if (values['is_indent_buy_sell'] === true) {
      history.push(
        getUrlWithEncryptedQueryParams(
          `/commodity/${type}/${reqId}/overview?back=${encodeBack}&indent=true&isSmartBuy=${isSmartBuy}`,
        ),
      );
    } else {
      // history.push(`/commodity/${type}/edit/${reqId}?back=${encodeBack}`);
      history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`));
    }
  }

  if (reqId && isPublished) {
    if (values['is_indent_buy_sell'] === true) {
      history.push(
        getUrlWithEncryptedQueryParams(
          `/commodity/${type}/${reqId}/overview?back=${encodeBack}&indent=true&isSmartBuy=${isSmartBuy}`,
        ),
      );
    } else {
      history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`));
    }
  }
};

export const handlePublish = async (
  id = 0,
  type,
  values,
  formikBag,
  isOpenBuySell,
  typeOfIndividualEnum,
  isProfileSubscribed,
  isStockTransfer,
  indent,
  convertToBuy,
  isSmartBuy,
) => {
  let typeText = 'Buy';
  if (type === 'offers') {
    typeText = 'Sell';
  }

  let indentDealsDocuments = values['indentDocuments'];

  delete values['indentDocuments'];

  formikBag.setSubmitting(true);

  let newParams = { ...values };
  if (values['indentString'] == 'converttobuy') {
    newParams = {
      ...values,
      indent_commodity_req_offer_ids: [values['commodity_req_offer_id']],
      commodity_req_offer_id: 0,
    };
  }

  const res = await saveRequirement(type, newParams, formikBag, isOpenBuySell);

  if (!res) {
    formikBag.setSubmitting(false);
    return false;
  }

  try {
    notifications.show({
      type: 'info',
      message: `Publishing ${typeText}...`,
      key: 'req-form',
    });

    let reqId = _.get(res, 'commodity_req_offer_id') || 0;
    reqId = parseInt(reqId);

    // formikBag.setFieldValue('commodity_req_offer_id', reqId);

    let PublishRes = '';
    if (type === 'requirements') {
      if (isOpenBuySell) {
        PublishRes = await api.publishOpenBuy(reqId, [values['commodity_req_offer_id']], newParams);
      } else {
        PublishRes = await api.publishRequirement(reqId, [values['commodity_req_offer_id']], newParams);
      }
    } else {
      if (isOpenBuySell) {
        PublishRes = await OfferService.PublishOpenSell(reqId, [values['commodity_req_offer_id']], newParams);
      } else {
        PublishRes = await OfferService.PublishPlainOffer(reqId, [values['commodity_req_offer_id']], newParams);
      }
      if (PublishRes) {
        await savePriceCalculatorData(values, PublishRes?.data.commodity_req_offer_id || 0);
      }
    }

    if (indentDealsDocuments?.length > 5) {
      try {
        notifications.show({
          type: 'error',
          message: 'Cannot upload more than 5',
          key: 'req-form',
        });
        return undefined;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      if (indentDealsDocuments?.length > 0) {
        indentDealsDocuments.map(async (item, index) => {
          let params = {
            file: item,
            entity_id: reqId,
            entity_type_enum: 28,
            document_module_enum: 22,
            company_affiliate_enum: 1,
            user_type_enum: 1,
            attachment_id: 0,
          };

          try {
            notifications.show({
              type: 'info',
              message: `Uploading Document...`,
              key: 'req-form',
            });

            const res = await financePartnerService.upload(params);
            notifications.show({
              type: 'success',
              message: `Document Uploaded.`,
              key: 'req-form',
            });
            // setTimeStamp(Date.now());
            return res;
          } catch (err) {
            console.log('handleCreate--->error---->', err);
            let errors = _.get(err, 'errors') || {};
            let errMessage = _.get(err, 'message') || 'Unable to process request.';
            notifications.show({
              type: 'error',
              message: errMessage,
              key: 'req-form',
              duration: 20,
            });
          }
        });
      }
    }

    if (typeOfIndividualEnum === 1) {
      notifications.update({
        type: 'success',
        message: `${typeText} published. ${
          (!isProfileSubscribed || !values?.isSubscribed) &&
          !values?.isCompanyAffSubscribed &&
          !values?.isLocationSubscribed
            ? `You need to subscribe to conclude a deal.`
            : ''
        }`,
        key: 'req-form',
      });
    } else {
      notifications.update({
        type: 'success',
        message: `${typeText} published. ${
          (!isProfileSubscribed || !values?.isSubscribed) &&
          !values?.isCompanyAffSubscribed &&
          !values?.isLocationSubscribed
            ? `You need to subscribe to conclude a deal.`
            : ''
        }`,
        key: 'req-form',
      });
    }

    let back = `/commodity/${type}/user`;
    if (indent && isSmartBuy === 'true') {
      back = `/control-tower/indent-deals?page=1`;
    } else if (isSmartBuy === 'false') {
      back = `/control-tower/seller-indent-deals?page=1`;
    }

    const encodeBack = utils.encodeCustomUrl(back);
    // if (values['indentString'] == 'converttobuy') {
    //   history.push(`/commodity/${type}/${reqId}/overview?back=${encodeBack}&indent=true`);
    // } else {

    if (values['is_indent_buy_sell'] === true) {
      history.push(
        getUrlWithEncryptedQueryParams(
          `/commodity/${type}/${reqId}/overview?back=${encodeBack}&indent=true&isindentconverted=true&isPublished=true&isSmartBuy=${isSmartBuy}`,
        ),
      );
    } else if (values['indentString'] == 'converttobuy') {
      history.push(
        getUrlWithEncryptedQueryParams(
          `/commodity/${type}/${reqId}/overview?back=${encodeBack}&isindentconverted=true&isSmartBuy=${isSmartBuy}`,
        ),
      );
    } else {
      history.push(
        getUrlWithEncryptedQueryParams(
          `/commodity/${type}/${reqId}/overview?back=${encodeBack}&isPublished=true&isSmartBuy=${isSmartBuy}`,
        ),
      );
    }
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';

    if (!_.isEmpty(errors)) {
      formikBag.setErrors({
        ...errors,
      });

      errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
    }

    notifications.show({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: null,
    });
  }

  formikBag.setSubmitting(false);
};

export const handleSavePlan = async (type, values, formikBag) => {
  let typeText = 'Buy';
  if (type === 'offers') {
    typeText = 'Sell';
  }

  formikBag.setSubmitting(true);

  const res = await savePlan(type, values, formikBag);

  formikBag.setSubmitting(false);
  if (!res) {
    return false;
  }

  let reqId = _.get(res, 'plan_id') || _.get(res, 'commodity_req_offer_id') || 0;
  let plan_actual_enum = _.get(res, 'plan_actual_enum') || 0;

  if (reqId && plan_actual_enum === 1) {
    //plan
    const back = `/commodity/${type}/plans?page=1`;
    const encodeBack = utils.encodeCustomUrl(back);
    reqId = _.get(res, 'commodity_req_offer_id') || 0;
    history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/plans/${reqId}/overview?back=${encodeBack}`));
  }
  if (reqId && plan_actual_enum === 2) {
    //actual buy /sell
    const back = `/commodity/${type}/user?page=1`;
    const encodeBack = utils.encodeCustomUrl(back);
    reqId = _.get(res, 'commodity_req_offer_id') || 0;
    history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`));
  }
};

const savePlan = async (type, values, formikBag) => {
  let typeText = 'Buy Plan';
  if (type === 'offers') {
    typeText = 'Sell Plan';
  }
  if (values['typeString'] == 'ActualForm') {
    typeText = 'Buy';
    if (type === 'offers') {
      typeText = 'Sell';
    }
  }
  try {
    notifications.show({
      type: 'info',
      message: `Saving ${typeText}...`,
      key: 'req-form',
    });

    //To date and from date timezone fix

    values['from_date'] = dayjs(values['from_date']).format('YYYY-MM-DD');
    values['to_date'] = dayjs(values['to_date']).format('YYYY-MM-DD');
    if (values['closing_on'] != '') {
      values['closing_on'] = dayjs(values['closing_on']).format('YYYY-MM-DD HH:mm:ss');
      values['valid_until'] = dayjs(values['valid_until']).format('YYYY-MM-DD HH:mm:ss');
    }

    let res = '';
    if (type === 'requirements') {
      if (values['commodity_req_offer_id']) {
        if (values['plan_actual_enum'] === 1 && values['typeString'] === 'ActualForm') {
          values['plan_id'] = values['commodity_req_offer_id'];
          await utils.validateSchema(PlanToRequirementSchema, values);
          delete values['actual_plan_from_date'];
          delete values['actual_plan_to_date'];
          res = await commodityPlanApi.create(values);
        } else {
          res = await commodityPlanService.updateRequirement(values);
        }
      } else {
        res = await commodityPlanService.create(values);
      }
    } else if (type === 'offers') {
      if (values['commodity_req_offer_id']) {
        if (values['plan_actual_enum'] === 1 && values['typeString'] === 'ActualForm') {
          values['plan_id'] = values['commodity_req_offer_id'];
          await utils.validateSchema(PlanToRequirementSchema, values);
          delete values['actual_plan_from_date'];
          delete values['actual_plan_to_date'];
          res = await commodityPlanService.createOffer(values);
        } else {
          res = await commodityPlanService.updateOffer(values);
        }
      } else {
        res = await commodityPlanService.createOffer(values);
      }
    }

    notifications.update({
      type: 'success',
      message: `${typeText} saved.`,
      key: 'req-form',
    });

    return res;
  } catch (err) {
    utils.displayErrors(err, formikBag);
  }

  return false;
};

export const handlePublishPlan = async (id = 0, type, values, formikBag, isOpenBuySell) => {
  let typeText = 'Buy';
  if (type === 'offers') {
    typeText = 'Sell';
  }

  formikBag.setSubmitting(true);

  try {
    notifications.show({
      type: 'info',
      message: `Publishing ${typeText}...`,
      key: 'req-form',
    });

    let res;
    if (typeText === 'Buy') {
      await utils.validateSchema(isOpenBuySell ? RequirementSchema : RequirementSchemaWithDateValidation, values);
      res = await commodityPlanService.savePlanAsRequirement(values);
    } else if (typeText === 'Sell') {
      await utils.validateSchema(isOpenBuySell ? RequirementSchema : RequirementSchemaWithDateValidation, values);
      res = await commodityPlanService.savePlanAsOffer(values);
    }
    if (!res) {
      formikBag.setSubmitting(false);
      return false;
    }

    let reqId = _.get(res, 'commodity_req_offer_id') || 0;

    reqId = parseInt(reqId);

    formikBag.setFieldValue('commodity_req_offer_id', reqId);

    let PublishRes = '';
    if (type === 'requirements') {
      PublishRes = await api.publishRequirement(reqId);
    } else {
      PublishRes = await OfferService.PublishPlainOffer(reqId);
    }

    notifications.update({
      type: 'success',
      message: `${typeText}...published.`,
      key: 'req-form',
    });
    const back = `/commodity/${type}/plans?page=1`;
    const encodeBack = utils.encodeCustomUrl(back);
    history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/${reqId}/overview?back=${encodeBack}`));
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';

    if (!_.isEmpty(errors)) {
      formikBag.setErrors({
        ...errors,
      });

      errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
    }

    notifications.show({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: null,
    });
  }

  formikBag.setSubmitting(false);
};

export const handlePublishAuction = async (id = 0, type, values, formikBag) => {
  let typeText = 'Buy Auction';
  if (type === 'offers') {
    typeText = 'Sell Auction';
  }

  try {
    notifications.show({
      type: 'info',
      message: `Publishing ${typeText}...`,
      key: 'req-form',
    });

    let indentDealsDocuments = values['indentDocuments'];

    values['from_date'] = dayjs(values['from_date']).format('YYYY-MM-DD');
    values['to_date'] = dayjs(values['to_date']).format('YYYY-MM-DD');
    values['auction_start_datetime'] = dayjs(values['auction_start_datetime']).format('YYYY-MM-DD HH:mm:ss');

    let saveAsDraft = '';
    if (type === 'requirements') {
      saveAsDraft = await commodityAuctionService.save(type, values);
    } else {
      saveAsDraft = await commodityAuctionService.save(type, values);
    }

    let saveReqId = _.get(saveAsDraft, 'commodity_auction_master_id') || 0;
    values['commodity_auction_master_id'] = saveReqId;

    let PublishRes = '';
    if (type === 'requirements') {
      PublishRes = await commodityAuctionService.create(type, values);
    } else {
      PublishRes = await commodityAuctionService.create(type, values);
    }

    let reqId = _.get(PublishRes, 'commodity_auction_master_id') || 0;

    if (indentDealsDocuments?.length > 5) {
      try {
        notifications.show({
          type: 'error',
          message: 'Cannot upload more than 5',
          key: 'req-form',
        });
        return undefined;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      if (indentDealsDocuments?.length > 0) {
        indentDealsDocuments.map(async (item, index) => {
          let params = {
            file: item,
            entity_id: reqId,
            entity_type_enum: 28,
            document_module_enum: 22,
            company_affiliate_enum: 1,
            user_type_enum: 1,
            attachment_id: 0,
          };

          try {
            notifications.show({
              type: 'info',
              message: `Uploading Document...`,
              key: 'req-form',
            });

            const res = await financePartnerService.upload(params);
            notifications.show({
              type: 'success',
              message: `Document Uploaded.`,
              key: 'req-form',
            });
            // setTimeStamp(Date.now());
            return res;
          } catch (err) {
            console.log('handleCreate--->error---->', err);
            let errors = _.get(err, 'errors') || {};
            let errMessage = _.get(err, 'message') || 'Unable to process request.';
            notifications.show({
              type: 'error',
              message: errMessage,
              key: 'req-form',
              duration: 20,
            });
          }
        });
      }
    }

    notifications.update({
      type: 'success',
      message: `${typeText} published. ${
        !values?.isSubscribed && !values?.isCompanyAffSubscribed && !values?.isLocationSubscribed
          ? `You need to subscribe to conclude a deal.`
          : ''
      }`,
      key: 'req-form',
    });

    const back = `/commodity/${type}/auction-user?page=1`;
    const encodeBack = utils.encodeCustomUrl(back);
    history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/auction/${reqId}/overview?back=${encodeBack}`));
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';

    if (!_.isEmpty(errors)) {
      formikBag.setErrors({
        ...errors,
      });

      errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
    }

    notifications.show({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: null,
    });
  }

  formikBag.setSubmitting(false);
};

export const handleUpdateAuction = async (id = 0, type, values, formikBag) => {
  let typeText = 'Buy Auction';
  if (type === 'offers') {
    typeText = 'Sell Auction';
  }

  try {
    notifications.show({
      type: 'info',
      message: `Updating ${typeText}...`,
      key: 'req-form',
    });

    let PublishRes = '';
    if (type === 'requirements') {
      PublishRes = await commodityAuctionService.update(type, values);
    } else {
      PublishRes = await commodityAuctionService.update(type, values);
    }

    let reqId = _.get(PublishRes, 'commodity_auction_master_id') || 0;

    notifications.update({
      type: 'success',
      message: `${typeText}...updated.`,
      key: 'req-form',
    });

    let indentDealsDocuments = values['indentDocuments'];
    let indentDealsDocumentsArray = values['indentDocumentsArray'];
    // let reqId = values['commodity_req_offer_id'] || 0;
    reqId = parseInt(reqId);

    if (indentDealsDocumentsArray?.length > 5) {
      try {
        notifications.show({
          type: 'error',
          message: 'Cannot upload more than 5',
          key: 'req-form',
        });
        return undefined;
      } catch (err) {
        console.log('error', err);
      }
    } else {
      if (indentDealsDocuments?.length) {
        indentDealsDocuments.map(async (item, index) => {
          let params = {
            file: item,
            entity_id: reqId,
            entity_type_enum: 28,
            document_module_enum: 22,
            company_affiliate_enum: 1,
            user_type_enum: 1,
            attachment_id: 0,
          };
          try {
            notifications.show({
              type: 'info',
              message: `Uploading Document...`,
              key: 'req-form',
            });

            const res = await financePartnerService.upload(params);
            notifications.show({
              type: 'success',
              message: `Document Uploaded.`,
              key: 'req-form',
            });
            // setTimeStamp(Date.now());
            return res;
          } catch (err) {
            console.log('handleCreate--->error---->', err);
            let errors = _.get(err, 'errors') || {};
            let errMessage = _.get(err, 'message') || 'Unable to process request.';
            notifications.show({
              type: 'error',
              message: errMessage,
              key: 'req-form',
              duration: 20,
            });
          }
        });
      }
    }

    const back = `/commodity/${type}/auction-user?page=1`;
    const encodeBack = utils.encodeCustomUrl(back);
    history.push(getUrlWithEncryptedQueryParams(`/commodity/${type}/auction/${reqId}/overview?back=${encodeBack}`));
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';

    if (!_.isEmpty(errors)) {
      formikBag.setErrors({
        ...errors,
      });

      errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
    }

    notifications.show({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: null,
    });
  }

  formikBag.setSubmitting(false);
};

export const handelStockTransferWithTransport = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: `Creating stock transfer`,
      key: 'req-form',
    });

    let res;
    // await utils.validateSchema(RequirementSchemaWithDateValidation, values);
    res = await storageService.stockTransferWithTransport(values);
    if (!res) {
      formikBag.setSubmitting(false);
      return false;
    }
    notifications.update({
      type: 'success',
      message:
        values?.operation_enum === 1 ? `Stock transfer created successfully.` : `Stock transfer updated successfully.`,
      key: 'req-form',
    });
    // const back = `/market/storage/transfers?page=1`;
    // const encodeBack = utils.encodeCustomUrl(back);
    // history.push(back);
    return res;
  } catch (err) {
    let errors = _.get(err, 'errors') || {};
    let errMessage = _.get(err, 'message') || 'Unable to process request.';
    if (!_.isEmpty(errors)) {
      formikBag.setErrors({
        ...errors,
      });
      errMessage = _.first(Object.keys(errors)) + ' - ' + _.first(Object.values(errors));
    }
    notifications.update({
      type: 'error',
      message: errMessage,
      key: 'req-form',
      duration: null,
    });
  }
};

export const handleDelete = async (values, formikBag) => {
  try {
    notifications.show({
      type: 'info',
      message: 'Deleting Document...',
      key: 'req-form',
    });
    const res = await qualityService.handleQualityUploadDestroy(values);
    notifications.show({
      type: 'success',
      message: 'Document deleted.',
      key: 'req-form',
    });
    return res;
  } catch (err) {
    console.log('handleDelete--->error---->', err);
    const errors = _.get(err, 'message') || 'Error';
    notifications.show({
      type: 'error',
      message: errors,
      key: 'req-form',
    });
  }
};

async function savePriceCalculatorData(values, id) {
  try {
    //Code to Save Landed Price Calculations through Price Calculator
    let calcData = values?.priceCalculatorData || null;
    if (calcData) {
      let priceCalculatorData = {
        ...calcData,
        commodity_req_offer_id: id, //commodity id
        commodity_req_offer_response_id: null, // quotation id
      };
      await calculatorServices.saveUpdatePriceCalculation(priceCalculatorData);
    }
  } catch (err) {
    notifications.show({
      type: 'error',
      message: 'Failed to save price calculator data.',
      key: 'req-form',
    });
    console.error(err);
  }
}
