/* eslint-disable no-unused-vars */
import qs from 'query-string';
import * as yup from 'yup';

import * as fetch from 'services/fetch';
import { BidMixedType } from 'services/bid';
import utils, { getDecryptedUrl } from 'lib/utils';

import config from 'config';
const { baseUrl } = config;

const BASE_URL = '/BioFuelRequirementOffers/api';

enum PeriodicityEnum {
  Once = 1,
  PerDay = 2,
  PerWeek = 3,
  PerMonth = 4,
  PerYear = 5,
}

enum UserTypeEnum {
  Enterprise = 1,
  NonEnterprise = 2,
}

enum CompanyAffiliateEnum {
  Company = 1,
  Affiliate = 2,
}

enum AboveBelowPenaltyEnum {
  Above = 1,
  Below = 2,
}

enum AboveBelowRejectionEnum {
  Above = 1,
  Below = 2,
}

enum FollowUnFollowEnum {
  Follow = 1,
  UnFollow = 2,
}

const QualitySpec = yup
  .object()
  .shape({
    commodity_req_offer_quality_spec_id: yup.number().positive().integer(),
    commodity_spec_id: yup.number().required().positive().integer(),
    qs_uom_id: yup.number().required().positive().integer(),
    desired_value: yup.number().required(),
    min_max_enum: yup.number().required().positive().integer(),
    rejection_value: yup.number().positive().integer(),
    difference_by_enum: yup.number().required().positive().integer(),
    // premium_value: yup.number(),
    // discount_value: yup.number(),
  })
  .defined();

const PlainOfferDataSchema = yup
  .object()
  .shape({
    commodity_req_offer_id: yup.number().required().integer().default(0), // PK
    commodity_id: yup.number().required().positive().integer(),
    // commodity_form_id: yup.number().required().positive().integer(),
    // commodity_residue_id: yup.number().required().positive().integer(),
    qty: yup.number().required().positive().integer(),
    qty_uom_id: yup.number().required().positive().integer().default(1),
    periodicity_enum: yup.number().oneOf([1, 2, 3, 4, 5]).defined().required(),
    from_date: yup.date().required(),
    to_date: yup.date().required(),
    min_qty: yup
      .number()
      .required()
      .positive()
      .integer()
      .max(yup.ref('qty'), 'Minimum Quantity should be less than Quantity'),
    expected_price: yup.number().required().positive().integer(),
    price_currency_id: yup.number().required().positive().integer(),
    payment_term_id: yup.number().required().positive().integer(),
    additional_comments: yup.string(),
    address_id: yup.number().required().positive().integer(),
    closing_on: yup.date().required(),
    commodity_req_offer_quality_spec: yup.array().of(QualitySpec),
    person_id: yup.number().notRequired(), //.integer(),
    user_type_enum: yup.mixed().notRequired(), //.oneOf([1, 2]).required(),
    company_affiliate_enum: yup.mixed().notRequired(), //.oneOf([1, 2]).required(),
  })
  .defined();

const PlainOfferLabelSchema = yup
  .object()
  .shape({
    commodity_type_id: yup.number().required().positive().integer(),
    commodity_type_code: yup.string().defined(),
    commodity_code: yup.string().defined(),
    commodity_form_code: yup.string().defined(),
    qty_uom_code: yup.string().defined(),
    periodicity_enum_code: yup.string().defined(),
    delivery_date_range: yup.string().defined(),
    open_qty: yup.number().defined(),
    total_qty: yup.number().defined(),
    coarse_location: yup.string().defined(),
    buyer_rating: yup.number().defined(),
    seller_rating: yup.number().defined(),
    person_id: yup.number().required().positive().integer(),
    req_offer_status_enum: yup.number().required().positive().integer(), // @TODO details required
    req_offer_status_enum_code: yup.string().defined(),
    transportation_cost: yup.number().defined(),
    total_response_count: yup.number().defined(),
    total_submitted_count: yup.number().defined(),
    total_accepted_count: yup.number().defined(),
    total_rejected_count: yup.number().defined(),
    is_followed_by_person: yup.boolean().default(false).defined(),
    price_currency_code: yup.string().defined(),
    payment_term_code: yup.string().defined(),
  })
  .defined();

const PlainOfferMixedSchema = PlainOfferDataSchema.concat(PlainOfferLabelSchema);
const PlainOfferMixedArraySchema = yup.array().of(PlainOfferMixedSchema);

export type PlainOfferDataType = yup.InferType<typeof PlainOfferDataSchema>;
export type PlainOfferLabelType = yup.InferType<typeof PlainOfferLabelSchema>;
export type PlainOfferMixedType = yup.InferType<typeof PlainOfferMixedSchema>;

export type QualitySpec = {
  commodity_req_offer_quality_spec_id: number;
  commodity_req_offer_id: number;
  commodity_spec_id: number;
  qs_uom_id: number;
  qs_uom_code: string;
  desired_value: number;
  min_max_enum: number;
  rejection_value: number;
  difference_by_enum: number;
  premium_value: number;
  discount_value: number;
  message_text: string | null;
  status_enum: boolean;
  created_by: number;
  created_datetime: Date;
  modified_by: number;
  modified_datetime: Date;
  lock_id: number;
  commodity_spec_code: string;
  min_max_enum_string: string;
  difference_by_enum_string: string;
};

const FetchAllPlainOfferWithinCircle = async (page: number, pageSize: number) => {
  const urlParams = {
    entity_id: globalThis?.user?.userid,
    address_id: 8, // @TODO: hardcoded
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FetchAllPlainOfferWithinCircle?${qs}`;

  const listData = await fetch.fetchList<PlainOfferMixedType>(url);

  // yup.array().of(PlainOfferLabelSchema1).validateSync(listData.data);

  return listData;
};

const FetchAllPlainOfferAgainstEntityId = async (page: number, pageSize: number) => {
  const urlParams = {
    entity_id: globalThis?.user?.userid,
    address_id: 8, // @TODO: hardcoded
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
    offset: (page - 1) * pageSize,
    page_size: pageSize,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FetchAllPlainOfferAgainstEntityId?${qs}`;

  const listData = await fetch.fetchList<PlainOfferMixedType>(url);

  // PlainOfferMixedArraySchema.validateSync(listData.data);

  // yup.array().of(PlainOfferLabelSchema).validateSync(listData.data);

  return listData;
};

const FetchPlainOfferDetailsAgainstId = async (commodityReqOfferId: string) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FetchPlainOfferDetailsAgainstId?${qs}`;

  // const details = await fetch.fetchOne<PlainOfferMixedType>(url);
  const details = await fetch.fetchList<QualitySpec>(url);

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const SavePlainOffer = async (payload) => {
  let finalPayload = {
    person_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1, // @TODO: hardcoded,
    quality_measured_at_enum: 1, // @TODO: hardcoded,
    vehicle_type_id: 1, // @TODO: hardcoded,
    ...payload,
  };

  finalPayload.periodicity_enum = parseInt(finalPayload.periodicity_enum);
  finalPayload.commodity_form_id = parseInt(finalPayload.commodity_form_id);
  finalPayload.commodity_residue_id = parseInt(finalPayload.commodity_residue_id);

  finalPayload.total_qty = utils.caculateFinalQuantity(
    finalPayload.qty,
    finalPayload.periodicity_enum,
    finalPayload.from_date,
    finalPayload.to_date,
  );

  const acl = utils.getACL(_.get(window, 'user') || {});
  if (!acl.isCompany) {
    finalPayload['entity_id'] = finalPayload['person_id'];
  }

  const url = `${BASE_URL}/PlainOffer/SavePlainOffer`;

  const details = await fetch.create<PlainOfferMixedType>(url, finalPayload);

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const EditPlainOffer = async (payload) => {
  let finalPayload = {
    person_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
    company_affiliate_enum: 1, // @TODO: hardcoded
    quality_measured_at_enum: 1, // @TODO: hardcoded,
    vehicle_type_id: 1, // @TODO: hardcoded,
    ...payload,
  };

  finalPayload.periodicity_enum = parseInt(finalPayload.periodicity_enum);
  finalPayload.commodity_form_id = parseInt(finalPayload.commodity_form_id);
  finalPayload.commodity_residue_id = parseInt(finalPayload.commodity_residue_id);

  finalPayload.total_qty = utils.caculateFinalQuantity(
    finalPayload.qty,
    finalPayload.periodicity_enum,
    finalPayload.from_date,
    finalPayload.to_date,
  );

  const acl = utils.getACL(_.get(window, 'user') || {});
  if (!acl.isCompany) {
    finalPayload['entity_id'] = finalPayload['person_id'];
  }

  const url = `${BASE_URL}/PlainOffer/EditPlainOffer`;

  PlainOfferDataSchema.validateSync(finalPayload);
  const details = await fetch.update<PlainOfferDataType>(url, finalPayload);

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const PublishPlainOffer = async (commodityReqOfferId: string, indentIds: any, newParams: any) => {
  function checkConvertToBuy() {
    const currentUrl = getDecryptedUrl(window.location.href).toLowerCase();
    const hasConvertToBuyTrue = currentUrl.includes('converttobuy=true');
    return hasConvertToBuyTrue;
  }

  const isLinkedBuyOrSell = checkConvertToBuy();

  let urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    person_id: globalThis?.user?.userid,
  };
  if (indentIds.length > 0) {
    urlParams = {
      commodity_req_offer_id: commodityReqOfferId,
      person_id: globalThis?.user?.userid,
      has_attachment: newParams?.has_attachment,
    };

    if (isLinkedBuyOrSell) {
      urlParams.indent_commodity_req_offer_ids = [indentIds];
    }
  }
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/PublishPlainOffer_PhaseTwo?${qs}`;

  const details = await fetch.update<{}>(url, {});

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const PublishOpenSell = async (commodityReqOfferId: string, indentIds: any, newParams: any) => {
  const loc = getDecryptedUrl(window.location.href);
  const isIndentConvertedFalse = loc.includes('isindentconverted=false') || loc.includes('isindentconverted=undefined');
  let urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    person_id: globalThis?.user?.userid,
  };
  if (indentIds.length > 0) {
    if (loc) {
      if (isIndentConvertedFalse) {
        urlParams = {
          commodity_req_offer_id: commodityReqOfferId,
          person_id: globalThis?.user?.userid,
          has_attachment: newParams?.has_attachment,
        };
      } else {
        urlParams = {
          commodity_req_offer_id: commodityReqOfferId,
          person_id: globalThis?.user?.userid,
          indent_commodity_req_offer_ids: indentIds,
          has_attachment: newParams?.has_attachment,
        };
      }
    }
  }
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/OpenBuySell/PublishPlainOffer?${qs}`;

  const details = await fetch.update<{}>(url, {});

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

// const FetchBidsAgainstPlainOfferId = async (commodityReqOfferId: string, page: number, pageSize: number) => {
//   const urlParams = {
//     commodity_req_offer_id: commodityReqOfferId,
//     offset: (page - 1) * pageSize,
//     page_size: pageSize,
//   };

//   const url = `${BASE_URL}/PlainOffer/FetchBidsAgainstPlainOfferId?${qs.stringify(urlParams)}`;

//   const listData = await fetch.fetchList<BidMixedType>(url);

//   // PlainOfferMixedArraySchema.validateSync(listData.data);

//   // yup.array().of(PlainOfferLabelSchema).validateSync(listData.data);

//   return listData;
// };

const AcceptBid = async () => {
  // /api/PlainOffer/AcceptBid
};
const RejectBidById = async () => {
  // /api/PlainOffer/RejectBidById
};

const WithdrawPlainOfferById = async (commodityReqOfferId: string) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    person_id: globalThis?.user?.userid,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/WithdrawPlainOfferById?${qs}`;

  const details = await fetch.update<{}>(url, {});

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const FollowUnfollowPlainOffer = async (commodityReqOfferId: number, isFollow: boolean) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    follow_unfollow_enum: isFollow ? 1 : 2,
    person_id: globalThis?.user?.userid,
    user_type_enum: globalThis?.user?.profile?.person?.type_of_individual_enum === 4 ? 1 : 2,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FollowUnfollowPlainOffer?${qs}`;

  const details = await fetch.update<{}>(url, {});

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const FetchPlainOfferAgainstId = async (commodityReqOfferId: string, selectedAddressId: string) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    person_id: globalThis?.user?.userid,
    source_address_id: selectedAddressId || 0,
  };
  const qs = utils.getQs(urlParams);
  const url = `${BASE_URL}/PlainOffer/FetchPlainOfferAgainstId_ELOC?${qs}`;

  const details = await fetch.fetchOne<PlainOfferMixedType>(url);

  // PlainOfferMixedSchema.validateSync(details);

  return details;
};

const FetchPlainOfferAgainstIdQuote = async (commodityReqOfferId: string, selectedAddressId: string) => {
  const urlParams = {
    commodity_req_offer_id: commodityReqOfferId,
    person_id: globalThis?.user?.userid,
    source_address_id: selectedAddressId,
  };

  const url = `${baseUrl}/BioFuelRequirementOffers/api/PlainOffer/FetchPlainOfferAgainstId_ELOC?${qs.stringify(
    urlParams,
  )}`;

  const resData = await fetch.fetchOne<PlainOfferMixedType>(url);

  // PlainOfferMixedSchema.validateSync(details);

  return resData;
};

export default {
  FetchAllPlainOfferWithinCircle,
  FetchPlainOfferDetailsAgainstId,
  FetchAllPlainOfferAgainstEntityId,
  FetchPlainOfferAgainstId,
  SavePlainOffer,
  EditPlainOffer,
  PublishPlainOffer,
  PublishOpenSell,
  RejectBidById,
  // FetchBidsAgainstPlainOfferId,
  AcceptBid,
  WithdrawPlainOfferById,
  FollowUnfollowPlainOffer,
  FetchPlainOfferAgainstIdQuote,
};

export { PlainOfferDataSchema, PlainOfferLabelSchema, PlainOfferMixedSchema, PlainOfferMixedArraySchema };
