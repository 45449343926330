import OverviewHeader from 'common/OverviewHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'lib/hooks/useQuery';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Slider from 'react-slick';
import { actions as rentalActions } from 'redux/equipmentRental';
import { Button, Table } from 'reactstrap';
import PopupForm from 'common/Form/PopupForm';
import VerifyStatusPopup from './ctverifyStatus';
import { useParams } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import dayjs from 'dayjs';
import { Chip } from '@material-ui/core';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { decryptionInHex, getDecryptedRouteParams } from 'lib/utils';

const EquipmentProfile = () => {
  //const { back } = useQuery();
  const { back, page = 1 } = useQuery();
  const dispatch = useDispatch();
  let { id } = getDecryptedRouteParams(useParams());
  // id = decryptionInHex(id);
  const equipmentData = useSelector((state) => state.rental);
  const roles = useSelector((state) => state.rolesAndRights);
  const rolesList = roles.entities || {};
  let CT_ParentRecord = Object.values(rolesList).find(
    (x) => x.module_name === 'CT_ParentModule' && (x.sub_module_id === 0 || x.sub_module_id === null),
  );
  const CT_parent = _.get(CT_ParentRecord, `full_access`);
  const equipmentProfileData = _.get(equipmentData, `entities[0]`);
  const equipmentSpecification = _.get(equipmentProfileData, `equipment_spec_detail`);
  const attachmentDetails = _.get(equipmentProfileData, 'app_attachment_detail');
  const verificationStatus = _.get(equipmentProfileData,"verification_status_enum");
  const verificationStatusCode = _.get(equipmentProfileData,"verification_status_enum_code");
  const isDraft = (verificationStatus === 4 && verificationStatusCode === 'Draft');
  const status = _.get(equipmentProfileData, 'verification_status_enum_code');
  const busy = _.get(equipmentData, 'busy') || false;
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  var settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
  };
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    let filterPrams = {
      equipment_master_id: id,
      Is_FetchAllEquipment : "true"
    };
    const params = {
      filters: filterPrams,
      offset: (parseInt(page) - 1) * rowsPerPage,
      page_Size: rowsPerPage,
    };
    dispatch(rentalActions.getAllEquipments(params));
  }, [filters, rowsPerPage, page, id]);

  const formatVehicleNumber = (vehicleNumber) => {
    return `${vehicleNumber.slice(0, 2)} ${vehicleNumber.slice(2, 4)} ${
      vehicleNumber?.length === 9
        ? vehicleNumber.slice(4, 5)
        : vehicleNumber?.length === 10
        ? vehicleNumber.slice(4, 6)
        : vehicleNumber.slice(4, 7)
    } ${vehicleNumber.slice(
      vehicleNumber?.length === 9 ? 5 : vehicleNumber?.length === 10 ? 6 : 7,
      vehicleNumber?.length,
    )}`;
  };

  return (
    <div>
      <OverviewHeader
      heading={`Equipment ID (#${id})`}
      backLink={back}
      status={status}
      equipmentRentalStatus = {true}
      />
      <div className="bfc-body">
          {busy ? (
            <div className="commodity-wrapper">
              <div className="panel__refresh">
                <LoadingIcon />
              </div>
            </div>
          ) :
      <div style={{ margin: '15px 10% 15px 10%' }}>
        <div className="row">
          <div className="col-7 image-content">
            <Slider {...settings}>
              {_.map(attachmentDetails, (image, image_key) => {
                return (
                  <div key={image_key}>
                    <img src={image?.attachment_path} className="basic-carsousel-img" />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="col-5 description-content">
            <div className="row">
              <div>
                <Button
                  onClick={() => setOpen(true)}
                  color="secondary"
                  type="button"
                  size="sm"
                  disabled={!CT_parent || isDraft}
                  className="next bfc-button-next">
                  Verify
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-6 label">Category :</div>
              <div className="col-6 description-data">{equipmentProfileData?.equipment_category_code || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Type :</div>
              <div className="col-6 description-data">{equipmentProfileData?.equipment_type_code || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Model :</div>
              <div className="col-6 description-data">{equipmentProfileData?.equipment_model_code || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Maker :</div>
              <div className="col-6 description-data">{equipmentProfileData?.equipment_make_code || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Variant :</div>
              <div className="col-6 description-data">{equipmentProfileData?.equipment_variant_code || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Serial No :</div>
              <div className="col-6 description-data">{equipmentProfileData?.serial_number || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Tracker ID :</div>
              <div className="col-6 description-data">{equipmentProfileData?.telemetry_device_id || 'N/A'}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Registration No :</div>
              <div className="col-6 description-data">
                {equipmentProfileData?.registration_no
                  ? formatVehicleNumber(equipmentProfileData?.registration_no)
                  : 'N/A'}
              </div>
            </div>
            <div className="row">
              <div className="col-6 label">Insurance Validity :</div>
              <div className="col-6 description-data">{`${
                equipmentProfileData?.insured_to_datetime
                  ? dayjs(equipmentProfileData?.insured_to_datetime).format('DD-MMM-YYYY')
                  : 'N/A'
              }`}</div>
            </div>
            <div className="row">
              <div className="col-6 label">Comes With Operator :</div>
              <div className="col-6 description-data">
                {equipmentProfileData?.is_operator_required === true ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="row">
              <div className="col-6 label">Comes With Tractor :</div>
              <div className="col-6 description-data">
                {equipmentProfileData?.is_tractor_available === true ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="row">
              <div className="col-6 label">Offered as :</div>
              <div className="col-6 description-data">
                {equipmentProfileData?.equipment_offer_type_enum_code}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col equipment-tabs">
            <Nav tabs>
              <NavItem>
                <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                  Specification
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                  Description
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                  Address
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>
                  Unavailability Dates
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="description-data">
                  <>
                    <Table>
                      <thead>
                        <tr>
                          <th>Specification</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(equipmentSpecification, (specificationCode, specification_key) => {
                          return (
                            <>
                              <tr>
                                <td>{specificationCode?.equipment_spec_code}</td>
                                <td>
                                  {specificationCode?.equipment_spec_value} {specificationCode?.equipment_spec_uom_code}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="description-data">{equipmentProfileData?.equipment_description}</div>
              </TabPane>
              <TabPane tabId="3">
                <div className="description-data">{equipmentProfileData?.equipment_address}</div>
              </TabPane>
              <TabPane tabId="4">
                <div className="description-data">
                  {equipmentProfileData?.blackout_days_detail?.map(
                    (item) =>
                      item.from_date &&
                      item.to_date && (
                        <Chip
                          className="mr-2 mb-2"
                          style={{ backgroundColor: 'var(--unnamed-color-1b62ab)', color: '#fff', fontWeight: 'bold' }}
                          label={`${dayjs(item.from_date).format('DD-MMM-YYYY')} To ${dayjs(item.to_date).format(
                            'DD-MMM-YYYY',
                          )}`}
                        />
                      ),
                  )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    }
      </div>
      {open && (
        <PopupForm
          title={`Verify Details`}
          onClose={() => {
            setOpen(false);
          }}>
          <VerifyStatusPopup />
        </PopupForm>
      )}
    </div>
  );
};
export default EquipmentProfile;
