import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Space, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import './rangeDate.scss';

function RangeDateSearchFilter({ dataIndex, data, onFilterChange, handleReset, value }) {
  const { confirm, clearFilters, setSelectedKeys, selectedKeys } = data;
  const [searchStartDate, setSearchStartDate] = useState(value ? value?.split(':')?.[0] : '');
  const [searchEndDate, setSearchEndDate] = useState(value ? value?.split(':')?.[1] : '');
  const [dateStringArray, setDateStringArray] = useState('');

  const { RangePicker } = DatePicker;

  useEffect(()=> {
    if(value === undefined || value === null){
      setSearchEndDate('');
      setSearchStartDate('');
    }
  },[value])
  return (
    <div style={{ padding: 8, display:"flex", flexDirection:"column" }}>
      <RangePicker
      size="small"
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
          'This Week': [moment().startOf('week'), moment().endOf('week')],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        onChange={(date, dateString) => {
          setSearchStartDate(dateString && dateString[0] ? dayjs(dateString[0]).format('YYYY-MM-DD') : '');
          setSearchEndDate(dateString && dateString[1] ? dayjs(dateString[1]).format('YYYY-MM-DD') : '');

          if (dateString[0] == '') {
            dateString[0] = 'N';
          }
          if (dateString[1] == '') {
            dateString[1] = 'N';
          }
          const dateStringArray = dateString[0] + ':' + dateString[1];
          setDateStringArray(dateStringArray);
          if (dateString[0] != 'N' && dateString[1] != 'N') onFilterChange(dateStringArray, dataIndex, confirm);
        }}
        value={[
          searchStartDate ? moment(searchStartDate, 'YYYY-MM-DD') : '',
          searchEndDate ? moment(searchEndDate, 'YYYY-MM-DD') : '',
        ]}
        allowEmpty={[true, true]}
      />

      <Space style={{display:"flex", justifyContent:"space-between", marginTop:"5px", width:"100%"}}>
        <Button
          className="proceed-button"
          onClick={(e) => {
            if (dateStringArray) onFilterChange(dateStringArray, dataIndex, confirm);
          }}
          type='primary'
          size="small"
          style={{ width: "100px" }}>
          Ok
        </Button>
        <Button
          className="reset-button"
          onClick={(e) => {
            setSearchStartDate('');
            setSearchEndDate('');
            handleReset(clearFilters, dataIndex);
          }}
          size="small"
          style={{ width: "100px" }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}

export default RangeDateSearchFilter;
