import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { actions as filterOptionsActions } from 'redux/filterOptions';
import { Space, Button, Select, AutoComplete } from 'antd';
import StarRatings from 'react-star-ratings';
import { getDecryptedUrl } from 'lib/utils';

function AutoCompleteFilter({ dataIndex, data, onFilterChange, handleReset, value, name, codeBaisedFilter, isMT = true, isControlTower, isFarmer, delivery_split_enum, isPersonCompanyAffiliateEnumRequired, isMarket }) {
  const { confirm, clearFilters } = data;
  const dispatch = useDispatch();

  const filterOptions = useSelector((state) => state.filterOptions);
  const referenceData = useSelector((state) => state.referenceData);
  const user = useSelector((state) => state.user);
  const isCtUser = user?.profile?.person?.is_control_tower_user;
  const [searchValue, setSearchValue] = useState(value);

  const loc = getDecryptedUrl(window.location.href);

  const status = [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Inactive' },
  ];

  const userStatus = [
    { value: 1, text: 'Active' },
    { value: 0, text: 'Closed' },
  ];

  const recordedBy = [
    { value: 'Me', text: 'Me' },
    { value: 'Counterpart', text: 'Counterpart' },
  ];

  const trial = [
    { value: 1, text: 'Trial' },
    { value: 0, text: 'Regular' },
  ];

  const subscriptionStatus = [
    { value: 'Trial', text: 'Trial' },
    { value: 'Active', text: 'Active' },
    { value: 'Expired', text: 'Expired' },
  ];

  const subscriptionPlanStatus = [
    { value: 'Trial', text: 'Trial' },
    { value: 'Active', text: 'Active' },
    { value: 'Expired', text: 'Expired' },
    { value: 'N/A', text: 'N/A' },
  ];

  const crDrStatus = [

    { value: 'Credit', text: 'Credit' },
    { value: 'Debit', text: 'Debit' }

  ];

  const farmProfileStatus = [
    { value: 'Complete', text: 'Complete' },
    { value: 'Not Complete', text: 'Not Complete' }
  ];

  const bank_verification_enum = [
    { value: '1', text: 'Verified' },
    { value: '2', text: 'Failed' }
  ];

  const invoice_bill_enum = [
    { value: '1', text: 'Invoice' },
    { value: '2', text: 'Bill' }
  ];
  const alloted_deAlloted_enum = [
    { value: '1', text: 'Alloted' },
    { value: '0', text: 'DeAlloted' }
  ];

  const eligibility_enum = [
    { value: 'Eligible for Invoicing', text: 'Eligible for Invoicing' },
    { value: 'Ineligible for Invoicing', text: 'Ineligible for Invoicing' }
  ];

  let options = [];
  if (dataIndex) {
    if (dataIndex === 'commodity_form_code' || dataIndex === 'commodity_form' || dataIndex === 'commodity_form_id') {
      let data = _.get(filterOptions, 'data.commodityForm') || [];
      options = [];
      data.map((item, index) => {
        let itemData = {
          ...item,
          value: item.text,
        };
        if (itemData.value !== null) {
          options.push(itemData);
        }
      });
    }

    if (dataIndex === 'commodity_form_master_code') {
      options = _.get(filterOptions, 'data.commodityFormMaster') || [];
    }

    if (dataIndex === 'commodity_type_code' || dataIndex === 'commodity_type') {
      options = _.get(filterOptions, 'data.commodityType') || [];
    }

    if (dataIndex === 'commodity_type_master_code') {
      options = _.get(filterOptions, 'data.commodityTypeMaster') || [];
    }

    if (dataIndex === 'fuel_code') {
      options = _.get(filterOptions, 'data.fuelType') || [];
    }

    if (dataIndex === 'carnot_device_type_code') {
      options = _.get(filterOptions, 'data.carnotDeviceType') || [];
    }

    if (dataIndex === 'commodity_residue_code' || dataIndex === 'commodity_residue_id') {
      options = _.get(filterOptions, 'data.commodityResidue') || [];
    }

    if (dataIndex === 'commodity_residue_master_code' || dataIndex === 'commodity_residue_code') {
      options = _.get(filterOptions, 'data.commodityResidueMaster') || [];
    }

    if (dataIndex === 'commodity_code' || dataIndex === 'goods_services' || dataIndex === 'commodity_id') {
      options = _.get(filterOptions, 'data.commodity') || [];
    }

    if (dataIndex === 'company') {
      options = _.get(filterOptions, 'data.company') || [];
    }

    if (dataIndex === 'subscription_plan' || dataIndex === 'subscription_plan_id') {
      options = _.get(filterOptions, 'data.subscriptionPlan') || [];
      options = options.map((option) => {
        var temp = Object.assign({}, option);
        if (temp.value === 1) {
          temp.text = 'Prepaid Pack 10,000';
        }
        if (temp.value === 2) {
          temp.text = 'Prepaid Pack 25,000';
        }
        if (temp.value === 3) {
          temp.text = 'Prepaid Pack 50,000';
        }
        return temp;
      });
    }

    if (dataIndex === 'platform_charge') {
      options = _.get(referenceData, 'data.PlatformTypeEnum') || [];
    }

    if (dataIndex === 'status') {
      options = [...status] || [];
    }

    if (dataIndex === 'person_status') {
      options = [...userStatus] || [];
    }

    if (dataIndex === 'recorded_by') {
      options = [...recordedBy] || [];
    }

    if (dataIndex === 'trial') {
      options = [...trial] || [];
    }
    if (dataIndex === 'invoice_bill_enum') {
      options = [...invoice_bill_enum] || [];
    }

    if (dataIndex === 'payment_method_code') {
      options = _.get(filterOptions, 'data.payment_method') || [];
    }

    if (dataIndex === 'vehicle_type_code' || dataIndex === 'vehicle_size') {
      options = _.get(filterOptions, 'data.vehicle_type') || [];
      console.log("Options--->", options)
    }
    if (dataIndex === 'invoice_finance_payment_enum_code') {
      options = _.get(filterOptions, 'data.InvoiceFinancePaymentEnum') || [];
    }
    if (dataIndex === 'invoice_finance_application_enum') {
      options = _.get(filterOptions, 'data.InvoiceFinanceApplicationEnum') || [];
    }

    if (
      dataIndex === 'coarse_loaction' ||
      dataIndex === 'locations' ||
      dataIndex === 'initiator_location' ||
      dataIndex === 'coarse_loaction_bid'
    ) {
      options = _.get(filterOptions, 'data.location') || [];
    }

    if (dataIndex === 'req_offer_status_enum') {
      options = _.get(filterOptions, 'data.req_offer_status_enum') || [];
    }

    if (dataIndex === 'req_offer_status_enum_linked_buy') {
      options = _.get(filterOptions, 'data.LinkedBuyStatus') || [];
    }

    if (dataIndex === 'req_offer_response_status_enum_code') {
      options = _.get(filterOptions, 'data.req_offer_response_status_enum') || [];
    }

    if (dataIndex === 'deal_status_enum_code' || dataIndex === 'deal_1_status_enum_code' || dataIndex === 'deal_2_status_enum' || dataIndex === 'processing_deal_status_enum_code') {
      options = _.get(filterOptions, 'data.deal_status_enum') || [];
    }
    if (dataIndex === 'fuelling_status_enum_code') {
      options = _.get(filterOptions, 'data.fuelling_status_enum') || [];
    }
    if (dataIndex === 'business_type_enum_code' || dataIndex === 'applicant_business_type_enum_code') {
      options = _.get(filterOptions, 'data.business_type_enum') || [];
    }
    if (dataIndex === 'kyc_status_enum_code' || dataIndex === 'kyc_status_enum') {
      options = _.get(filterOptions, 'data.kyc_status_enum') || [];
    }
    if (dataIndex === 'invoice_status_enum' || dataIndex === 'linked_invoicestatusenum') {
      options = _.get(filterOptions, 'data.invoice_status_enum') || [];
    }

    if (dataIndex === 'invoice_verification_enum' || dataIndex === 'workflow_invoice_verification_enum') {
      options = _.get(filterOptions, 'data.invoice_verification_enum') || [];
    }

    if (dataIndex === 'pending_approved') {
      options = _.get(filterOptions, 'data.pending_approved_enum') || [];
    }
    if (dataIndex === 'cashflow_status_enum_code') {
      options = _.get(filterOptions, 'data.cashflow_status_enum') || [];
    }
    // if (dataIndex === 'eligibility') {
    //   options = _.get(filterOptions, 'data.eligibility') || [];
    // }
    if (dataIndex === 'invoice_status_enum_code') {
      options = [
        { value: 2, text: 'Open' },
        { value: 4, text: 'Paid' },
        { value: 10, text: 'Initiated' },
      ];
    }
    if (dataIndex === 'is_offerer_bank_verified') {
      options = [
        { value: '1', text: 'Yes' },
        { value: '0', text: 'No' }
      ];
    }

    if (dataIndex === 'payment_status_enum_code') {
      options = _.get(filterOptions, 'data.payment_status_enum') || [];
    }

    if (dataIndex === 'vehicle_type_enum_code') {
      options = _.get(filterOptions, 'data.vehicle_type_enum') || [];
    }

    if (dataIndex === 'per_day_rate') {
      options = _.get(filterOptions, 'data.hire_type_enum') || [];
    }

    if (dataIndex === 'tax_applicability_enum_code') {
      options = _.get(filterOptions, 'data.tax_applicability_enum') || [];
    }

    if (dataIndex === 'delivery_allotment_status_enum_code' && !loc.includes('not-confirmed')) {
      options = _.get(filterOptions, 'data.delivery_allotment_status_enum') || [];
    }

    if (dataIndex === 'consignor') {
      options = _.get(filterOptions, 'data.consignor_id') || [];
    }

    if (dataIndex === 'delivery_consignor') {
      options = _.get(filterOptions, `data.DeliveryConsignor&delivery_split_enum=${delivery_split_enum}`) || [];
    }

    if (dataIndex === 'seller_company_id') {
      options = filterOptions.data.consignor_id;
    }

    if (dataIndex === 'consignee') {
      options = _.get(filterOptions, 'data.consignee_id') || [];
    }
    if(dataIndex === 'ProcessingDeals_Buyer'){
      options = _.get(filterOptions, 'data.ProcessingDeals_Buyer') || [];
    }
    if (dataIndex === 'delivery_consignee') {
      options = _.get(filterOptions, `data.DeliveryConsignee&delivery_split_enum=${delivery_split_enum}`) || [];
    }

    if (dataIndex === 'delivery_allotment_status_enum_code' &&  loc.includes('not-confirmed')) {
      options = _.get(filterOptions, `data.ScheduleStatus&delivery_split_enum=${delivery_split_enum}`) || [];
    }
    if (dataIndex === 'buyer_company_code_deal') {
      options = _.get(filterOptions, 'data.Consignee') || [];
    }
    if (dataIndex === 'seller_company_code_deal') {
      options = _.get(filterOptions, 'data.Consignor') || [];
    }
    if(dataIndex === 'ProcessingDeals_Seller'){
      options = _.get(filterOptions,'data.ProcessingDeals_Seller') || [];
    }
    if (dataIndex === 'invoice_buyer' || dataIndex === 'buyer_company_code') {
      options = _.get(filterOptions, 'data.InvoiceBuyer') || [];
    }
    if (dataIndex === 'buyer_company_code_manual') {
      options = _.get(filterOptions, 'data.ManualInvoiceBuyer') || [];
    }
    if (dataIndex === 'seller_company_code_manual') {
      options = _.get(filterOptions, 'data.ManualInvoiceSeller') || [];
    }

    if (dataIndex === 'invoice_seller') {
      options = _.get(filterOptions, 'data.InvoiceSeller') || [];
    }
    if (dataIndex === 'buyer_company_code') {
      options = _.get(filterOptions, 'data.Consignee') || [];
    }
    if (dataIndex === 'vehicle') {
      options = _.get(filterOptions, 'data.vehicle_id') || [];
    }

    if (dataIndex === 'driver') {
      options = _.get(filterOptions, 'data.driver_id') || [];
    }

    if (dataIndex === 'paid_received') {
      options = _.get(filterOptions, 'data.paid_received') || [];
    }

    if (dataIndex === 'received_by_company_id') {
      options = filterOptions.data.counterparty;
    }

    if (dataIndex === 'customer_vendor') {
      options = _.get(filterOptions, 'data.counterparty') || [];
    }

    if (
      dataIndex === 'load_location' ||
      dataIndex === 'pickup_location' ||
      dataIndex === 'base_location_code' ||
      dataIndex === 'from_location_id_code' ||
      dataIndex === 'load_district_code'
    ) {
      options = _.get(filterOptions, 'data.base_location_id') || [];
    }
    if (dataIndex === 'hbs_pickup_location') {
      options = _.get(filterOptions, `data.Deliveries_Pick_Up_Location&delivery_split_enum=${delivery_split_enum}`) || [];
    }
    if (dataIndex === 'deal_loading_location') {
      options = _.get(filterOptions, 'data.DealPickupLocation') || [];
    }
    if (dataIndex === 'deal_delivery_location') {
      options = _.get(filterOptions, 'data.DealDeliveryLocation') || [];
    }
    if (dataIndex === 'transport_deal_pickup_location') {
      options = _.get(filterOptions, 'data.TransportDealPickupLocation') || [];
    }
    if (dataIndex === 'transport_deal_delivery_location') {
      options = _.get(filterOptions, 'data.TransportDealDeliveryLocation') || [];
    }
    if (dataIndex === 'transporter_against_own_deals') {
      options = _.get(filterOptions, 'data.TransporterAgainstOwnDeals') || [];
    }
    if (dataIndex === 'from_location_id') {
      options = filterOptions.data.base_location_id;
    }

    if (
      dataIndex === 'delivery_location' ||
      dataIndex === 'delivery_location_code' ||
      dataIndex === 'to_location_code' ||
      dataIndex === 'buyer_location_code' ||
      dataIndex === 'delivery_district_code'
    ) {
      options = _.get(filterOptions, 'data.delivery_location_id') || [];
    }

    if (dataIndex === 'hbs_delivery_location') {
      // options = filterOptions.data.Deliveries_Delivery;
      options = filterOptions.data?.[`Deliveries_Delivery&delivery_split_enum=${delivery_split_enum}`];
    }
    if (dataIndex === 'to_location_id') {
      options = filterOptions.data.delivery_location_id;
    }

    if (dataIndex === 'stock_in_out_type_code') {
      options = _.get(filterOptions, 'data.stock_in_out_type_enum') || [];
    }

    if (dataIndex === 'seller_company_code' || dataIndex === 'transport_person' || dataIndex === 'transporter_company_id') {
      options = _.get(filterOptions, 'data.transport_company_id') || [];
    }
    // if (dataIndex === 'transport_person') {
    //   options = _.get(filterOptions, 'data.transport_company_id') || [];
    // }
    if (dataIndex === 'auction_response_phase_enum_code') {
      options = _.get(filterOptions, 'data.auction_response_phase_enum') || [];
    }

    if (dataIndex === 'finance_enum_code') {
      options = _.get(filterOptions, 'data.finance_enum') || [];
    }
    if (dataIndex === 'gender') {
      options = _.get(filterOptions, 'data.GenderEnum') || [];
    }

    if (dataIndex === 'type_of_user') {
      options = _.get(filterOptions, 'data.TypeOfIndividualEnum') || [];
    }

    if (dataIndex === 'business_type_enum') {
      options = _.get(filterOptions, 'data.BusinessTypeEnum') || [];
    }
    if (dataIndex === 'auction_duration_enum_code' || dataIndex === 'auction_duration_enum') {
      options = _.get(filterOptions, 'data.AuctionDurationEnum') || [];
    }

    if (dataIndex === 'nature_of_business') {
      options = _.get(filterOptions, 'data.NatureOfBusinessEnum') || [];
    }

    if (dataIndex === 'modified_by') {
      options = _.get(filterOptions, 'data.BF_SETUP_PERSON') || [];
    }
    if (dataIndex === 'modified_by_code') {
      options = _.get(filterOptions, 'data.SmartBuyerConfigurationModifiedBy') || [];
    }
    if (dataIndex === 'vehicle_type_code' || dataIndex === 'vehicle_size') {
      options = _.get(filterOptions, 'data.BF_SETUP_VEHICLE_TYPE') || [];
      options = options?.map(item => ({ ...item, text: `${item?.text?.trim()?.split(' ')?.[0]} ` }))
      console.log("Options--->", options)
    }
    if (dataIndex === 'equipment_make_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_MAKE') || [];
    }
    if (dataIndex === 'equipment_variant_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_VARIANT') || [];
    }
    if (dataIndex === 'equipment_type_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_TYPE') || [];
    }

    if (dataIndex === 'signature_type_code') {
      options = _.get(filterOptions, 'data.signature_type_code') || [];
    }
    if (dataIndex === 'pg_payment_enum_code') {
      options = _.get(filterOptions, 'data.pg_payment_status_enum') || [];
    }
    if (dataIndex === 'subscription_status') {
      options = [...subscriptionStatus];
    }
    if (dataIndex === 'subscription_plan_status') {
      options = [...subscriptionPlanStatus];
    }
    if (dataIndex === 'is_followed_by_person' || dataIndex === 'is_aggregate_quantity_variations') {
      options = [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ];
    }

    if (dataIndex === 'is_aggregate_quantity_variations') {
      options = [
        { value: 1, text: 'Aggregate' },
        { value: 0, text: 'Per Delivery' },
      ];
    }

    if (dataIndex === 'cr_dr_status') {
      options = [...crDrStatus]
    }

    if (dataIndex === 'preferred_blocked_enum') {
      options = [
        { value: 1, text: 'Preferred' },
        { value: 2, text: 'Exclude Blocked' },
        { value: 3, text: 'Following' },
      ];
    }

    if (dataIndex === 'is_countered') {
      options = [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ];
    }

    if (
      dataIndex === 'is_verified' ||
      dataIndex == 'is_auction' ||
      dataIndex === 'is_auto_match'
    ) {
      options = [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ];
    }
    if (
      dataIndex === 'is_walk_in' ||
      dataIndex === 'is_auto_match'
    ) {
      options = [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ];
    }
    if (dataIndex === 'delivery_term_enum_code') {
      options = [
        { value: 1, text: 'Delivered (DDP)' },
        { value: 2, text: 'Ex-Factory (FCA)' },
      ];
    }
    if (dataIndex === 'is_own_transport') {
      options = [
        { value: 1, text: 'Own Transport' },
        { value: 0, text: 'BioFuelCircle Transport' },
      ];
    }
    if (dataIndex === 'offer_bid_type_enum') {
      options = [
        { value: -1, text: 'Quotation' },
        { value: 1, text: 'Offer' },
        { value: 2, text: 'Bid' },
      ];
    }
    if (dataIndex === 'auction_status') {
      options = [
        { value: 'Cancelled', text: 'Cancelled' },
        { value: 'Live', text: 'Live' },
        { value: 'Completed', text: 'Completed' },
        { value: 'Upcoming', text: 'Upcoming' },
      ];
    }
    if (dataIndex === 'is_secured') {
      options = [
        { value: 'true', text: 'Yes' },
        { value: 'false', text: 'No' },
      ];
    }

    if (dataIndex === 'rating') {
      options = [1, 2, 3, 4].map((rating) => ({
        value: rating,
        text: (
          <StarRatings
            rating={rating}
            starRatedColor="#ffcc33"
            numberOfStars={5}
            starDimension="12px"
            starSpacing="1px"
            name="rating"
          />
        ),
      }));
    }

    if (dataIndex === 'plan_name') {
      options = _.get(filterOptions, 'data.VerifiedSellerPlan') || [];
    }

    if (dataIndex === 'inspection_enum_code') {
      options = _.get(filterOptions, 'data.inspection_enum') || [];
    }
    if (dataIndex === 'extended_profile_enum_code') {
      options = _.get(filterOptions, 'data.extended_profile_enum') || [];
    }
    if (dataIndex === 'tick_color_enum_location') {
      options = _.get(filterOptions, 'data.tick_color_enum_location') || [];
    }
    if (dataIndex === 'paid_by') {
      options = _.get(filterOptions, 'data.PaymentsPaidBy') || [];
    }
    if (dataIndex === 'paid_to') {
      options = _.get(filterOptions, 'data.PaymentsReceivedBy') || [];
    }
    if (dataIndex === 'recorded_by_name') {
      options = _.get(filterOptions, 'data.PaymentsPaidReceivedCombined') || [];
    }
    if (dataIndex === 'district_from') {
      options = _.get(filterOptions, 'data.TransportRateFromLocation') || [];
    }
    if (dataIndex === 'district_to') {
      options = _.get(filterOptions, 'data.TransportRateToLocation') || [];
    }
    if (dataIndex === 'load_state_code') {
      options = _.get(filterOptions, 'data.Pickuplocationstate') || [];
    }
    if (dataIndex === 'delivery_state_code') {
      options = _.get(filterOptions, 'data.deliverylocationstate') || [];
    }

    if (dataIndex === 'ManualInvoicePickupLocation') {
      options = _.get(filterOptions, 'data.ManualInvoicePickupLocation') || [];
    }
    if (dataIndex === 'ManualInvoiceDeliveryLocation') {
      options = _.get(filterOptions, 'data.ManualInvoiceDeliveryLocation') || [];
    }
    if (dataIndex === 'buyer_id') {
      options = _.get(filterOptions, 'data.FinanceBuyers') || [];
    }
    if (dataIndex === 'seller_id') {
      options = _.get(filterOptions, 'data.FinanceSellers') || [];
    }
    if (dataIndex === 'buyer_id_borg') {
      options = _.get(filterOptions, 'data.FinanceBuyerBorg') || [];
    }
    if (dataIndex === 'buyer_person_company_affiliate_id') {
      options = _.get(filterOptions, isMarket ? 'data.DealFinance_Applicant_Buyer' : 'data.DealFinance_NBFC_Buyer') || [];
    }
    if (dataIndex === 'seller_person_company_affiliate_id') {
      options = _.get(filterOptions, isMarket ? 'data.DealFinance_Applicant_Seller' : 'data.DealFinance_NBFC_Seller') || [];
    }
    if (dataIndex === 'nbfc_person_company_affiliate_id') {
      options = _.get(filterOptions, 'data.DealFinance_Applicant_Bank') || [];
    }
    if (dataIndex === 'seller_id_borg') {
      options = _.get(filterOptions, 'data.FinanceSellersBorg') || [];
    }
    if (dataIndex === 'applied_to_bank_id') {
      options = _.get(filterOptions, 'data.FinanceAppliedTo') || [];
    }
    if (dataIndex === 'applicant_company_affiliate_name') {
      options = _.get(filterOptions, isCtUser ? 'data.EPApplications_Companies' : 'data.EPApplications_CompaniesUser') || [];
      options = options.map((option) => ({
        ...option,
        value: `${option.value}-${option.person_company_affiliate_enum}`,
      }));
    }
    if (dataIndex === 'company_address_id') {
      options = _.get(filterOptions, isCtUser ? 'data.EPApplications_LocationNames' : 'data.EPApplications_LocationNamesUser') || [];
    }
    if (dataIndex === 'subscription') {
      options = _.get(filterOptions, 'data.EPApplications_SubscriptionStatus') || [];
      options = options.map((option) => ({ ...option, value: option.code }));
    }
    if (dataIndex === 'coarse_location') {
      options = _.get(filterOptions, isCtUser ? 'data.EPApplications_CoarseLocations' : 'data.EPApplications_CoarseLocationsUser') || [];
      options = options.map((option) => ({ ...option, value: option.code }));
    }
    if (dataIndex === 'inspection_person_id') {
      options = _.get(filterOptions, 'data.EPApplications_InspectedBy') || [];
    }
    if (dataIndex === 'inspection_verified_by') {
      options = _.get(filterOptions, 'data.EPApplications_InspectionVerifiedBy') || [];
    }
    if (dataIndex === 'show_on_landing_page') {
      options = [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ];
    }
    if (dataIndex === 'is_facilitator') {
      options = [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ];
    }
    if (dataIndex === 'person_company_type') {
      const typeOfIndividualEnum = _.get(filterOptions, 'data.TypeOfIndividualEnum') || [];
      const natureOfBusinessEnum = _.get(filterOptions, 'data.NatureOfBusinessEnum') || [];
      if (typeOfIndividualEnum.length > 0 && natureOfBusinessEnum.length > 0 && options.length === 0) {
        let filterData = [...typeOfIndividualEnum];
        natureOfBusinessEnum.forEach((item) => {
          let newData = item;
          newData = { ...newData, value: newData.value + typeOfIndividualEnum.length };
          filterData.push(newData);
        });
        options = filterData;
      }
    }

    if (dataIndex === 'req_offer_type_enum' || dataIndex === 'buy_sell_enum') {
      options = [
        { value: 1, text: 'Buy' },
        { value: 2, text: 'Sell' },
      ];
    }
    if (dataIndex === 'periodicity') {
      options = [
        { value: 1, text: 'Once' },
        { value: 2, text: 'Per Day' },
        { value: 4, text: 'Per Month' },
      ];
    }
    if (dataIndex === 'exposure_type') {
      options = [
        { value: 1, text: 'Buying' },
        { value: 0, text: 'Selling' },
      ];
    }
    if (dataIndex === 'distance_range') {
      options = [
        { value: 50, text: '50km' },
        { value: 100, text: '100km' },
        { value: 250, text: '250km' },
        { value: 500, text: '500km' },
        { value: 501, text: '> 500km' },
      ];
    }

    if (dataIndex === 'farmer_location_code') {
      options = _.get(filterOptions, 'data.FarmerLocations') || [];
    }
    if (dataIndex === 'verification_status_enum') {
      options = _.get(filterOptions, 'data.VerificationStatusEnum') || [];
    }
    if (dataIndex === 'from_address') {
      if (isControlTower) {
        options = _.get(filterOptions, 'data.STFromLocation') || [];
      } else {
        options = _.get(filterOptions, 'data.from_address') || [];
      }
    }
    if (dataIndex === 'to_address') {
      if (isControlTower) {
        options = _.get(filterOptions, 'data.STToLocation') || [];
      } else {
        options = _.get(filterOptions, 'data.to_address') || [];
      }
    }
    if (dataIndex === 'transporter') {
      options = _.get(filterOptions, 'data.transporter') || [];
      options = [...new Set(options.map(item => item.company_code))];
      options = [{ text: options?.[0], value: options?.[0] }]
    }
    if (dataIndex === 'stock_transfer_status_enum') {
      options = _.get(filterOptions, 'data.StockTransferStatusEnum') || [];
    }
    if (dataIndex === 'consignee_id') {
      if (isControlTower) {
        options = _.get(filterOptions, 'data.STConsignor') || [];
      } else {
        options = _.get(filterOptions, 'data.consignee_id') || [];
        options = [...new Set(options.map(item => item.company_code))];
        options = [{ text: options?.[0], value: options?.[0] }]
      }
    }
    if (dataIndex === 'consignor_id') {
      if (isControlTower) {
        options = _.get(filterOptions, 'data.STConsignor') || [];
      } else {
        options = _.get(filterOptions, 'data.consignor_id') || [];
        options = [...new Set(options.map(item => item.company_code))];
        options = [{ text: options?.[0], value: options?.[0] }]
      }
    }
    if (dataIndex === 'equipment_category_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_CATEGORY') || [];
    }
    if (dataIndex === 'equipment_model_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_EQUIPMENT_MODEL') || [];
    }
    if (dataIndex === 'farm_profile_complete') {
      options = [...farmProfileStatus]
    }
    if (dataIndex === 'credit_line_bank_name') {
      options = _.get(filterOptions, 'data.CT_tf_GetApplicationInbox_BankName') || [];
    }
    if (dataIndex === 'finance_invoice_bank_name') {
      options = _.get(filterOptions, 'data.CT_tf_GetAllFinanceInvoiceApplication_BankName') || [];
    }
    if (dataIndex === 'equipment_address') {
      options = _.get(filterOptions, 'data.EquipmentAddressEnum') || [];
    }
    if (dataIndex === 'reason_for_account_closure_enum') {
      options = _.get(filterOptions, 'data.ReasonForAccountClosureEnum') || [];
    }
    if (dataIndex === 'bank_verification_enum') {
      options = bank_verification_enum;
    }
    if (dataIndex === 'company_affiliate_name') {
      options = _.get(filterOptions, 'data.FacilitatorCompanyAffiliateName') || [];
    }
    if (dataIndex === 'modified_by_person') {
      options = _.get(filterOptions, 'data.AdjustmentFactorModifiedBy') || [];
    }
    if (dataIndex === 'early_payment_modified_by') {
      options = _.get(filterOptions, 'data.EarlyPaymentDiscountModifiedBy') || [];
    }
    if (dataIndex === 'platform_charge_at_enum') {
      options = _.get(filterOptions, 'data.platform_charge_at_enum') || [];
    }
    if (dataIndex === 'packaging_id') {
      options = _.get(filterOptions, 'data.BF_SETUP_PACKAGING') || [];
    }
    if (dataIndex === 'company_location') {
      options = _.get(filterOptions, 'data.company_location') || [];
    }
    if (dataIndex === 'contract_type_enum') {
      options = _.get(filterOptions, 'data.ContractTypeEnum') || [];
    }
    if (dataIndex === 'onboarding_way') {
      options = _.get(filterOptions, 'data.OnboardingWayEnum') || [];
    }
    if (dataIndex === 'bf_setup_ftk_machine_id') {
      options = _.get(filterOptions, 'data.FTKMachine') || [];
    }
    if (dataIndex === 'person_company_affiliate_id') {
      options = _.get(filterOptions, 'data.FTKAllotedEntityName') || [];
    }
    if (dataIndex === 'address_id') {
      options = _.get(filterOptions, 'data.FTKAllotedEntityLocation') || [];
    }
    if (dataIndex === 'user_name') {
      options = _.get(filterOptions, 'data.FTKAllotedUserName') || [];
    }
    if (dataIndex === 'is_alloted') {
      options = [...alloted_deAlloted_enum];
    }
    if (dataIndex === 'eligibility') {
      options = [...eligibility_enum];
    }
    if (dataIndex === 'zoho_payment_type_enum') {
      options = _.get(filterOptions, 'data.ZohoPaymentTypeEnum') || [];
    }
    if (dataIndex === 'asc_contract_type_enum') {
      options = _.get(filterOptions, 'data.ASCContractTypeEnum') || [];
    }
    if (dataIndex === 'advance_purpose_enum') {
      options = _.get(filterOptions, 'data.AdvancePurposeEnum') || [];
    }

    useEffect(() => {
      if (dataIndex !== '') {
        switch (dataIndex) {
          case 'customer_vendor':
            dispatch(filterOptionsActions.getCounterpartyData('counterparty'));
            break;
          case 'load_location':
          case 'pickup_location':
          case 'base_location_code':
          case 'from_location_id_code':
          case 'load_district_code':
            dispatch(filterOptionsActions.getPickUpLocationData('base_location_id'));
            break;
          case 'applicant_company_affiliate_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(isCtUser ? 'EPApplications_Companies' : 'EPApplications_CompaniesUser'));
            break;
          case 'company_address_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(isCtUser ? 'EPApplications_LocationNames' : 'EPApplications_LocationNamesUser'));
            break;
          case 'subscription':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('EPApplications_SubscriptionStatus'));
            break;
          case 'coarse_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(isCtUser ? 'EPApplications_CoarseLocations' : 'EPApplications_CoarseLocationsUser'));
            break;
          case 'inspection_person_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('EPApplications_InspectedBy'));
            break;
          case 'inspection_verified_by':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('EPApplications_InspectionVerifiedBy'));
            break;
          case 'deal_loading_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('DealPickupLocation', true));
            break;
          case 'deal_delivery_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('DealDeliveryLocation', true));
            break;
          case 'transport_deal_pickup_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('TransportDealPickupLocation', true));
            break;
          case 'transport_deal_delivery_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('TransportDealDeliveryLocation', true));
            break;
          //transporter filter
          case 'transporter_against_own_deals':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('TransporterAgainstOwnDeals', true));
            break;
          case 'paid_by':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('PaymentsPaidBy', false));
            break;
          case 'paid_to':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('PaymentsReceivedBy', false));
            break;
          case 'recorded_by_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('PaymentsPaidReceivedCombined', false));
            break;
          case 'district_from':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('TransportRateFromLocation', true));
            break;
          case 'district_to':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('TransportRateToLocation', true));
            break;
          case 'invoice_status_enum':
          case 'linked_invoicestatusenum':
            dispatch(filterOptionsActions.getInvoiceStatusEnumData('invoice_status_enum'));
            break;
          case 'workflow_invoice_verification_enum':
          case 'invoice_verification_enum':
            dispatch(filterOptionsActions.getInvoiceVerificationStatusEnumData('invoice_verification_enum'));
            break;
          case 'cashflow_status_enum_code':
            dispatch(filterOptionsActions.getCashflowStatusEnumData('cashflow_status_enum'));
            break;
            // case 'eligibility':
            // dispatch(filterOptionsActions.getCashflowStatusEnumData('eligibility'));
            // break;
            default:
              if (!loc.includes('not-confirmed')) {
                switch (dataIndex) {
                  case 'delivery_allotment_status_enum_code':
                    dispatch(filterOptionsActions.getDeliveryAllotmentStatusEnumData('delivery_allotment_status_enum'));
                    break;
                  default:
                    // Handle other cases if needed
                    break;
                }
              }
              break;
          // case 'delivery_allotment_status_enum_code':
          //   dispatch(filterOptionsActions.getDeliveryAllotmentStatusEnumData('delivery_allotment_status_enum'));
          //   break;
          case 'delivery_location':
          case 'delivery_location_code':
          case 'to_location_code':
          case 'buyer_location_code':
          case 'delivery_district_code':
            dispatch(filterOptionsActions.getDeliveryLocationData('delivery_location_id'));
            break;
          case 'load_state_code':
            dispatch(filterOptionsActions.getFilterDataELOC('Pickuplocationstate'));
            break;
          case 'delivery_state_code':
            dispatch(filterOptionsActions.getFilterDataELOC('deliverylocationstate'));
            break;
          case 'paid_received':
            dispatch(filterOptionsActions.getPaidReceivedData('paid_received'));
            break;
          case 'payment_method_code':
            dispatch(filterOptionsActions.getReferenceData('payment_method'));
            break;
          case 'payment_status_enum_code':
            dispatch(filterOptionsActions.getPaymentStatusEnumData());
            break;
          case 'commodity_type_code':
          case 'commodity_type':
            dispatch(filterOptionsActions.getReferenceData('commodityType'));
            break;
          case 'commodity_type_master_code':
            dispatch(filterOptionsActions.getReferenceData('commodityTypeMaster'));
            break;
            case 'fuel_code':
              dispatch(filterOptionsActions.getReferenceData('fuelType'));
              break;
              case 'carnot_device_type_code':
                dispatch(filterOptionsActions.getReferenceData('carnotDeviceType'));
                break
          case 'commodity_form_code':
          case 'commodity_form_id':
          case 'commodity_form':
            dispatch(filterOptionsActions.getReferenceData('commodityForm'));
            break;
          case 'commodity_form_master_code':
            dispatch(filterOptionsActions.getReferenceData('commodityFormMaster'));
            break;
          case 'commodity_residue_master_code':
          case 'commodity_residue_code':
          case 'commodity_residue_id':
            dispatch(filterOptionsActions.getReferenceData('commodityResidueMaster'));
            break;
          case 'commodity_code':
          case 'commodity_id':
          case 'goods_services':
            dispatch(filterOptionsActions.getReferenceData('commodity'));
            break;
          case 'company':
            dispatch(filterOptionsActions.getReferenceData('company'));
            break;
          case 'plan_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('VerifiedSellerPlan'));
            break;
          case 'subscription_plan':
          case 'subscription_plan_id':
            dispatch(filterOptionsActions.getReferenceData('subscriptionPlan'));
            break;
          case 'coarse_loaction':
          case 'locations':
          case 'initiator_location':
          case 'coarse_loaction_bid':
            dispatch(filterOptionsActions.getReferenceData('location'));
            break;
          case 'consignee':
            dispatch(filterOptionsActions.getConsigneeData('consignee_id'));
            break;
          case 'consignor':
            dispatch(filterOptionsActions.getConsignorData('consignor_id'));
            break;
          case 'invoice_buyer':
          case 'buyer_company_code':
            dispatch(filterOptionsActions.getFilterData('InvoiceBuyer'));
            break;
          case 'invoice_seller':
            dispatch(filterOptionsActions.getFilterData('InvoiceSeller'));
            break;
          case 'buyer_company_code_deal':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('Consignee', true));
            break;
          case 'seller_company_code_deal':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('Consignor', true));
            break;
          case 'ProcessingDeals_Seller':
          case 'ProcessingDeals_Buyer':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(dataIndex,true));
            break;
          case 'vehicle':
            dispatch(filterOptionsActions.getVehicleData('vehicle_id'));
            break;
          case 'driver':
            dispatch(filterOptionsActions.getDriverData('driver'));
            break;
          case 'req_offer_status_enum':
            dispatch(filterOptionsActions.getEnumData());
            break;
          case 'req_offer_status_enum_linked_buy':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('LinkedBuyStatus'));
            break;
          case 'req_offer_response_status_enum_code':
            dispatch(filterOptionsActions.getOfferBidEnumData());
            break;
          case 'deal_status_enum_code':
          case 'deal_1_status_enum_code':
          case 'deal_2_status_enum':
          case 'processing_deal_status_enum_code':
            dispatch(filterOptionsActions.getDealEnumData(dataIndex));
            break;
            case 'fuelling_status_enum_code':
              dispatch(filterOptionsActions.getFuelEnumData());
              break;
          case 'kyc_status_enum_code':
            dispatch(filterOptionsActions.getKYCStatusEnumData());
          case 'seller_company_code':
          case 'transport_person':
          case 'transporter_company_id':
            dispatch(filterOptionsActions.getTransportProvider());
            break;
          case 'vehicle_type_enum_code':
            dispatch(filterOptionsActions.getVehicleTypeData());
            break;
          case 'per_day_rate':
            dispatch(filterOptionsActions.getHireTypeData());
            break;
          case 'tax_applicability_enum_code':
            dispatch(filterOptionsActions.getTaxApplicabilityData());
            break;
          case 'stock_in_out_type_code':
            dispatch(filterOptionsActions.getstockInOutType());
            break;
          case 'business_type_enum_code':
          case 'applicant_business_type_enum_code':
            dispatch(filterOptionsActions.getBusinessTypeEnum());
            break;
          case 'auction_response_phase_enum_code':
            dispatch(filterOptionsActions.getAuctionResponsePhaseEnumData());
            break;
          case 'finance_enum_code':
            dispatch(filterOptionsActions.getFinanceEnumData());
            break;
          case 'gender':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('GenderEnum'));
            break;
          case 'type_of_user':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('TypeOfIndividualEnum'));
            break;
          case 'business_type_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BusinessTypeEnum'));
            break;
          case 'nature_of_business':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('NatureOfBusinessEnum'));
            break;
          case 'modified_by':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_PERSON'));
            break;
          case 'modified_by_code':
            dispatch(filterOptionsActions.getFilterData('SmartBuyerConfigurationModifiedBy'));
            break;
          case 'vehicle_type_code':
          case 'vehicle_size':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_VEHICLE_TYPE'));
            break;
          case 'equipment_make_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_MAKE'));
            break;
          case 'equipment_variant_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_VARIANT'));
            break;
          case 'equipment_type_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_TYPE'));
            break;
          case 'invoice_finance_payment_enum_code':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('InvoiceFinancePaymentEnum'));
            break;
          case 'invoice_finance_application_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('InvoiceFinanceApplicationEnum'));
            break;
          case 'signature_type_code':
            dispatch(filterOptionsActions.getSignatureTypeEnumData());
            break;
          case 'pg_payment_enum_code':
            dispatch(filterOptionsActions.getPgPaymentStatusEnum());
            break;
          case 'inspection_enum_code':
            dispatch(filterOptionsActions.inspectionVerificatioEnumData('inspection_enum'));
            break;
          case 'extended_profile_enum_code':
            dispatch(filterOptionsActions.extendedProfileStatusEnumData('extended_profile_enum'));
            break;
          case 'tick_color_enum_location':
            dispatch(filterOptionsActions.extendedProfileVerificationStatusEnumData('tick_color_enum_location'));
            break;
          case 'auction_duration_enum_code':
          case 'auction_duration_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('AuctionDurationEnum'));
            break;
          case 'pending_approved':
            dispatch(filterOptionsActions.getManualInvoiceStatusEnumData('PendingApprovedEnum'));
            break;
          case 'ManualInvoicePickupLocation':
            dispatch(filterOptionsActions.getFilterData('ManualInvoicePickupLocation'));
            break;
          case 'ManualInvoiceDeliveryLocation':
            dispatch(filterOptionsActions.getFilterData('ManualInvoiceDeliveryLocation'));
            break;
          case 'person_company_type':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('TypeOfIndividualEnum'));
            dispatch(filterOptionsActions.getRegisteredUserFilterData('NatureOfBusinessEnum'));
            break;
          case 'buyer_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FinanceBuyers'));
            break;
          case 'seller_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FinanceSellers'));
            break;
          case 'buyer_id_borg':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FinanceBuyerBorg'));
            break;
          case 'buyer_person_company_affiliate_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(isMarket ? 'DealFinance_Applicant_Buyer' : 'DealFinance_NBFC_Buyer'));
            break;
          case 'seller_id_borg':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FinanceSellersBorg'));
            break;
          case 'seller_person_company_affiliate_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(isMarket ? 'DealFinance_Applicant_Seller' : 'DealFinance_NBFC_Seller'));
            break;
          case 'nbfc_person_company_affiliate_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('DealFinance_Applicant_Bank'));
            break;
          case 'applied_to_bank_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FinanceAppliedTo'));
            break;
          case 'farmer_location_code':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FarmerLocations', true));
            break;
          case 'verification_status_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('VerificationStatusEnum'));
            break;
          case 'from_address':
            if (isControlTower) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('STFromLocation'));
            } else if (isFarmer) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('from_address', false, false, user?.person_id, false, true));
            } else {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('from_address', false, true, user?.person_id));
            }
            break;
          case 'to_address':
            if (isControlTower) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('STToLocation'));
            } else if (isFarmer) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('to_address', false, false, user?.person_id, false, true));
            } else {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('to_address', false, true, user?.person_id));
            }
            break;
          case 'transporter':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('transporter', false, true, user?.person_id, true));
            break;
          case 'consignee_id':
            if (isControlTower) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('STConsignor'));
            } else {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('consignee_id', false, true, user?.person_id, true));
            }
            break;
          case 'consignor_id':
            if (isControlTower) {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('STConsignor'));
            } else {
              dispatch(filterOptionsActions.getFilterDataByFilterEnum('consignor_id', false, true, user?.person_id, true));
            }
            break;
          case 'stock_transfer_status_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('StockTransferStatusEnum'));
            break;
          case 'equipment_category_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_CATEGORY'));
            break;
          case 'equipment_model_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_EQUIPMENT_MODEL'));
            break;
          case 'buyer_company_code_manual':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('ManualInvoiceBuyer'));
            break;
          case 'seller_company_code_manual':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('ManualInvoiceSeller'));
            break;
          case 'credit_line_bank_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('CT_tf_GetApplicationInbox_BankName'));
            break;
          case 'finance_invoice_bank_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('CT_tf_GetAllFinanceInvoiceApplication_BankName'));
            break;
          case 'equipment_address':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('EquipmentAddressEnum'));
            break;
          case 'reason_for_account_closure_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('ReasonForAccountClosureEnum'));
            break;
          case 'company_affiliate_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FacilitatorCompanyAffiliateName'));
            break;
          case 'modified_by_person':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('AdjustmentFactorModifiedBy'));
            break;
          case 'early_payment_modified_by':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('EarlyPaymentDiscountModifiedBy'));
            break;
          case 'platform_charge_at_enum':
            dispatch(filterOptionsActions.getPlatformChargeAtEnumData());
            break;
          case 'packaging_id':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('BF_SETUP_PACKAGING'));
            break;
          case 'company_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('company_location', false, true, user?.person_id));
            break;
          case 'contract_type_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('ContractTypeEnum'));
            break;
          case 'onboarding_way':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('OnboardingWayEnum'));
            break;
          case 'bf_setup_ftk_machine_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FTKMachine'));
            break;
          case 'person_company_affiliate_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FTKAllotedEntityName'));
            break;
          case 'address_id':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FTKAllotedEntityLocation'));
            break;
          case 'user_name':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum('FTKAllotedUserName'));
            break;
          case 'zoho_payment_type_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('ZohoPaymentTypeEnum'));
            break;
          case 'asc_contract_type_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('ASCContractTypeEnum'));
            break;
          case 'advance_purpose_enum':
            dispatch(filterOptionsActions.getRegisteredUserFilterData('AdvancePurposeEnum'));
            break;
        }
      }
    }, [dataIndex]);

    useEffect(() => {
      if (dataIndex !== '') {
        switch (dataIndex) {
          case 'hbs_pickup_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(`Deliveries_Pick_Up_Location&delivery_split_enum=${delivery_split_enum}`, true));
            break;
          case 'hbs_delivery_location':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(`Deliveries_Delivery&delivery_split_enum=${delivery_split_enum}`, true));
            break;
          case 'delivery_consignee':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(`DeliveryConsignee&delivery_split_enum=${delivery_split_enum}`, true));
            break;
            default:
              if (loc.includes('not-confirmed')) {
                switch (dataIndex) {
                  case 'delivery_allotment_status_enum_code':
                    dispatch(filterOptionsActions.getFilterDataByFilterEnum(`ScheduleStatus&delivery_split_enum=${delivery_split_enum}`, true));
                    break;
                  default:
                    // Handle other cases if needed
                    break;
                }
              }
              break;
          case 'delivery_consignor':
            dispatch(filterOptionsActions.getFilterDataByFilterEnum(`DeliveryConsignor&delivery_split_enum=${delivery_split_enum}`, true));
            break;
        }
      }
    }, [dataIndex, delivery_split_enum])

    const selectedOption = options?.find(item => item?.value == value || item?.id == value)
    useEffect(() => {
      if (value && parseInt(value) && typeof Number(value) === 'number') {
        (dataIndex === 'vehicle_type_code' || dataIndex === 'vehicle_size' || dataIndex === 'req_offer_status_enum' || dataIndex === 'req_offer_response_status_enum_code') ? setSearchValue(value) : setSearchValue(selectedOption?.text);
      } else {
        setSearchValue(value);
      }
    }, [value])

    return (
      <div className="d-flex" style={{ padding:0 }}>
        <Select
          showSearch
          placeholder={`Select ${name}`}
          value={searchValue !== undefined ? searchValue : null}
          onChange={(e, option) => {
            // if (dataIndex === 'commodity_residue_code') {
            if (dataIndex === 'noFilter') {
            // if (dataIndex === 'paid_by' || dataIndex === 'paid_to' | dataIndex === 'recorded_by_name' || dataIndex === 'commodity_residue_code') {
              const option = JSON.parse(e);
              setSearchValue(option.text.toString());
              onFilterChange(option.text.toString(), dataIndex, confirm);
            } else if (dataIndex === 'transporter_against_own_deals' || dataIndex === 'deal_delivery_location' || dataIndex === 'deal_loading_location' || isPersonCompanyAffiliateEnumRequired) {
              const option = JSON.parse(e);
              setSearchValue(option.text.toString());
              onFilterChange(option.value.toString(), dataIndex, confirm, option?.person_company_affiliate_enum);
            } else if (dataIndex === 'vehicle_type_code' || dataIndex === 'vehicle_size') {
              setSearchValue(dataIndex === 'vehicle_type_code' ? option?.value : option.children);
              onFilterChange(dataIndex === 'vehicle_type_code' ? option?.value : option.children, dataIndex, confirm);
            } else {
              setSearchValue(e);
              onFilterChange(e, dataIndex, confirm);
            }
          }}
          optionFilterProp="children"
          filterOption={(inputValue, option) =>
            option.props.children.toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
          }
          loading={options?.length > 0 ? false : true}>
          {options?.map((option,index) =>
            option?.text ? (
              <Select.Option
                key={index}
                value={
                  dataIndex === 'transporter_against_own_deals' || dataIndex === 'deal_delivery_location' || dataIndex === 'deal_loading_location' || isPersonCompanyAffiliateEnumRequired
                    ? JSON.stringify(option)
                    : codeBaisedFilter
                      ? dataIndex === 'vehicle_size' ? option.text.concat(option.code) : option.text.toString().trim()
                      : option.value.toString()
                }>
                {isMT ? option.text : option.text.concat(option.code)}
              </Select.Option>
            ) : (
              option?.displayMember && (
                <Select.Option key={index} value={codeBaisedFilter ? option.displayMember.toString() : option.valueMember.toString()}>
                  {option.displayMember}
                </Select.Option>
              )
            ),
          )}
        </Select>
        <Space>
          <Button
            className="reset-button"
            onClick={(e) => {
              setSearchValue(null);
              handleReset(clearFilters, dataIndex);
            }}
            size="small"
            style={{ width: 70, marginLeft: '5px' }}>
            Reset
          </Button>
        </Space>
      </div>
    );
  }
}
export default AutoCompleteFilter;
