import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Space, Button, Input } from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined } from '@ant-design/icons';

function NumericSearchFilter({ dataIndex, data, onFilterChange, handleReset, value, name, isDealDealiveryMultiselectFilter, isOfferBidCount }) {
  const { confirm, clearFilters, setSelectedKeys, selectedKeys } = data;

  return (
    <div style={{ padding: 8 }}>
      {
        isDealDealiveryMultiselectFilter ?
          <Input
            ref={(node) => { }}
            placeholder={`Search ${name}`}
            value={selectedKeys || ''}
            onChange={(e) => {
              if (e.target.value.toUpperCase() && /^[ST\-0-9,]+$/.test(e.target.value.toUpperCase())) {
                setSelectedKeys(e.target.value.toUpperCase())
              } else if (!e.target.value) {
                setSelectedKeys(e.target.value)
              }
              // setSelectedKeys(e.target.value ? (e.target.value).replace(/[^ST\-0-9,]/g, "") : '')
            }}
            onPressEnter={() => {
              if (selectedKeys.length) {
                onFilterChange(selectedKeys, dataIndex, confirm);
              } else {
                setSelectedKeys('');
              }
            }}
            style={{ marginBottom: 8, display: 'block' }}
            type="text"
          />
          :
          <Input
            ref={(node) => { }}
            placeholder={isOfferBidCount ? name : `Search ${name}`}
            value={selectedKeys || ''}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : '')}
            onPressEnter={() => {
              if (parseInt(selectedKeys) >= 0) {
                onFilterChange(selectedKeys, dataIndex, confirm);
              } else {
                setSelectedKeys('');
              }
            }}
            style={{ marginBottom: 8, display: 'block' }}
            type="number"
          />
      }
      <Space>
        <Button
          className="proceed-button"
          type="primary"
          onClick={() => {
            if (parseInt(selectedKeys) >= 0 || selectedKeys.length) {
              onFilterChange(selectedKeys, dataIndex, confirm);
            } else {
              setSelectedKeys('');
            }
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}>
          Search
        </Button>
        <Button
          className="reset-button"
          onClick={(e) => {
            setSelectedKeys([]);
            handleReset(clearFilters, dataIndex);
          }}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
}

export default NumericSearchFilter;
